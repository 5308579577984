import { Box } from '@mui/material';
import { Button } from '@pennfoster/pfc-design-system';

interface Props {
  text: string;
  disabled: boolean;
  onClick: () => void;
}

export default function CartButton({ text, ...props }: Props) {
  return (
    <Box display="flex" justifyContent="end" marginTop="24px">
      <Button pfVariant="filled" size="medium" {...props}>
        {text}
      </Button>
    </Box>
  );
}
