import { useQuery } from 'react-query';
import { getPaymentMethods, getPaymentMethodsDefaultBackup } from 'src/api';
import QueryKeys from 'src/types/query-keys';

export const useFetchPaymentMethods = () => {
  const fetchPaymentMethods = () => {
    return getPaymentMethods();
  };

  return useQuery(QueryKeys.GET_PAYMENT_METHODS, fetchPaymentMethods);
};

export const useFetchDefaultBackupPaymentMethods = () => {
  const fetchDefaultBackupPaymentMethods = async () => {
    const resp = await getPaymentMethodsDefaultBackup();
    return resp;
  };

  return useQuery(
    QueryKeys.GET_PAYMENT_METHODS_DEFAULTBACKUP,
    fetchDefaultBackupPaymentMethods,
    {
      staleTime: 0,
    }
  );
};
