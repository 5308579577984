import React, { useEffect, useState } from 'react';
import { PaymentPlans, ScheduledPayment } from 'src/types/payments';
import StatusTable from '../status-table';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import StatusChip from '../status-chip';
import { dateFormat } from 'src/helpers/format';
import { currencyFormatWithDecimals } from 'src/helpers/format';
import {
  generateAriaLabelElement,
  generateAriaLabelStrong,
  getAriaLabel,
} from 'src/helpers/aria-label-helper';

interface ScheduledPaymentsProps {
  dataScheduledPayments: ScheduledPayment[];
  dataPaymentPlans: PaymentPlans[];
}

export default function ScheduledPayments({
  dataScheduledPayments,
  dataPaymentPlans,
}: ScheduledPaymentsProps) {
  let counter = 0;
  const [duePaymentPlans, setDuePaymentPlans] = useState<number[]>([]);

  useEffect(() => {
    const filteredData = dataPaymentPlans.filter(
      (item) => item.status === 'pastdue'
    );
    if (filteredData.length > 0) {
      const matchObjects = filteredData.map((item) => item.paymentPlanSisId);
      setDuePaymentPlans((prevArray) => [...prevArray, ...matchObjects]);
    }
  }, []);
  const taxAlertMessage =
    'Sales tax will be calculated and added to all payment amounts at the time of payment.';
  const scheduledStatusHeader: GridColDef[] = [
    {
      field: 'dueDate',
      headerName: 'Due Date',
      type: 'string',
      flex: 1,
      sortable: false,
      cellClassName: 'not-clickable',
      valueFormatter: ({ value }) =>
        dateFormat(value, {
          month: 'numeric',
          day: 'numeric',
          year: 'numeric',
        }),
    },
    {
      field: 'programDisplayName',
      headerName: 'Program',
      type: 'string',
      flex: 2,
      sortable: false,
      cellClassName: 'not-clickable',
      renderCell: (params: GridRenderCellParams) => {
        const ariaLabelElement = generateAriaLabelElement(params.value);
        return ariaLabelElement;
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      type: 'string',
      flex: 1,
      sortable: false,
      headerAlign: 'left',
      align: 'left',
      cellClassName: 'not-clickable',
      renderCell: (params: GridRenderCellParams) => {
        const ariaLabel = getAriaLabel(params.value);
        const pastPayment = duePaymentPlans.includes(
          params?.row?.paymentPlanSisId
        );
        if (pastPayment) {
          params.formattedValue = 'missed';
          params.row.status = 'missed';
          params.row.type = 'Payment';
        }
        return params?.row?.type !== 'Fee' ? (
          <StatusChip aria-label={ariaLabel} params={params} />
        ) : (
          <div aria-label="Empty Cell"></div>
        );
      },
    },
    {
      field: 'type',
      headerName: 'Type',
      type: 'string',
      flex: 1,
      sortable: false,
      headerAlign: 'left',
      align: 'left',
      cellClassName: 'not-clickable',
      renderCell: (params: GridRenderCellParams) => {
        const ariaLabelElement = generateAriaLabelElement(params.value);
        return ariaLabelElement;
      },
    },
    {
      field: 'amountDue',
      headerName: 'Amount',
      type: 'number',
      flex: 1,
      sortable: false,
      headerAlign: 'right',
      align: 'right',
      cellClassName: 'not-clickable',
      valueFormatter: ({ value }) => `${currencyFormatWithDecimals(value, 2)}`,
      renderCell: (params: GridRenderCellParams) => {
        const ariaLabelStrong = generateAriaLabelStrong(params.formattedValue);
        return ariaLabelStrong;
      },
    },
  ];

  //TODO: Replace this function with a mechanism to get a unique id for scheduled payments with type = 'Fee'
  function generateUniqueId(): string {
    const timestampPart = Date.now().toString(36);
    const counterPart = (counter++).toString(36);
    return timestampPart + counterPart;
  }
  const rowsWithIds = dataScheduledPayments.map((row) => ({
    ...row,
    id: generateUniqueId(),
  }));
  return (
    <StatusTable
      title="Upcoming Payments"
      rows={rowsWithIds}
      header={scheduledStatusHeader}
      alertMessage={taxAlertMessage}
      noRowsMessage="No upcoming payments available"
    />
  );
}
