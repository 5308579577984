import { useMemo, useState } from 'react';
import { ElectivesNotificationCard, MyProgramCard } from 'src/components/cards';
import GradesCard from 'src/components/grades-card';
import AppLayout from 'src/components/layout';
import MyElectivesCard from 'src/components/my-electives-card';
import ExpansionPanelWithItems from 'src/components/program-expansion-panel/expansion-panel-with-items';
import ExpansionSkeleton from 'src/components/program-expansion-panel/expansion-skeleton';
import { DegreeAuditTerm, PathwayStatusValues } from 'src/types/degree-audit-term';
import { Enrollment } from 'src/types/enrollment';
import { Box, Grid, SelectChangeEvent } from '@mui/material';
import { CircularProgress } from '@pennfoster/pfc-design-system';
import ProgramsHeader, { ProgramsHeaderProps } from './header';
import LoadingComponent from '../loading-component';
import PathwaysNotificationCard from '../cards/pathways-notification-card';
import useCreditPercentage from 'src/hooks/credit-percentage';
import LexisNexisNotificationCard from '../cards/lexis-nexis-notification-card';
import { LEGACY_ELECTIVE_PLACEHOLDER, LEXIS_NEXIS } from 'src/constants/cardStatus';

export interface ProgramsViewProps {
  enrollment: Enrollment;
  terms: DegreeAuditTerm[];
  currentTerm: DegreeAuditTerm;
  hideGrades: boolean;
}

export default function ProgramsView({ enrollment, terms, currentTerm, hideGrades }: ProgramsViewProps) {
  const [selectedTerm, setSelectedTerm] = useState<DegreeAuditTerm>(currentTerm);
  const [isLoadingUrlCourses, setIsLoadingUrlCourses] = useState<boolean>(false);

  // Handlers

  const termChangeHandler = (event: SelectChangeEvent<unknown>) => {
    const selectedId = event.target.value as number;
    const selectedTerm = terms.find((term) => term.termId == selectedId);
    if (!selectedTerm) {
      console.error(`Invalid termId passed: ${selectedId}`);
      return;
    }
    setSelectedTerm(selectedTerm);
  };

  // Assigning Props

  const isCurrentTermSelected = selectedTerm.termId === enrollment.currentTerm;
  const enrolledElectiveCoursesNames = currentTerm.electives.map((e) => e.name);

  // TODO Revisit this
  const showChooseElectivesCard =
    selectedTerm.electivePlaceHolders.length > 0 && // only displays if selected terms has electives
    isCurrentTermSelected; // displays once student is enrolled in that term.

  const programDisplayName = enrollment.program?.programDisplayName || enrollment.program?.programName || '';

  const creditPercentage = useMemo(
    () => useCreditPercentage(enrollment, selectedTerm),
    [enrollment, enrollment.program?.totalNumberTerms, selectedTerm]
  );

  const headerProps: ProgramsHeaderProps = {
    programDisplayName,
    selectedTerm,
    terms,
    termChangeHandler,
  };

  if (isLoadingUrlCourses) {
    return <LoadingComponent label="Entering Classroom" />;
  }

  const isLexisNexisNotificationCardRequired = selectedTerm.cardStatus.includes(LEXIS_NEXIS);
  const showLSAPlaceHolders = selectedTerm.cardStatus.includes(LEGACY_ELECTIVE_PLACEHOLDER);

  return (
    <AppLayout>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={3}>
          <Grid item xs={10} md={6}>
            <ProgramsHeader {...headerProps} />
          </Grid>
          <Grid item xs={2} md={1} sx={{ textAlign: 'end', alignSelf: 'flex-end' }}>
            <CircularProgress value={creditPercentage} color="secondary" />
          </Grid>
          <Grid item xs={12} md={8} sx={{ alignContent: 'flex-start' }}>
            {selectedTerm ? (
              <ExpansionPanelWithItems
                showLSAPlaceHolders={showLSAPlaceHolders}
                term={selectedTerm}
                termId={selectedTerm.termId}
                isCurrentTerm={isCurrentTermSelected}
                learningEnabled={enrollment.learningEnabled}
                enrollment={enrollment}
                setIsLoadingUrlCourses={setIsLoadingUrlCourses}
              />
            ) : (
              <ExpansionSkeleton count={4} />
            )}
          </Grid>
          <Grid container item xs={12} md={4} spacing={3} sx={{ alignContent: 'flex-start' }}>
            {currentTerm.pathwayStatus !== PathwayStatusValues.None && (
              <Grid item xs={12}>
                {(currentTerm.pathwayStatus === PathwayStatusValues.Pathway ||
                  currentTerm.pathwayStatus === PathwayStatusValues.ElectiveAndPathway) &&
                !showLSAPlaceHolders ? (
                  <PathwaysNotificationCard pathwayStatus={currentTerm.pathwayStatus} />
                ) : (
                  <ElectivesNotificationCard showLSAPlaceHolders={showLSAPlaceHolders} />
                )}
              </Grid>
            )}
            {isLexisNexisNotificationCardRequired && (
              <Grid item xs={12}>
                <LexisNexisNotificationCard />
              </Grid>
            )}
            <Grid item xs={12}>
              <MyProgramCard />
            </Grid>
            {!hideGrades && (
              <Grid item xs={12}>
                <GradesCard enrollment={enrollment} />
              </Grid>
            )}
            {showChooseElectivesCard && (
              <Grid item xs={12}>
                <MyElectivesCard
                  nEnrolledElectives={currentTerm.enrolledElectives}
                  nNeededElectives={currentTerm.neededElectives}
                  enrolledElectiveCoursesNames={enrolledElectiveCoursesNames}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Box>
    </AppLayout>
  );
}
