import { useContext, useMemo } from 'react';
import EnrollmentContext from 'src/context/enrollment';
import UserContext from 'src/context/user';

import LoadingComponent from '../loading-component';

interface Props {
  children?: React.ReactNode;
  themeIsLoading: boolean;
}

export function InitialLoadingState({ children, themeIsLoading }: Props) {
  const { queryStatus: userQueryStatus } = useContext(UserContext);
  const { queryStatus: enrollmentQueryStatus } = useContext(EnrollmentContext);

  const isLoading: boolean = useMemo(() => {
    return (
      enrollmentQueryStatus === 'loading' ||
      userQueryStatus === 'loading' ||
      themeIsLoading
    );
  }, [themeIsLoading, userQueryStatus, enrollmentQueryStatus]);

  if (isLoading) {
    return <LoadingComponent label={'Loading'} />;
  }

  return <>{children}</>;
}
