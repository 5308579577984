import { AxiosResponse } from 'axios';
import { Course } from 'src/types/course';

import { instance } from './apiConfig';

export function getCourses(): Promise<AxiosResponse<Course[]>> {
  return instance({
    method: 'GET',
    url: `/user/courses`,
  });
}

export function getCourseUrl(
  enrollmentId: string | undefined,
  courseId: number | null
): Promise<AxiosResponse<string>> {
  if (!enrollmentId || courseId === null) {
    return Promise.reject(new Error('Invalid enrollmentId or courseId'));
  }

  return instance({
    method: 'GET',
    url: `/user/enrollments/${enrollmentId}/courses/${courseId}/lmsurl`,
  });
}

export function getEnrollmentCourses(
  enrollmentId: string,
  numCourses = 3
): Promise<AxiosResponse<Course[]>> {
  return instance({
    method: 'GET',
    url: `/user/enrollments/${enrollmentId}/courses?numCourses=${numCourses}`,
  });
}
