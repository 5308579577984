export const normalizeCardNumber = (value: string) =>
  value
    .replace(/\s/g, '')
    .match(/.{1,4}/g)
    ?.join(' ')
    .substr(0, 19) || '';
export const normalizeExpiry = (value: string) =>
  value
    .replace(/[^\d]/g, '')
    .replace(
      /^(\d{2})(\d*)$/,
      (match, first, second) => first + (second.length ? `/${second}` : '')
    );

export const normalizeNumberInput = (value: string) =>
  value.replace(/[^\d]/g, '');

export default {
  normalizeCardNumber,
  normalizeExpiry,
  normalizeNumberInput,
};
