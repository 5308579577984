import React from 'react';
import { Button, Card } from '@pennfoster/pfc-design-system';
import { CardContent, CardHeader, Divider, CardActions } from '@mui/material';
import { Subtitle1 } from 'src/styles';
import { useNavigate } from 'react-router-dom';
import { MANAGE_PAYMENT } from 'src/routes/routemap';
import CreditCardItem from './credit-card-item';
import { PaymentMethod } from 'src/types/payments';

interface PaymentMethodsCardProps {
  paymentMethods: PaymentMethod[];
}

export default function PaymentMethodsCard({
  paymentMethods,
}: PaymentMethodsCardProps) {
  const navigate = useNavigate();
  const handleClick = async () => {
    navigate(MANAGE_PAYMENT);
  };

  return (
    <>
      <Card
        pfVariant="default"
        borderColor="common.white"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <CardHeader
          sx={{ px: 3, pt: 3, pb: 2, flex: 0 }}
          title={<Subtitle1>Payment Methods</Subtitle1>}
        />
        <Divider aria-hidden="true" sx={{ margin: '0 24px' }} />

        <CardContent
          sx={{
            p: 3,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {paymentMethods ? (
            paymentMethods.map((paymentMethod, index) => (
              <CreditCardItem key={index} paymentMethod={paymentMethod} />
            ))
          ) : (
            <>
              <p>There are no default/backup payment methods selected.</p>
            </>
          )}
        </CardContent>
        <CardActions
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            margin: '0 24px 24px 0',
            padding: 0,
          }}
        >
          <Button
            label="Manage Payment Methods"
            size="large"
            color="primary"
            variant="outlined"
            onClick={handleClick}
          />
        </CardActions>
      </Card>
    </>
  );
}
