import { DegreeAuditTerm } from 'src/types/degree-audit-term';

import { Box, MenuItem, SelectChangeEvent, Typography } from '@mui/material';

import TermSelect from './semester-select';

export interface ProgramsHeaderProps {
  programDisplayName: string;
  selectedTerm: DegreeAuditTerm;
  terms: DegreeAuditTerm[];
  termChangeHandler: (event: SelectChangeEvent<unknown>) => void;
}

const ProgramsHeader = ({
  programDisplayName,
  selectedTerm,
  terms,
  termChangeHandler,
}: ProgramsHeaderProps) => (
  <Box>
    <Typography variant="labelLarge" lineHeight="24px" component={'span'}>
      {programDisplayName}
    </Typography>
    {terms.length > 1 && (
      <>
        <Typography
          variant="labelLarge"
          lineHeight="24px"
          component={'span'}
          sx={{ mx: 2 }}
        >
          -
        </Typography>
        <Typography
          variant="labelLarge"
          component={'span'}
        >{`Semester ${selectedTerm.termId}`}</Typography>
        <TermSelect
          id="term-select"
          value={selectedTerm}
          onChange={termChangeHandler}
        >
          {terms
            .sort((a, b) => a.termId - b.termId)
            .map((s) => (
              <MenuItem key={s.termId} value={s.termId}>
                Semester {s.termId}
              </MenuItem>
            ))}
        </TermSelect>
      </>
    )}
    <Typography variant="headingH6" component="h1" lineHeight="40px">
      Courses and Grades
    </Typography>
  </Box>
);

export default ProgramsHeader;
