import { useState } from 'react';
import { Box, Tab, Tabs } from '@mui/material';
import { AdminHeader } from './admin-header';
import { AdminLayout } from './admin-layout';
import { AdminTabPanel } from './admin-tab-panel';
import { AdminImpersonate } from './admin-impersonate';
import { AdminUsernameChange } from './admin-username-change';

const tabs = [
  {
    label: 'Impersonate',
    component: <AdminImpersonate />,
  },
  {
    label: 'Username Change',
    component: <AdminUsernameChange />,
  },
];

export function AdminPage() {
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box>
      <AdminHeader />
      <AdminLayout>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 10 }}>
          <Tabs value={value} onChange={handleChange} aria-label="admin tabs">
            {tabs.map((tab) => (
              <Tab key={tab.label} label={tab.label} />
            ))}
          </Tabs>
        </Box>
        {tabs.map((tab, index) => (
          <AdminTabPanel key={tab.label} value={value} index={index}>
            {tab.component}
          </AdminTabPanel>
        ))}
      </AdminLayout>
    </Box>
  );
}
