import { Subtitle1 } from 'src/styles';

import { Box } from '@mui/material';

export default function PathwayCartHeader() {
  return (
    <Box display="flex" justifyContent="space-between" sx={{ pb: '16px' }}>
      <Subtitle1 display="inline">My Pathway</Subtitle1>
    </Box>
  );
}
