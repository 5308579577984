/* eslint-disable react/react-in-jsx-scope */
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import LoadingComponent from 'src/components/loading-component';
import { AUTH_LOGIN } from 'src/routes/routemap';

export default function AuthLogoutCallback() {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(AUTH_LOGIN);
  }, []);

  return <LoadingComponent label={'Loading'} />;
}
