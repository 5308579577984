/* eslint-disable react/react-in-jsx-scope */
import { useEffect } from 'react';
import { redirectToLogin } from '../../../helpers/redirect-to-login';
import LoadingComponent from 'src/components/loading-component';
import { ResponseState } from 'src/types/auth';

interface Props {
  responseState?: ResponseState;
}

export default function AuthLogin({ responseState }: Props) {
  useEffect(() => {
    redirectToLogin(responseState, 'Login');
  }, []);
  return <LoadingComponent label={'Loading'} />;
}
