import React, { ReactNode } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { Paper, DialogTitle, DialogContent } from '@mui/material';
import { Dialog } from '@pennfoster/pfc-design-system';

interface DialogProps {
  title?: string;
  open: boolean;
  children: ReactNode;
  contentText?: ReactNode;
  handleClose: () => void;
}

export default function DialogContainer(props: DialogProps) {
  const { handleClose, children, open, title, contentText } = props;
  const theme = useTheme();
  const StyledPaper = styled(Paper)`
    background-color: ${theme.palette.background.default};
  `;

  return (
    <Dialog
      sx={{}}
      BackdropProps={{
        style: {
          backdropFilter: 'blur(25px)',
          backgroundColor: 'rgb(0, 0, 0)',
          opacity: '0.70',
        },
      }}
      onClose={handleClose}
      PaperComponent={StyledPaper}
      PaperProps={{
        style: {
          color: 'black',
          maxWidth: '516px',
        },
      }}
      open={open}
    >
      <DialogTitle
        sx={{
          paddingTop: 0,
          paddingLeft: 0,
          paddingRight: 0,
        }}
      >
        {title}
      </DialogTitle>
      {contentText}
      <DialogContent sx={{ padding: 0 }}>{children}</DialogContent>
    </Dialog>
  );
}
