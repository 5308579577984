import React from 'react';
import NotificationCard from './notification-card';
import { pathwaysCardProps } from './pathways-card-props';
import { PathwayStatusValues } from 'src/types/degree-audit-term';

interface NotificationCardProps {
  pathwayStatus: PathwayStatusValues;
}

export default function PathwaysNotificationCard({
  pathwayStatus,
}: NotificationCardProps) {
  const notificationProps = pathwaysCardProps(
    pathwayStatus
  );
  return (
    <NotificationCard
      content={notificationProps.content}
      buttons={notificationProps.buttons}
      media={notificationProps.media}
      title={notificationProps.title}
    />
  );
}
