import React, { useContext } from 'react';
import EnrollmentContext from 'src/context/enrollment';
import { Body1 } from 'src/styles';

import { Box, Radio, RadioGroup } from '@mui/material';
import { getProgramName } from 'src/helpers/get-program-name';

interface MultipleProgramsProps {
  defaultProgramId: string | undefined;
  onClick: (enrollmentId: string) => void;
}

export default function MultiplePrograms({
  defaultProgramId,
  onClick,
}: MultipleProgramsProps) {
  const { userEnrollment } = useContext(EnrollmentContext);

  if (!userEnrollment) {
    return null;
  }

  return (
    <RadioGroup
      sx={{ pt: 1 }}
      aria-label="Programs"
      defaultValue={defaultProgramId}
    >
      {userEnrollment.enrollments.map((enrollmentItem) => (
        <Box
          key={enrollmentItem.enrollmentId}
          sx={{
            alignItems: 'start',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <Box
            sx={{
              alignContent: 'center',
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
            }}
          >
            <Radio
              checked={defaultProgramId === enrollmentItem.enrollmentId}
              value={enrollmentItem.enrollmentId}
              onClick={() => onClick(enrollmentItem.enrollmentId)}
              inputProps={{
                'aria-label': getProgramName(enrollmentItem),
              }}
            />
            <Body1>{getProgramName(enrollmentItem)}</Body1>
          </Box>
        </Box>
      ))}
    </RadioGroup>
  );
}
