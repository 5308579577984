import { atom } from "jotai";
import { usernameChange } from "src/types/username-change";
  
export const initialUsernameChangeState = {
    studentNumber: '',
    desiredUsername: '',
}

export const studentNumberAtom = atom<string>('');
export const reasonAtom = atom<string>('');
export const isImpersonatingAtom = atom<boolean>(false);
export const usernameChangeAtom = atom<usernameChange>(initialUsernameChangeState);
