import { Skeleton } from '@pennfoster/pfc-design-system';

type ButtonSize = 'small' | 'large';

interface Props {
  width?: number | string;
  size?: ButtonSize;
}

const ButtonSizeMap = {
  large: '42px',
  small: '24px',
};

export function ButtonSkeleton({ width, size = 'small' }: Props) {
  return (
    <Skeleton
      variant={'rounded'}
      width={width}
      sx={{ borderRadius: '99px' }}
      height={ButtonSizeMap[size]}
    />
  );
}
