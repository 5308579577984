import React from 'react';
import {
  ProgressStatus,
  ProgressStatusLabels,
} from 'src/types/progress-status';

import { useTheme } from '@mui/material';
import { Chip } from '@pennfoster/pfc-design-system';

export interface ProgressChipProps {
  status: ProgressStatus | undefined;
  isStatusForLesson: boolean | false;
}

export default function ProgressChip({
  status,
  isStatusForLesson,
}: ProgressChipProps) {
  const theme = useTheme();
  const color = () => {
    if (status == ProgressStatus.Locked) {
      return 'rgba(162, 166, 172, 1)';
    }

    return 'rgba(15, 23, 34, 1)';
  };

  const iconName = () => {
    if (status && status == ProgressStatus.Complete) {
      return 'Check';
    }
    if (status && status == ProgressStatus.Failed) {
      return 'Block';
    }
    if (status && status == ProgressStatus.InProgress) {
      return 'PendingOutlined';
    }
    if (status && status == ProgressStatus.Locked) {
      return 'LockOutlined';
    }

    if (status && status == ProgressStatus.NotStarted) {
      return 'NotStartedOutlined';
    }
    return '';
  };
  const getBackgroundColor = () => {
    if (status === null) return theme.palette.neutral.light;

    if (isStatusForLesson) return 'transparent';

    if (status == ProgressStatus.Complete) {
      return theme.palette.success.container;
    }

    if (status == ProgressStatus.Locked) {
      return theme.palette.background['MUI-disabled'];
    }

    if (status == ProgressStatus.Failed) {
      return theme.palette.error.container;
    }

    if (status == ProgressStatus.InProgress) {
      return theme.palette.secondary.container;
    }

    if (status === ProgressStatus.NotStarted) {
      return theme.palette.background.default;
    }

    return 'theme.palette.neutral.light';
  };

  const getBorderColor = () => {
    if (status === null) return theme.palette.neutral.light;

    if (isStatusForLesson) return 'transparent';

    if (status == ProgressStatus.Complete) {
      return theme.palette.success.light;
    }

    if (status == ProgressStatus.Locked) {
      return 'rgba(0, 0, 0, 0.1)';
    }

    if (status == ProgressStatus.Failed) {
      return theme.palette.error.light;
    }

    if (status == ProgressStatus.InProgress) {
      return theme.palette.secondary.light;
    }

    if (status === ProgressStatus.NotStarted) {
      return 'rgba(162, 166, 172, 1)';
    }

    return 'theme.palette.neutral.light';
  };

  return (
    <Chip
      aria-label={`Status: ${ProgressStatusLabels[status as ProgressStatus]}`}
      sx={{ color: color(), fontSize: '12px', fontWeight: 'bold' }}
      pfVariant="outlined"
      size="small"
      iconName={iconName()}
      label={ProgressStatusLabels[status as ProgressStatus]}
      backgroundColor={getBackgroundColor()}
      borderColor={getBorderColor()}
    />
  );
}
