import React from 'react';
import { Box, Radio, RadioGroup } from '@mui/material';
import { ProgramDTO } from 'src/types/enrollment';
import { Body1, Subtitle1 } from 'src/styles';
import { Control, Controller } from 'react-hook-form';
import { currencyFormatWithDecimals } from 'src/helpers/format';
import { IFormInput } from '.';
import HowToPaySkeleton from './skeleton/how-to-pay-skeleton';

interface HowToPayProps {
  handleClick: (individualPayments: boolean) => void;
  selectedProgram: ProgramDTO | undefined;
  control: Control<IFormInput, any>;
  remainingBalance: number;
}

interface PaymentOptionProps {
  label: string;
  value: boolean;
  onClick: (value: boolean) => void;
}

function PaymentOption({ label, value, onClick }: PaymentOptionProps) {
  return (
    <Box
      sx={{
        alignItems: 'start',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <Box
        sx={{
          alignContent: 'center',
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
        }}
      >
        <Radio
          value={value}
          onClick={() => onClick(value)}
          inputProps={{
            'aria-label': label,
          }}
        />
        <Body1>{label}</Body1>
      </Box>
    </Box>
  );
}

export default function HowToPay({
  handleClick,
  control,
  selectedProgram,
  remainingBalance,
}: HowToPayProps) {
  const individualPaymentsLabel = 'Individual Payments';

  let balanceLabel = 'Full Balance';

  if (remainingBalance && selectedProgram?.programName) {
    const formattedBalance = currencyFormatWithDecimals(remainingBalance);
    balanceLabel = `${formattedBalance} - ${
      selectedProgram?.programName ?? ''
    } - ${balanceLabel}`;
  }

  return !selectedProgram ? (
    <HowToPaySkeleton />
  ) : (
    <>
      <Subtitle1 variant="h2">How to Pay</Subtitle1>
      <Controller
        render={({ field }) => (
          <RadioGroup sx={{ pt: 1 }} {...field}>
            <PaymentOption
              label={individualPaymentsLabel}
              value={true}
              onClick={handleClick}
            />
            <PaymentOption
              label={balanceLabel}
              value={false}
              onClick={handleClick}
            />
          </RadioGroup>
        )}
        name="howToPay"
        control={control}
      />
    </>
  );
}
