import { Grid } from '@mui/material';
import {
  PaymentsCardSkeleton,
  PaymentPlansCardSkeleton,
  TasksCardSkeleton,
  UpcomingCoursesCardSkeleton,
} from 'src/components/skeleton-components';

export function PaymentsLoading() {
  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <PaymentsCardSkeleton />
      </Grid>
      <Grid item xs={6}>
        <TasksCardSkeleton actions={true} />
      </Grid>
      <Grid item xs={12}>
        <PaymentPlansCardSkeleton />
      </Grid>
      <Grid item xs={12}>
        <UpcomingCoursesCardSkeleton
          action={false}
          coursesCount={7}
          divider={false}
          status={false}
        />
      </Grid>
    </Grid>
  );
}
