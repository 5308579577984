import React, { useEffect, useState, ReactElement } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { TokenHelper } from 'src/helpers/token-helper';
import AuthLogin from 'src/pages/auth/login';
import AuthTokens from 'src/auth/auth-tokens';
import { refreshToken as refreshAuthToken } from 'src/auth/refresh-token';
import LoadingComponent from 'src/components/loading-component';
import { JsxElement } from 'typescript';
import DegreeAuditProvider from 'src/providers/degree-audit';
import EnrollmentProvider from 'src/providers/enrollment';
import NotificationsProvider from 'src/providers/notifications';
import PaymentMethodProvider from 'src/providers/payment-method';
import UserProvider from 'src/providers/user';
import { InitialLoadingState } from 'src/components/initial-loading-state';
import ChatbotProvider from 'src/providers/chatbot';
import { ADMIN, DASHBOARD } from './routemap';
import { useAtom } from 'jotai';
import { isImpersonatingAtom } from 'src/state';

export default function PrivateRoute() {
  const [component, setComponent] = useState<ReactElement<JsxElement>>(<></>);
  const tokenHelper = new TokenHelper(AuthTokens.getAccessToken());
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [isImpersonating] = useAtom(isImpersonatingAtom);
  const isAdmin = tokenHelper.isAdmin();

  const app = (
    <UserProvider>
      <EnrollmentProvider>
        <DegreeAuditProvider>
          <ChatbotProvider>
            <PaymentMethodProvider>
              <NotificationsProvider>
                {/* TODO: we're hard coding in themeIsLoading?
                  This value needs to be passed in or removed.
                  Mick Piereder 2023.10.17 */}
                <InitialLoadingState themeIsLoading={false}>
                  <Outlet />
                </InitialLoadingState>
              </NotificationsProvider>
            </PaymentMethodProvider>
          </ChatbotProvider>
        </DegreeAuditProvider>
      </EnrollmentProvider>
    </UserProvider>
  );

  const redirectToAdminPage = () => {
    navigate(isAdmin ? ADMIN : DASHBOARD);
  };

  useEffect(() => {
    isAdmin && !isImpersonating && redirectToAdminPage();
  }, [pathname]);

  useEffect(() => {
    if (AuthTokens.getAccessToken() && !tokenHelper.isTokenExpired()) {
      isAdmin && !isImpersonating && redirectToAdminPage();
      setComponent(app);
    } else if (AuthTokens.getRefreshToken()) {
      refreshAuthToken()
        .then(() => {
          isAdmin && !isImpersonating && redirectToAdminPage();
          setComponent(app);
        })
        .catch((err) => {
          console.error(err);
        });

      setComponent(<LoadingComponent label={'Loading'} />);
    } else {
      setComponent(<AuthLogin responseState={{ targetUrl: location.pathname, policyType: 'Login' }} />);
    }
  }, []);

  return component;
}
