import * as signalR from '@microsoft/signalr';

export class BaseSignalRConnection {
  protected _connection: signalR.HubConnection;
  protected _isConnected = false;

  constructor(connectionUrl: string) {
    if (!connectionUrl) {
      throw new Error('Connection URL is not defined');
    }

    this._connection = new signalR.HubConnectionBuilder()
      .withUrl(connectionUrl)
      .withAutomaticReconnect()
      .build();

    this._connection.onreconnecting((error) => {
      if (error) {
        console.error('An error occurred while reconnecting:', error);
      } else {
        console.info('Reconnecting...');
      }
    });

    this._connection.onreconnected((connectionId) => {
      if (!connectionId) {
        console.error('Reconnection failed');
      } else {
        console.info('Reconnected with connection ID:', connectionId);
      }
      this._isConnected = true;
    });

    this._connection.onclose((error) => {
      if (error) {
        console.error('An error occurred while closing the connection:', error);
      } else {
        console.info('Connection closed');
      }
      this._isConnected = false;
    });
  }

  public get isConnected(): boolean {
    return this._isConnected;
  }

  public get connection(): signalR.HubConnection {
    return this._connection;
  }

  public async startConnection(): Promise<void> {
    if (process.env.NODE_ENV === 'development') {
      return;
    }

    try {
      await this._connection.start();
      this._isConnected = true;
      console.info('SignalR connection established');
    } catch (error) {
      console.error('Connection failed', error);
      this._isConnected = false;
    }
  }
}
