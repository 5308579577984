import { Enrollment } from 'src/types/enrollment';

export const getProgramName = (enrollment: Enrollment) => {
  const programName =
    (enrollment.program?.programDisplayName ??
      enrollment.program?.programName) ||
    (enrollment.program?.programDisplayName === ''
      ? enrollment.program?.programName
      : enrollment.program?.programDisplayName) ||
    '';
  return programName;
};

export const getProgramNameByParams = (
  programDisplayName: string | null | undefined,
  programName: string | null | undefined
) => {
  const result =
    programDisplayName !== null &&
    programDisplayName !== undefined &&
    programDisplayName !== ''
      ? programDisplayName
      : programName !== null && programName !== undefined && programName !== ''
      ? programName
      : '';
  return result;
};
