import { Card } from '@pennfoster/pfc-design-system';
import { ReactNode } from 'react';

interface Props {
  children?: ReactNode;
  size?: 'small' | 'large';
}

export function CardSkeleton({ children, size = 'large' }: Props) {
  const sizeSx = {
    large: {
      pl: 4,
      pb: 4,
      pr: 4,
      pt: 0,
    },
    small: {
      pl: 3,
      pb: 3,
      pr: 3,
      pt: 0,
    },
  };
  return (
    <Card pfVariant={'default'} sx={sizeSx[size]}>
      {children}
    </Card>
  );
}
