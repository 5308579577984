/**
 * Shortens a string to the character length provided and adds an ellipsis to the end.
 *
 * @param string - The maximum characters a string should be.
 * @param maxLength - The maximum characters a string should be.
 * @returns {string} - Shortened string with ellipsis added to the end.
 */
export function shortenToEllipsis(string: string, maxLength: number): string {
  if (string.length < maxLength) {
    return string;
  }
  return string.substring(0, maxLength).trim().concat('...');
}
