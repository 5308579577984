import { useContext, useMemo } from 'react';
import { useMutation, useQuery } from 'react-query';
import { getElectivePools, postElectives } from 'src/api';
import EnrollmentContext from 'src/context/enrollment';
import { PostElectivesDto } from 'src/types/api';
import { SISCourseId } from 'src/types/course';
import {
  ElectivePool,
  SelectedElective,
  SISElectivePoolId,
} from 'src/types/electives';
import QueryKeys from 'src/types/query-keys';
import { is2xx } from 'src/utils/http-utils';

import { useDegreeAuditQuery } from './degree-audit';
import DegreeAuditContext from 'src/context/degree-audit';
import { DegreeAuditTerm, PathwayStatusValues } from 'src/types/degree-audit-term';
import { COURSES, ELECTIVES, PATHWAY } from 'src/routes/routemap';

export type ElectivesCalculations = {
  electivePools: ElectivePool[];
  nEnrolledElectives: number;
  nNeededElectives: number;
};

export function useElectivesQuery(currentTerm: DegreeAuditTerm | undefined) {
  const { enrollment } = useContext(EnrollmentContext);
  if (!currentTerm) {
    const e = 'Unable to query electives. Degree Audit unavailable.';
    throw new Error(e);
  }
  const termId = currentTerm.termId;
  if (!currentTerm.termId) {
    const e = 'Unable to query electives. Term ID unavailable.';
    throw new Error(e);
  }
  const queryFn = () => {
    if (!enrollment?.enrollmentId) {
      const e = 'Unable to query electives. Enrollment ID unavailable.';
      throw new Error(e);
    }
    return getElectivePools(enrollment.enrollmentId, termId);
  };

  return useQuery(
    [QueryKeys.GET_ELECTIVE_POOLS, enrollment?.enrollmentId, termId],
    queryFn,
    { enabled: !!enrollment?.enrollmentId }
  );
}

export function useElectivesMutation(currentTerm: DegreeAuditTerm | undefined) {
  const { refetch: degreeAuditRefetch } = useDegreeAuditQuery();
  const { refetch: electivesRefetch } = useElectivesQuery(currentTerm);
  const { enrollment, refetch: enrollmentRefetch } =
    useContext(EnrollmentContext);

  const mutationFn = async (selectedCourses: SelectedElective[]) => {
    if (!enrollment?.enrollmentId) {
      throw new Error('Unable to mutate elective. Enrollment ID unavailable.');
    }

    const { enrollmentId } = enrollment;
    const poolCourseMap: { [epId: SISElectivePoolId]: SISCourseId[] } = {};
    const payload: PostElectivesDto = { electivePoolCourseSelections: [] };

    selectedCourses.forEach((course) => {
      const { sisCourseId, sisElectivePoolId } = course;
      if (sisElectivePoolId in poolCourseMap) {
        poolCourseMap[sisElectivePoolId].push(sisCourseId);
      } else {
        poolCourseMap[sisElectivePoolId] = [sisCourseId];
      }
    });

    payload.electivePoolCourseSelections = Object.entries(poolCourseMap).map(
      ([electivePoolId, courseIds]) => {
        return {
          courseIds,
          electivePoolId: +electivePoolId,
        };
      }
    );

    const resp = await postElectives(enrollmentId, payload);

    if (!is2xx(resp.status)) {
      throw new Error(`Elective POST did not return 2xx. Response:\n${resp}`);
    }

    await Promise.all([
      enrollmentRefetch(),
      degreeAuditRefetch(),
      electivesRefetch(),
    ]);
    return resp;
  };

  const mutation = useMutation({ mutationFn });
  return mutation;
}

export function useElectivesCalculations() {
  const { currentTerm } = useContext(DegreeAuditContext);
  const electivesQuery = useElectivesQuery(currentTerm);

  const electivePools = useMemo(() => {
    if (
      !electivesQuery.isSuccess ||
      !Array.isArray(electivesQuery.data?.data) ||
      currentTerm === undefined
    ) {
      return [];
    }
    // filtering to only elective pools who have a placeholder
    return electivesQuery.data.data.filter((ep) => {
      return currentTerm.electivePlaceHolders.some(
        (eph) => eph.sisElectivePoolId === ep.sisElectivePoolId
      );
    });
  }, [electivesQuery.status, electivesQuery.data, currentTerm]);

  // The number of electives the user has previously enrolled in.
  const nEnrolledElectives: number = useMemo(() => {
    if (!currentTerm || !electivePools) {
      return -1;
    }
    return electivePools.reduce((acc, pool) => {
      return acc + pool.numberCoursesEnrolled;
    }, 0);
  }, [currentTerm, electivePools]);

  // The number of electives the user still needs to enroll in.
  const nNeededElectives: number = useMemo(() => {
    if (nEnrolledElectives === -1 || !electivePools) {
      return -1;
    }

    //Only add elective pools who have coursesRequired
    const nRequired = electivePools.reduce(
      (n, ep) =>
        n + (ep.numberCoursesRequired > 0 ? ep.numberCoursesRequired : 0),
      0
    );
    return nRequired - nEnrolledElectives;
  }, [nEnrolledElectives, electivePools]);

  return {
    electivePools,
    nEnrolledElectives,
    nNeededElectives,
  };
}

export function useElectivesRoutes() {
  const { currentTerm } = useContext(DegreeAuditContext);
  const { enrollment} = useContext(EnrollmentContext);
  if (currentTerm === undefined || enrollment === undefined) {
    throw new Error();
  }

  const electivePathwayRoute = useMemo(() => {
    let navigateUrl = COURSES;

    if(currentTerm.pathwayStatus == PathwayStatusValues.Pathway) {
      navigateUrl = PATHWAY;
    }

    if(currentTerm.pathwayStatus == PathwayStatusValues.Elective) {
      navigateUrl = ELECTIVES;
    }

    if(currentTerm.pathwayStatus == PathwayStatusValues.ElectiveAndPathway) {
      navigateUrl = PATHWAY;
      if(enrollment.selectedPathway) {
        navigateUrl = ELECTIVES;
      }
    }

    navigateUrl = navigateUrl + '?termId=' + currentTerm.termId;
    return navigateUrl;
  }, [currentTerm, enrollment]);

  return {
    electivePathwayRoute
  }
}