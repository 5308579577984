import React, { useContext } from 'react';
import { Box, Grid } from '@mui/material';
import { ProgramDTO } from 'src/types/enrollment';
import { Control, SubmitHandler, UseFormHandleSubmit } from 'react-hook-form';
import { IFormInput } from '.';
import ConfirmationPaymentCard from 'src/components/cards/confirmation-payment-card';
import NotificationPaymentErrorCard from 'src/components/cards/notification-payment-error-card';
import FeEvents from 'src/events/fe-events';
import ChoosePaymentMethod from './choose-payment-method';
import ChoosePayments from './choose-payments';
import ChooseProgram from './choose-program';
import ConfirmationDialog from './confirmation-dialog';
import HowToPay from './how-to-pay';
import { Alert, Button } from '@pennfoster/pfc-design-system';
import UserContext from 'src/context/user';
import { NunitoSansErrorMessage } from 'src/styles';
import {
  CalculateSalesTaxRequest,
  NotificationPaymentErrorCardProps,
  PaymentMethod,
  ScheduledPayment,
} from 'src/types/payments';
import { FormState } from './state';
import TotalPaymentAmount from './total-payment-amount';
import AddCardFormDialog from 'src/components/add-card-form-dialog';
import { cms } from 'src/helpers/language';

interface FormViewProps {
  notificationProps: NotificationPaymentErrorCardProps | null;
  handleSubmit: UseFormHandleSubmit<IFormInput, undefined>;
  onSubmit: SubmitHandler<IFormInput>;
  handleSelectProgram: (program: ProgramDTO) => void;
  onClickHowToPay: (individualPayments: boolean) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<IFormInput, any>;
  total: number;
  paymentsScheduled: ScheduledPayment[];
  handleCheckboxChange: (event: React.ChangeEvent<HTMLInputElement>, payment: ScheduledPayment) => void;
  handleClickChange: () => void;
  onClickPaymentMethod: (paymentMethod: PaymentMethod) => void;
  setAddPaymentMethodOpen: (isOpen: boolean) => void;
  setNewCard: (newCard: PaymentMethod) => void;
  handleCancelClick: () => void;
  shouldShouldAlert: boolean;
  isValid: boolean;
  setDialogOpen: (isOpen: boolean) => void;
  handleClose: () => void;
  buildSalesTaxRequestObject: (data: IFormInput) => CalculateSalesTaxRequest;
  formData: IFormInput;
  state: FormState;
  userInWindowInterval: boolean;
}

export default function FormView({
  handleSubmit,
  onSubmit,
  handleSelectProgram,
  onClickHowToPay,
  control,
  total,
  handleCheckboxChange,
  buildSalesTaxRequestObject,
  state,
  handleClickChange,
  onClickPaymentMethod,
  setAddPaymentMethodOpen,
  setNewCard,
  handleCancelClick,
  shouldShouldAlert,
  isValid,
  setDialogOpen,
  handleClose,
  paymentsScheduled,
  formData,
  userInWindowInterval,
}: FormViewProps) {
  const { user } = useContext(UserContext);
  const {
    dialogOpen,
    individualPaymentsSelected,
    addPaymentMethodOpen,
    isChanged,
    defaultCard,
    paymentMethods,
    selectedscheduledPayments,
    amount,
    paymentTaxesResponse,
    selectedProgram,
    isLoadingTaxes,
    isErrorOnTaxes,
    notificationProps,
    isLoading,
  } = state;
  return (
    <>
      {notificationProps === null ? (
        <Box
          sx={{
            backgroundColor: 'background.paper',
            padding: 3,
          }}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <ChooseProgram onSelectProgram={handleSelectProgram} />
              </Grid>
              <Grid item xs={12}>
                <HowToPay
                  handleClick={onClickHowToPay}
                  control={control}
                  selectedProgram={selectedProgram}
                  remainingBalance={total}
                />
              </Grid>
              {individualPaymentsSelected && (
                <Grid item xs={12}>
                  <ChoosePayments
                    scheduledPayments={paymentsScheduled}
                    selectedscheduledPayments={selectedscheduledPayments}
                    handleCheckboxChange={handleCheckboxChange}
                  />
                </Grid>
              )}
              {amount > total && (
                <Grid item xs={12}>
                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Alert pfVariant="standard" severity="error">
                      <NunitoSansErrorMessage>{cms.content.pages.make_a_payment.payment_more_than_remaining_balance_warning}</NunitoSansErrorMessage>
                    </Alert>
                  </Box>
                </Grid>
              )}
              <Grid item xs={12}>
                <TotalPaymentAmount amount={amount} isLoading={paymentsScheduled?.length === 0} />
              </Grid>
              {userInWindowInterval && (
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2, ml: 2 }}>
                  <Alert pfVariant="standard" severity="error">
                    <NunitoSansErrorMessage>{cms.content.pages.make_a_payment.payment_interval_warning}</NunitoSansErrorMessage>
                  </Alert>
                </Box>
              )}
              <Grid item xs={12}>
                <ChoosePaymentMethod
                  isChanged={isChanged}
                  handleClickChange={handleClickChange}
                  paymentMethods={paymentMethods}
                  onClickPaymentMethod={onClickPaymentMethod}
                  defaultCard={defaultCard}
                  setAddPaymentMethodOpen={setAddPaymentMethodOpen}
                  user={user}
                />
              </Grid>
            </Grid>
            {shouldShouldAlert && (
              <Box sx={{ display: 'flex', justifyContent: 'center', pb: 2 }}>
                <Alert pfVariant="standard" sx={{ alignItems: 'center' }} severity="warning">
                  {cms.content.pages.make_a_payment.payment_past_due_warning}
                </Alert>
              </Box>
            )}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                padding: 0,
                right: 0,
              }}
            >
              <Box
                sx={{
                  alignContent: 'flex-end',
                  justifyContent: 'flex-end',
                  padding: 0,
                  right: 0,
                }}
              >
                <Button label="Cancel" pfVariant="text" size="large" onClick={handleCancelClick} />
                <Button
                  label="Continue"
                  disabled={!isValid || userInWindowInterval || (amount > total)}
                  pfVariant="filled"
                  color="primary"
                  size="large"
                  onClick={() => {
                    buildSalesTaxRequestObject(formData);
                    setDialogOpen(true);
                    FeEvents.trackNamedEvent({
                      eventName: 'ConfirmClickEvent',
                    });
                  }}
                />
              </Box>
            </Box>
            <ConfirmationDialog
              dialogOpen={dialogOpen}
              amount={amount}
              taxAmount={paymentTaxesResponse?.totalAmount ?? 0}
              planName={selectedProgram?.programName}
              paymentMethod={defaultCard}
              setDialogOpen={setDialogOpen}
              handleSubmit={handleSubmit}
              onSubmit={onSubmit}
              isLoading={isLoading}
              isLoadingTaxes={isLoadingTaxes}
              isErrorOnTaxes={isErrorOnTaxes}
            />
            <AddCardFormDialog handleClose={handleClose} open={addPaymentMethodOpen} emitNewCard={setNewCard} />
          </form>
        </Box>
      ) : (
        <Grid item xs={12}>
          {notificationProps.errorTitle !== 'Success' ? (
            <NotificationPaymentErrorCard
              errorTitle={notificationProps.errorTitle}
              errorMessage={notificationProps.errorMessage}
              makePayment={notificationProps.makePayment}
              setNotificationProps={notificationProps.setNotificationProps}
            />
          ) : (
            <ConfirmationPaymentCard confirmationMessage="" />
          )}
        </Grid>
      )}
    </>
  );
}
