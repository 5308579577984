import React, { useContext } from 'react';
import { Body1 } from 'src/styles';

import { Box, Radio, RadioGroup } from '@mui/material';
import EnrollmentContext from 'src/context/enrollment';
import { getProgramName } from 'src/helpers/get-program-name';

interface SingleProgramProps {
  onClick: (enrollmentId: string) => void;
}

function SingleProgram({ onClick }: SingleProgramProps) {
  const { enrollment } = useContext(EnrollmentContext);

  if (!enrollment) {
    return null;
  }

  return (
    <>
      <Box sx={{ pt: 2 }}>
        <Body1>{getProgramName(enrollment)}</Body1>
        <RadioGroup
          sx={{ pt: 1 }}
          aria-label="Program"
          value={enrollment.program?.sisEnrollmentId}
        >
          <Radio
            sx={{ display: 'none' }}
            value={enrollment.program?.sisEnrollmentId}
            onClick={() => onClick(enrollment.enrollmentId)}
            inputProps={{
              'aria-label': getProgramName(enrollment),
            }}
          />
        </RadioGroup>
      </Box>
    </>
  );
}

export default SingleProgram;
