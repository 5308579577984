import React from 'react';
import { UseFormRegisterReturn, UseFormGetValues } from 'react-hook-form';
import { IFormInput } from '.';
import { TextField } from '@pennfoster/pfc-design-system';
import { MenuItem } from '@mui/material';

interface Option {
  value: string;
  label: string;
}

interface InputSelectProps {
  className?: string;
  defaultValue: string;
  name: string;
  label: string;
  register: UseFormRegisterReturn;
  getValues: UseFormGetValues<IFormInput>;
  readOnly: boolean;
  selectValues: Option[];
}

export const FormInputSelect = ({
  defaultValue,
  className,
  name,
  register,
  label,
  getValues,
  readOnly,
  selectValues,
}: InputSelectProps) => {
  return (
    <>
      {defaultValue && (
        <TextField
          className={className}
          defaultValue={defaultValue}
          select
          inputRef={register.ref}
          InputLabelProps={{ shrink: !!getValues }}
          inputProps={{
            onChange: register.onChange,
            onBlur: register.onBlur,
            name: register.name,
            readOnly: readOnly,
          }}
          fullWidth
          id={name}
          label={label}
          value={defaultValue}
          readOnly={readOnly}
          SelectProps={{
            MenuProps: {
              PaperProps: {
                style: {
                  maxHeight: '200px',
                },
              },
            },
          }}
        >
          {selectValues.map((option: Option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      )}
    </>
  );
};
