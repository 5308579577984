import { useState } from 'react';
import { MutationStatus } from 'react-query';

import { Box, SwipeableDrawer, Typography, useTheme } from '@mui/material';
import CartItem from 'src/components/common/cart/selected-item';
import PathwayCartButton from '../button';
import LoadingComponent from 'src/components/loading-component';

interface Props {
  mutationStatus: MutationStatus;
  isSubmissionModalOpen: boolean;
  selectedPathwayName: string;
  isPathwaySelected: boolean;
  clearSelectedPathway: () => void;
  handleSubmitClick: () => void;
  handleContinueClick: () => void;
}

export default function SelectedPathwayDrawer({
  selectedPathwayName,
  clearSelectedPathway,
  isPathwaySelected,
  ...props
}: Props) {
  const { mutationStatus } = props;

  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = useState<boolean>(false);

  const toggleDrawer = (open: boolean) => {
    setMobileOpen(open);
  };

  const selectedPathwayItem = () => (
    <CartItem
      text={selectedPathwayName}
      disable={mutationStatus === 'success'}
      buttonText={mutationStatus === 'success' ? 'Confirmed' : 'Remove'}
      onClick={() => clearSelectedPathway()}
    />
  );

  if (props.mutationStatus == 'loading') {
    return (
      <>
        <SwipeableDrawer
          onOpen={() => {
            toggleDrawer(true);
          }}
          onClose={() => {
            toggleDrawer(false);
          }}
          anchor="bottom"
          open={mobileOpen}
          swipeAreaWidth={56}
          disableSwipeToOpen={false}
          hysteresis={0.25}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            overflow: 'visible',
          }}
          PaperProps={{
            sx: {
              overflow: 'visible',
              height: 'calc(50% - 56px)',
            },
          }}
        >
          <LoadingComponent label="Confirming Submission" fullPage={false} />
        </SwipeableDrawer>
      </>
    );
  }

  return (
    <>
      <SwipeableDrawer
        onOpen={() => {
          toggleDrawer(true);
        }}
        onClose={() => {
          toggleDrawer(false);
        }}
        anchor="bottom"
        open={mobileOpen}
        swipeAreaWidth={56}
        disableSwipeToOpen={false}
        hysteresis={0.25}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          overflow: 'visible',
        }}
        PaperProps={{
          sx: {
            overflow: 'visible',
            height: 'calc(50% - 56px)',
          },
        }}
      >
        <Box
          bgcolor={theme.palette.background.paper}
          borderRadius="16px 16px 0 0"
          visibility="visible"
          height="56px"
          border="1px solid #eeeeee"
          zIndex={0}
          position="absolute"
          top={-56}
          right={0}
          left={0}
          padding="16px 26px"
          display="flex"
          justifyContent="space-between"
          sx={{
            boxShadow: '0px 0px 16px rgba(0, 0, 0, 0.08)',
          }}
        >
          <Typography>My Pathway</Typography>
        </Box>
        <Box
          bgcolor={theme.palette.background.paper}
          visibility="visible"
          border="1px solid #eeeeee"
          padding="16px 26px"
          height="100%"
          zIndex={1}
        >
          {selectedPathwayItem()}
        </Box>
        <PathwayCartButton {...props} isPathwaySelected={isPathwaySelected} />
      </SwipeableDrawer>
    </>
  );
}
