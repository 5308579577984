import React from 'react';
import { FieldErrors } from 'react-hook-form';
import { NunitoSansErrorMessage } from '../../../styles';
import { Alert } from '@pennfoster/pfc-design-system';
import { cms } from 'src/helpers/language';

interface ErrorMessageProps {
  errors?: FieldErrors | string;
  defaultMessage?: string;
}

const content = cms.content.errors.generic_has_errors_below.details;

export default function ErrorMessage({ errors, defaultMessage = content }: ErrorMessageProps) {
  if ((typeof errors === 'object' && Object.keys(errors).length === 0) || errors === 'undefined') {
    return null;
  }

  const message = typeof errors === 'string' ? errors : defaultMessage;

  return (
    <Alert pfVariant="standard" severity="error">
      <NunitoSansErrorMessage>{message}</NunitoSansErrorMessage>
    </Alert>
  );
}
