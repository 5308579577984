import React, { useContext, useState } from 'react';
import { getInitials } from 'src/helpers';

import CopyIcon from '@mui/icons-material/ContentCopy';
import { Avatar, Box, MenuItem, Snackbar, useTheme } from '@mui/material';
import { Link, Typography } from '@pennfoster/pfc-design-system';
import UserContext from 'src/context/user';

interface Props {
  preferredName?: string;
  firstName: string;
  lastName: string;
}

export function AccountInfoMenuItem({
  preferredName,
  firstName,
  lastName,
}: Props) {
  const theme = useTheme();
  const { user } = useContext(UserContext);
  const [studentNumberCopied, setStudentNumberCopied] = useState(false);

  const handleCopy = () => {
    if(user?.studentNumber){
      navigator.clipboard.writeText(user.studentNumber);
      setStudentNumberCopied(true);
    }
  };
  const handleCloseCopiedSnack = () => {
    setStudentNumberCopied(false);
  };

  return (
    <MenuItem
      sx={{
        ':hover': {
          backgroundColor: 'transparent',
        },
      }}
    >
      <Box display={'flex'} alignItems={'center'}>
        <Avatar
          sx={{
            padding: 1.5,
            backgroundColor: theme.palette.secondary.main,
            margin: 1,
          }}
        >
          <Typography variant="subtitle1">
            {getInitials(preferredName || firstName, lastName)}
          </Typography>
        </Avatar>
        <Box margin={1}>
          <Typography
            variant="body1"
            sx={{
              fontWeight: 'bold',
              whiteSpace: 'normal',
              wordBreak: 'break-word',
            }}
          >
            {`${preferredName || firstName} ${lastName}`}
          </Typography>
          <Typography variant="caption">
            Student Number:
            <Box marginLeft={0.5} component={'span'} onClick={handleCopy}>
              <Link
                content={
                  <>
                    {user?.studentNumber}{' '}
                    <CopyIcon
                      sx={{
                        fontSize: 16,
                        transform: 'translateY(4px)',
                        marginLeft: 0.25,
                      }}
                    />
                  </>
                }
                underline="hover"
              />
            </Box>
          </Typography>
          <Snackbar
            open={studentNumberCopied}
            message={'Student Number Copied!'}
            autoHideDuration={6000}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            onClose={handleCloseCopiedSnack}
          />
        </Box>
      </Box>
    </MenuItem>
  );
}
