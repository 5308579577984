import React from 'react';
import { useMatches } from 'react-router-dom';

import { Box, Breadcrumbs, useTheme } from '@mui/material';

interface Handle {
  crumb: () => JSX.Element;
}

export default function BreadCrumbs() {
  const theme = useTheme();
  const matches = useMatches();
  const crumbs = matches
    .filter((match) => {
      const handle = match.handle as Handle;
      return Boolean(handle?.crumb);
    })
    .map((match) => {
      const handle = match.handle as Handle;
      return handle.crumb();
    });

  if (crumbs.length <= 1) {
    return null;
  }

  return (
    <Box id={'crumbs'} sx={{ marginBottom: theme.spacing(4) }}>
      <Breadcrumbs aria-label="breadcrumb">
        {crumbs.map((crumb, index) => (
          <Box key={index}>{crumb}</Box>
        ))}
      </Breadcrumbs>
    </Box>
  );
}
