import React from 'react';

import { Box, CardActions, CardContent } from '@mui/material';
import { Button, Card, Typography } from '@pennfoster/pfc-design-system';

interface ErrorCardButtonOpts {
  text: string;
  onClick: () => void;
}

interface Props {
  headline: string;
  body: string | React.ReactElement;
  svg: string;
  primaryButtonOpts?: ErrorCardButtonOpts;
  secondaryButtonOpts?: ErrorCardButtonOpts;
}

/**
 * ErrorCard Component
 *
 * A visual card to display errors with optional primary and secondary action buttons.
 *
 * Props:
 * - `headline`: A brief headline for the error.
 * - `body`: Detailed error description.
 * - `svg`: URL for the image to be displayed.
 * - `primaryButtonOpts`: Optional button configuration for a primary-
 * action. Button will render if this object is passed.
 * - `secondaryButtonOpts`: Optional button configuration for a secondary-
 * action. Button will render if this object is passed.
 *
 * Example Usage:
 * ```jsx
 * <ErrorCard
 *    headline="Oops!"
 *    body="Something went wrong."
 *    svg="/path/to/image.svg"
 *    primaryButtonOpts={{ text: 'Retry', onClick: someRetryFunction }}
 * />
 * ```
 */
export default function ErrorModal({
  headline,
  body,
  svg,
  primaryButtonOpts,
  secondaryButtonOpts,
}: Props) {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      width="100vw"
      bgcolor="grey.100"
    >
      <Card
        sx={{
          width: '80%',
          maxWidth: 500,
          padding: 2,
          borderRadius: 2,
          boxShadow: 3,
        }}
      >
        <CardContent>
          <Box display="flex" flexDirection="column">
            <img
              src={svg}
              alt="Error illustration"
              style={{ width: 456, height: 256, alignSelf: 'center' }}
            />

            <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
              {headline}
            </Typography>

            <Typography variant="body1" paragraph>
              {body}
            </Typography>
          </Box>
        </CardContent>
        <CardActions>
          {secondaryButtonOpts && (
            <Button variant="outlined" onClick={secondaryButtonOpts.onClick}>
              {secondaryButtonOpts.text}
            </Button>
          )}
          {primaryButtonOpts && (
            <Button
              variant="contained"
              color="primary"
              onClick={primaryButtonOpts.onClick}
            >
              {primaryButtonOpts.text}
            </Button>
          )}
        </CardActions>
      </Card>
    </Box>
  );
}
