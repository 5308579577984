import { Body1 } from 'src/styles';
import { StyledExpansionSummary } from './styled';
import { Button } from '@pennfoster/pfc-design-system';
import { MutationStatus } from 'react-query';

interface Props {
  text: string;
  isAddable: boolean;
  isSelected: boolean;
  isEnrolled: boolean;
  mutationStatus: MutationStatus;
  selectElective: () => void;
}

const ElectiveRowSummary = ({ text, isAddable, isEnrolled, isSelected, mutationStatus, selectElective }: Props) => {
  const disableAddButton = isSelected || !isAddable || mutationStatus === 'success';

  let selectorText = 'Add';

  if (isSelected) {
    selectorText = 'Added';
  }
  if (!isAddable) {
    if (isEnrolled) {
      selectorText = 'Confirmed';
    }
  }

  const handleClick = () => {
    if (isAddable) selectElective();
  };

  return (
    <StyledExpansionSummary
      details={[
        <Button
          disabled={disableAddButton}
          key={`elective-${text}-summary-link`}
          label={selectorText}
          pfVariant="text"
          onClick={handleClick}
        />,
      ]}
    >
      <Body1>{text}</Body1>
    </StyledExpansionSummary>
  );
};

export default ElectiveRowSummary;
