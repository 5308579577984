import { AxiosError, AxiosResponse } from 'axios';
import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { getUser, switchEnrollment } from 'src/api';
import UserContext from 'src/context/user';
import messages from 'src/events/messages';
import { DASHBOARD } from 'src/routes/routemap';
import QueryKeys from 'src/types/query-keys';
import { User } from 'src/types/user';
import { useAtom } from 'jotai';
import { userAtom } from 'src/state';

interface Props {
  children: React.ReactNode;
}

function UserProvider({ children }: Props) {
  const [user, setUser] = useAtom(userAtom)
  const [submittedPathway, setSubmittedPathway] = useState<boolean>(false);
  const [notificationCount, setNotificationCount] = useState<number>(0);
  const [isSwitchingEnrollments, setIsSwitchingEnrollments] = useState<boolean>(false);

  const { status, data, error, refetch } = useQuery<AxiosResponse<User>, AxiosError>(QueryKeys.GET_USER, getUser);

  const updateUserReady = (user: User) => {
    setUser(user);
    refetch();
    window.location.replace(DASHBOARD);
  };

  useEffect(() => {
    messages.userReady(updateUserReady);
  });

  const switchEnrollmentMutation = useMutation(
    (selectedEnrollmentId: string) => {
      setIsSwitchingEnrollments(true);
      return switchEnrollment({ enrollmentId: selectedEnrollmentId });
    },
    {
      onError: (error) => {
        setIsSwitchingEnrollments(false);
        console.error("Error mutating user's 'selectedEnrollmentId'", error);
      },
      onSuccess: (data, selectedEnrollmentId) => {
        setIsSwitchingEnrollments(false);
        if (user) {
          setUser({ ...user, currentEnrollmentId: selectedEnrollmentId });
        }
      },
    }
  );

  useEffect(() => {
    switch (status) {
      case 'error':
        console.error('Error querying User data', error);
        break;
      case 'success':
        // TODO: Consider a more robust check for data integrity.
        // Mick Piereder 2023.07.26
        if (data?.data) {
          setUser(data.data);
          sessionStorage.setItem('userId', data.data.id.toString());
          messages.initUser(data.data.id.toString());
        } else {
          console.warn(`User query returned incomplete 'data' property.`);
        }
    }
  }, [error, status, data]);

  const updateNotificationCount = (notificationCount: number) => {
    setNotificationCount(notificationCount);
  };

  const changeSelectedEnrollment = (selectedEnrollmentId: string) => {
    if (!user) {
      const e = 'Unable to patch selectedEnrollmentId. User object falsy';
      throw new Error(e);
    }
    switchEnrollmentMutation.mutate(selectedEnrollmentId);
  };

  return (
    <UserContext.Provider
      value={{
        queryStatus: status,
        queryError: error,
        user,
        submittedPathway,
        setSubmittedPathway,
        notificationCount,
        updateNotificationCount,
        changeSelectedEnrollment,
        isSwitchingEnrollments,
        refetch,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

export default UserProvider;
