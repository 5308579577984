import React from 'react';
import { CardContent, Grid, Skeleton, useTheme } from '@mui/material';
import { Card } from '@pennfoster/pfc-design-system';
import { ButtonSkeleton } from '../skeleton-components';

interface CardContainerSkeletonProps {
  showOptions: boolean;
}

export default function CardContainerSkeleton(
  props: CardContainerSkeletonProps
) {
  const theme = useTheme();
  return (
    <>
      <Card
        sx={{ p: theme.spacing(1) }}
        pfVariant="default"
        borderColor={theme.palette.neutral[100]}
      >
        <CardContent sx={{ paddingBottom: '0' }}>
          <Grid container sx={{ mb: 3 }}>
            <Grid item xs={1}>
              <Skeleton variant={'rounded'} width={'70%'} height={32} />
            </Grid>
            <Grid item xs={9}>
              <Skeleton variant={'rounded'} width={'70%'} height={32} />
            </Grid>
            <Grid item xs={2}>
              <Skeleton variant={'rounded'} width={'100%'} height={32} />
            </Grid>
          </Grid>
          <Grid container sx={{ mb: 1 }}>
            <Grid item xs={12}>
              <Skeleton variant={'rounded'} width={'100%'} height={28} />
            </Grid>
          </Grid>
          <Grid container sx={{ mb: 3 }}>
            <Grid item xs={12}>
              <Skeleton variant={'rounded'} width={'100%'} height={28} />
            </Grid>
          </Grid>
          <Grid container>
            {props.showOptions ? (
              <>
                <Grid item xs={3} />
                <Grid item xs={3} sx={{ pr: 1 }}>
                  <ButtonSkeleton width={'100%'} />
                </Grid>
                <Grid item xs={3} sx={{ pr: 1 }}>
                  <ButtonSkeleton width={'100%'} />
                </Grid>
              </>
            ) : (
              <Grid item xs={9} />
            )}
            <Grid item xs={3}>
              <ButtonSkeleton width={'100%'} />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}
