import { Box, Button, styled, useTheme } from '@mui/material';
import { Body1 } from 'src/styles';

const ParentBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  ml: -2,
  mr: -2,
  pl: 2,
  pr: 2,
}));

const ChildBox = styled(Box)(({ theme: { spacing, palette } }) => ({
  paddingTop: spacing(0.5),
  paddingBottom: spacing(0.5),
  marginLeft: 4,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  borderBottom: `1px solid ${palette.neutral[80]}`,
}));

interface Props {
  text: string;
  buttonText?: string;
  disable: boolean;
  onClick: () => void;
}

export default function CartItem({
  text,
  buttonText = 'Remove',
  disable,
  onClick,
}: Props) {
  const { palette } = useTheme();

  return (
    <ParentBox>
      <ChildBox>
        <Body1>{text}</Body1>
        <Button
          onClick={() => !disable && onClick()}
          sx={{
            textTransform: 'unset',
            color: disable ? palette.neutral[80] : palette.primary.main,
          }}
        >
          {buttonText}
        </Button>
      </ChildBox>
    </ParentBox>
  );
}
