import { Grid, Box } from '@mui/material';
import AppLayout from 'src/components/layout';
import {
  TasksCardSkeleton,
  UpcomingCoursesCardSkeleton,
} from 'src/components/skeleton-components';

export function ElectivesLoading() {
  return (
    <AppLayout showHelpFooter={false}>
      <Grid container columnSpacing={3}>
        <Grid container item xs={12}>
          <Grid item xs={12} md={7} display={'flex'} flexWrap={'wrap'}>
            <Box
              borderRadius={0.5}
              height={32}
              width={'100%'}
              bgcolor={'#f4f4f5' /* replace with token */}
              mb={2}
            />
            <Box
              borderRadius={0.5}
              height={16}
              width={'100%'}
              bgcolor={'#f4f4f5' /* replace with token */}
              mb={1.25}
            />
            <Box
              borderRadius={0.5}
              height={16}
              width={'66.66%'}
              bgcolor={'#f4f4f5' /* replace with token */}
              mb={2}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} md={7}>
          <Grid item xs={12}>
            <UpcomingCoursesCardSkeleton
              coursesCount={10}
              divider={true}
              status={false}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} md={5}>
          <Grid item xs={12}>
            <TasksCardSkeleton status={false} divider={false} actions={true} />
          </Grid>
        </Grid>
      </Grid>
    </AppLayout>
  );
}
