import React, { useState } from 'react';
import { Drawer } from '@pennfoster/pfc-design-system';
import { Box, Divider, MenuItem, MenuList, Typography } from '@mui/material';
import { AccountInfoMenuItem } from './account-info-menu-item';
import { useNavigate } from 'react-router-dom';

import LogOutIcon from '@mui/icons-material/PowerSettingsNew';
import ProfileIcon from '@mui/icons-material/PersonOutline';
import MobileMenuIcon from '@mui/icons-material/MoreVert';
import ResourceDrawer from '../resource-drawer';
import NotificationsDrawer from '../notifications-drawer';

import { AUTH_LOGOUT, PROFILE } from 'src/routes/routemap';
import { MyProgramsMenuItem } from './my-programs-menu-item';

interface Props {
  preferredName?: string;
  firstName: string;
  lastName: string;
}

export function AccountSettingsDrawer(props: Props) {
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const navigateLogout = () => {
    toggleMenu();
    navigate(AUTH_LOGOUT);
  };

  const navigateMyProfile = () => {
    toggleMenu();
    navigate(PROFILE);
  };

  return (
    <>
      <MobileMenuIcon onClick={toggleMenu} />
      <Drawer anchor="right" toggleDrawer={toggleMenu} open={menuOpen}>
        <MenuList>
          <AccountInfoMenuItem {...props} />
          <MyProgramsMenuItem />
          <Box padding={2}>
            <Divider />
          </Box>
          <MenuItem onClick={navigateMyProfile}>
            <ProfileIcon sx={{ fontSize: 24, mr: 2 }} />
            <Typography variant="body1">My Profile</Typography>
          </MenuItem>
          <NotificationsDrawer mobile />
          <ResourceDrawer mobile />
          <Box padding={2}>
            <Divider />
          </Box>
          <MenuItem onClick={navigateLogout}>
            <LogOutIcon sx={{ fontSize: 24, mr: 2 }} />
            <Typography variant="body1">Log Out</Typography>
          </MenuItem>
        </MenuList>
      </Drawer>
    </>
  );
}
