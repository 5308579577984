import React from 'react';
import AppLayout from 'src/components/layout';
import { HeadingH6 } from 'src/styles';

import { Box, useMediaQuery, useTheme } from '@mui/material';
import EditProfileForm from 'src/components/forms/edit-profile-form';

export default function ProfilePage() {
  const theme = useTheme();
  const onlySmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <AppLayout>
      <Box sx={{ maxWidth: '743px', margin: '0 auto' }}>
        <HeadingH6 sx={{ mb: onlySmallScreen ? 2 : 4 }} variant="h1">
          My Profile
        </HeadingH6>
        <EditProfileForm />
      </Box>
    </AppLayout>
  );
}
