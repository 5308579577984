import React from 'react';
import { useNavigate } from 'react-router-dom';
import svg from 'src/assets/svg/error-models/something-went-wrong.svg';

import ErrorModal from './error-modal';
import { DASHBOARD } from 'src/routes/routemap';

export default function PageNotFoundModal() {
  const navigate = useNavigate();

  const headline = `Oh No! Something Went Wrong.`;

  const body = `Well, this is unexpected. The page you're seeking has
  momentarily vanished. For immediate assistance, please submit a support
  ticket in the help center.`;

  const primaryButtonOpts = {
    text: 'Go Back Home',
    onClick: () => navigate(DASHBOARD),
  };

  return (
    <ErrorModal
      headline={headline}
      body={body}
      svg={svg}
      primaryButtonOpts={primaryButtonOpts}
    />
  );
}
