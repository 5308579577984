import React, { ReactElement, useEffect } from 'react';
import { useLocation, Outlet } from 'react-router-dom';
import { useCacheBuster } from 'react-cache-buster';

function CachedVersionCheck(): ReactElement {
    const { checkCacheStatus } = useCacheBuster();
    const pagelocation = useLocation();
    useEffect(() => {
        checkCacheStatus();
      }, [pagelocation.pathname])

  return <Outlet />;
}

export default CachedVersionCheck;
