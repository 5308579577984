import axios, { AxiosResponse } from 'axios';

import { instance } from './apiConfig';

export function getSasToken(blobUrl: string): Promise<AxiosResponse> {
  return instance({
    method: 'GET',
    url: `/sastoken?blobUrl=${blobUrl}`,
  });
}

export function getHtmlBlob(token: string): Promise<AxiosResponse> {
  return axios.get(token);
}
