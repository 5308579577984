import React, { useContext } from 'react';
import { Subtitle1 } from 'src/styles';

import { CardActions, CardContent, CardHeader, Divider } from '@mui/material';
import { Card } from '@pennfoster/pfc-design-system';
import AccountStatusesChip from 'src/components/account-status-chip';
import EnrollmentContext from 'src/context/enrollment';
import { NextPaymentData } from 'src/types/payments';
import { getUxStatus, getPaymentDescription, getPaymentEnrollment } from 'src/helpers/paymentHelper';
import ActionButtons from './action-buttons';
import MyCardContent from './my-card-content';
import { useFetchScheduledPayments } from 'src/hooks/fetch-payments';

interface MyPaymentsCardProps {
  nextPaymentData: NextPaymentData;
}

function MyPaymentsCard({ nextPaymentData }: MyPaymentsCardProps) {
  const { enrollment, userEnrollment } = useContext(EnrollmentContext);
  const { data: scheduledPaymentsData } = useFetchScheduledPayments();
  const paymentEnrollment = getPaymentEnrollment(scheduledPaymentsData, userEnrollment?.enrollments, enrollment);
  const accountStatus = paymentEnrollment?.accountStatus;
  const status: string = (accountStatus && getUxStatus(accountStatus, paymentEnrollment.learningEnabled)) ?? '';
  const nextPaymentAmount = nextPaymentData && nextPaymentData?.amountDue;
  let description = '';
  if (nextPaymentData && nextPaymentData.amountDue !== undefined && nextPaymentData.dueDate !== undefined) {
    description = getPaymentDescription(status, nextPaymentData, paymentEnrollment);
  }

  return (
    <Card pfVariant="default" borderColor="common.white">
      <CardHeader
        sx={{ px: 3, pt: 2.5, pb: 2 }}
        title={<Subtitle1 variant="h2">My Payments</Subtitle1>}
        action={<AccountStatusesChip params={status} />}
      />
      <Divider
        aria-hidden="true"
        sx={{
          ml: '24px',
          mr: '24px',
        }}
      />
      <CardContent>
        <MyCardContent description={description ?? ''} nextPaymentAmount={nextPaymentAmount ?? 0} status={status} />
      </CardContent>
      <CardActions
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        {accountStatus && <ActionButtons accountStatus={accountStatus} />}
      </CardActions>
    </Card>
  );
}

export default MyPaymentsCard;
