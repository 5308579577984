import React from 'react';
import { UseFormRegisterReturn, UseFormGetValues } from 'react-hook-form';
import { IFormInput } from '.';
import { TextField } from '@pennfoster/pfc-design-system';
import { InputAdornment, useTheme } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

interface InputTextProps {
  className?: string;
  name: string;
  label: string;
  register: UseFormRegisterReturn;
  getValues: UseFormGetValues<IFormInput>;
  readOnly: boolean;
  maxLength?: number;
  error?: boolean;
  helperText?: React.ReactNode;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  placeholder?: string;
  isValid?: boolean;
  isFocus?: boolean;
  isError?: boolean;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
}

export const FormInputText = ({
  className,
  name,
  register,
  label,
  getValues,
  readOnly,
  maxLength,
  error,
  helperText,
  onChange,
  placeholder,
  isValid,
  isFocus,
  isError,
  onFocus,
  onBlur,
}: InputTextProps) => {
  const theme = useTheme();
  return (
    <TextField
      className={className}
      inputRef={register.ref}
      InputLabelProps={{ shrink: !!getValues }}
      focused={isFocus}
      color={isValid && isFocus ? 'success' : undefined}
      inputProps={{
        maxLength: maxLength || 999,
        onChange: register.onChange,
        onBlur: register.onBlur,
        name: register.name,
        readOnly: readOnly,
      }}
      InputProps={{
        endAdornment: isError ? (
          <InputAdornment position="end">
            <ErrorOutlineIcon
              sx={{
                color: theme.palette.error.main,
              }}
            />
          </InputAdornment>
        ) : isValid && isFocus ? (
          <InputAdornment position="end">
            <DoneIcon
              sx={{
                color: theme.palette.success[30],
              }}
            />
          </InputAdornment>
        ) : undefined,
      }}
      fullWidth
      id={name}
      label={label}
      readOnly={readOnly}
      error={error}
      helperText={helperText}
      placeholder={placeholder}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
    />
  );
};
