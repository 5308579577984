import { useContext, useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { getResourceLinks } from 'src/api/campus';
import { getPersonalizedDocument, checkForPersonalizedDocument } from 'src/api/enrollments';
import EnrollmentContext from 'src/context/enrollment';
import { Body1, HeadingH6 } from 'src/styles';
import QueryKeys from 'src/types/query-keys';
import InformationIcon from '@mui/icons-material/QuestionMark';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import FileDownload from '@mui/icons-material/FileDownload';
import { Launch } from '@mui/icons-material';
import { useDocumentsQuery } from 'src/hooks/documents';
import { Box, List, ListItem, MenuItem, useTheme, alpha, Typography, Button, CircularProgress } from '@mui/material';
import { Drawer, IconButton } from '@pennfoster/pfc-design-system';
import { styled } from '@mui/system';
import { SUPPORT_CASE, HELP_CENTER } from 'src/constants/support-and-resources';
import { applicationVersion } from 'src/api/apiConfig';
import { AUTH_HELP } from 'src/routes/routemap';

interface Props {
  mobile?: boolean;
}

export default function ResourceDrawer({ mobile }: Props) {
  const theme = useTheme();
  const [downloading, setDownloading] = useState(false);
  const [open, setOpen] = useState(false);
  const [showDocuments, setShowDocuments] = useState(false);
  const enrollmentContext = useContext(EnrollmentContext);
  const campusId = enrollmentContext?.enrollment?.campusId || '';
  const [personalizedDocumentExists, setPersonalizedDocumentExists] = useState(false);

  const documentsQuery = useDocumentsQuery();

  const resourceQuery = useQuery([QueryKeys.GET_RESOURCE_LINKS], () => getResourceLinks(campusId), {
    onError: (error) => console.error(error),
    select: (data) => {
      if (Array.isArray(data?.data)) {
        return {
          data: data.data.filter((link) => link.resourceLinkType.name !== SUPPORT_CASE),
        };
      }
      return data;
    },
  });

  useEffect(() => {
    checkForPersonalizedDocument()
      .then((result) => {
        setPersonalizedDocumentExists(result);
      })
      .catch((error) => {
        console.error('Error checking for personalized document:', error);
      });
  }, []);

  const handleDownloadPersonalizedDocument = async () => {
    setDownloading(true);
    try {
      const response = await getPersonalizedDocument();
      const blob = new Blob([response.data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      const documentName = response.headers['x-document-name'];
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', documentName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading personalized document:', error);
    } finally {
      setDownloading(false);
    }
    setOpen(false);
  };

  const ResourceLink = styled('div')(({ theme }) => ({
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: alpha(theme.palette.secondary.light, 0.2),
    },
    '&:visited': {
      color: 'inherit',
    },
  }));

  const DocumentsList = (
    <>
      {documentsQuery && documentsQuery.data && Array.isArray(documentsQuery.data.data) ? (
        documentsQuery.data.data
          .filter((doc) => doc && doc.link && doc.link.trim() !== '')
          .map((doc, index) => (
            <ResourceLink key={index}>
              <Box sx={{ marginLeft: '20px' }}>
                <a
                  href={doc.link || '#'}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: 'none', color: 'inherit' }}
                >
                  <ListItem
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Body1>{doc.name}</Body1>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={doc.link || '#'}
                      style={{
                        textDecoration: 'none',
                        color: 'inherit',
                      }}
                    >
                      <IconButton icon={<Launch />} />
                    </a>
                  </ListItem>
                </a>
              </Box>
            </ResourceLink>
          ))
      ) : (
        <div>
          <p>No documents found</p>
        </div>
      )}
      {personalizedDocumentExists && (
        <ResourceLink onClick={handleDownloadPersonalizedDocument}>
          <Box sx={{ marginLeft: '20px' }}>
            <ListItem
              sx={{
                display: 'flex',
                cursor: 'pointer',
                justifyContent: 'space-between',
              }}
            >
              <Body1 aria-hidden="true">Enrollment Letter</Body1>
              <IconButton
                aria-label={`Download personalized document`}
                icon={downloading ? <CircularProgress size={25} /> : <FileDownload />}
              />
            </ListItem>
          </Box>
        </ResourceLink>
      )}
    </>
  );

  function toggleDocuments() {
    setShowDocuments(!showDocuments);
  }

  function toggleDrawer() {
    setOpen(!open);
  }

  return (
    <>
      {!mobile ? (
        <Box>
          <IconButton
            aria-label="Resources"
            pfVariant="standard"
            icon={
              <InformationIcon
                sx={{
                  backgroundColor: theme.palette.text.light,
                  borderRadius: '50%',
                  color: theme.palette.neutral[60],
                  fontSize: '32px',
                  height: '40px',
                  padding: '6px',
                  width: '40px',
                }}
              />
            }
            onClick={toggleDrawer}
          />
        </Box>
      ) : (
        <MenuItem onClick={toggleDrawer}>
          <InformationIcon sx={{ fontSize: 24, mr: 2 }} />
          <Body1 variant="h2">Support and Resources</Body1>
        </MenuItem>
      )}
      <Drawer
        toggleDrawer={toggleDrawer}
        open={open}
        anchor="right"
        Header={<HeadingH6 variant="h2">Support & Resources</HeadingH6>}
      >
        <hr aria-hidden="true" />
        <List>
          {Array.isArray(resourceQuery.data?.data) &&
            resourceQuery.data?.data &&
            resourceQuery.data?.data.map((link) => (
              <a
                key={link.id}
                href={link.resourceLinkType.name == HELP_CENTER? AUTH_HELP : link.link}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  textDecoration: 'none',
                  color: 'inherit',
                  outline: 'none',
                }}
              >
                <ResourceLink>
                  <ListItem
                    sx={{
                      display: 'flex',
                      cursor: 'pointer',
                      justifyContent: 'space-between',
                    }}
                    key={link.id}
                  >
                    <Body1 aria-hidden="true">{link.resourceLinkType.name}</Body1>
                    <IconButton
                      aria-label={`${link.resourceLinkType.name} (link opens in new tab)`}
                      icon={<Launch />}
                    />
                  </ListItem>
                </ResourceLink>
              </a>
            ))}
          <a
            target="_blank"
            rel="noopener noreferrer"
            style={{
              textDecoration: 'none',
              color: 'inherit',
              outline: 'none',
            }}
          >
            <ResourceLink>
              <ListItem
                sx={{
                  display: 'flex',
                  cursor: 'pointer',
                  justifyContent: 'space-between',
                }}
              >
                <Body1 aria-hidden="true">Getting Started Guides</Body1>
                <IconButton aria-label={`Getting Started Guides (link opens in new tab)`} icon={<Launch />} />
              </ListItem>
            </ResourceLink>
          </a>
          <ResourceLink>
            <ListItem
              sx={{
                display: 'flex',
                cursor: 'pointer',
                justifyContent: 'space-between',
              }}
              onClick={toggleDocuments}
            >
              <Body1>Documents</Body1>
              {documentsQuery.data && documentsQuery.data.data && (
                <IconButton
                  aria-label="toggle documents"
                  icon={showDocuments ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                />
              )}
            </ListItem>
          </ResourceLink>
          {showDocuments ? DocumentsList : null}
        </List>
        <Box
          sx={{
            position: 'absolute',
            bottom: -30,
            left: 0,
            width: '100%',
            textAlign: 'center',
            pb: 1.5,
          }}
        >
          <Typography variant="caption">{`Version: ${applicationVersion}`}</Typography>
        </Box>
      </Drawer>
    </>
  );
}
