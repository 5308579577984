import CryptoJS from 'crypto-js';

class PKCE {
  constructor() {
    this.count++;
    if (this.count < 2) {
      this.init();
    }
  }

  challenge = '';
  verifier = '';
  count = 0;

  createVerifier() {
    const verifier = CryptoJS.lib.WordArray.random(43).toString(
      CryptoJS.enc.Base64url
    );
    this.verifier = verifier;
  }

  createChallengeCode() {
    const challenge = CryptoJS.SHA256(this.verifier).toString(
      CryptoJS.enc.Base64url
    );
    this.challenge = challenge;
  }

  init() {
    this.createVerifier();
    this.createChallengeCode();
  }
}

const instancePKCE = new PKCE();
export default instancePKCE;
