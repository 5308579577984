import { useState, useEffect } from 'react';
import FeEvents from 'src/events/fe-events';
import { Button } from '@pennfoster/pfc-design-system';
import { getLexisNexisURL } from 'src/api/enrollments';
import { ReactComponent as ChooseElectivesSvg } from '../../assets/svg/choose-electives.svg';

const lexisNexisNotificationProps = () => {
  
  const [lexisNexisUrl, setLexisNexisUrl] = useState('');

  useEffect(() => {
    const fetchLexisNexisUrl = async () => {
      try {
        const response = await getLexisNexisURL();
        setLexisNexisUrl(response.data);
      } catch (error) {
        console.error('Error fetching LexisNexis URL:', error);
      }
    };

    fetchLexisNexisUrl(); 
  }, []);

  return {
    media: <ChooseElectivesSvg />,
    title: 'Complete Your Project',
    content: (
        <span>
          Go to LexisNexis to complete your course project. 
        </span>
      ),
    buttons: [
      <Button
        key="Go To LexisNexis"
        sx={{ m: 1 }}
        size="small"
        variant="outlined"
        href={lexisNexisUrl}
        target="_blank"
        rel="noopener noreferrer"
        onClick={() =>
          FeEvents.trackNamedEvent({
            eventName: 'GoToLexisNexisClick',
          })
        }
      >
        Go To LexisNexis
      </Button>,
    ],
  };
};

export { lexisNexisNotificationProps };
