import instance from './apiConfig';
import { AxiosResponse } from 'axios';

export function postImpersonate(
  studentNumber: string,
  reason: string
): Promise<AxiosResponse> {
  return instance({
    method: 'POST',
    url: '/impersonate',
    data: { studentNumber, reason },
  });
}

export function deleteImpersonateSession(): Promise<AxiosResponse> {
  return instance({
    method: 'DELETE',
    url: '/impersonate/release',
  });
}
