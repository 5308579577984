import { CardActions } from '@mui/material';
import { ReactNode } from 'react';

interface Props {
  children?: ReactNode;
}

export function CardActionsSkeleton({ children }: Props) {
  return <CardActions sx={{ padding: 0 }}>{children}</CardActions>;
}
