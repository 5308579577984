import { Body2, Subtitle1 } from 'src/styles';

import { Box } from '@mui/material';

interface Props {
  name: string;
  nNeededInPool: number;
}

const ElectivePoolHeader = ({ name, nNeededInPool }: Props) => {
  let subheaderText = `Choose ${nNeededInPool} Course`;
  if (nNeededInPool > 1) {
    subheaderText += 's';
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        mb: 4,
        pt: 3,
        pl: 3,
      }}
    >
      <Subtitle1>{name}</Subtitle1>
      {nNeededInPool > 0 && <Body2>{subheaderText}</Body2>}
    </Box>
  );
};

export default ElectivePoolHeader;
