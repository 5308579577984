import React from 'react';
import { useNavigate } from 'react-router-dom';
import svg from 'src/assets/svg/error-models/loading-state-failed.svg';

import ErrorModal from './error-modal';
import ROUTES from 'src/routes/routemap';

export default function LoadingStateFailedModal() {
  const navigate = useNavigate();

  const headline = "Oops, Let's Give it Another Shot!";

  const body = `We apologize for the hiccup you've encountered. It seems we hit
   a tiny speed bump on your journey. `;

  const primaryButtonOpts = {
    text: 'Back to Home',
    onClick: () => navigate(ROUTES.DASHBOARD),
  };

  const secondaryButtonOpts = {
    text: 'Reload Page',
    onClick: () => window.location.reload(),
  };

  return (
    <ErrorModal
      headline={headline}
      body={body}
      svg={svg}
      primaryButtonOpts={primaryButtonOpts}
      secondaryButtonOpts={secondaryButtonOpts}
    />
  );
}
