import { useState } from 'react';
import { MutationStatus } from 'react-query';
import CartItem from 'src/components/common/cart/selected-item';
import { SISCourseId } from 'src/types/course';
import { SelectedElective } from 'src/types/electives';

import { Box, SwipeableDrawer, Typography, useTheme } from '@mui/material';
import ElectiveCartButton from '../button';
import LoadingComponent from 'src/components/loading-component';

interface Props {
  nNeededElectives: number;
  mutationStatus: MutationStatus;
  selectedCourses: SelectedElective[];
  handleRemoveElective: (sisCourseId: SISCourseId) => void;
  handleSubmitClick: () => void;
  handleContinueClick: () => void;
}

export default function ElectivesCartDrawer({
  nNeededElectives,
  selectedCourses,
  handleRemoveElective,
  ...props
}: Props) {
  const { mutationStatus } = props;

  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = useState<boolean>(false);

  const toggleDrawer = (open: boolean) => {
    setMobileOpen(open);
  };

  const selectedElectivesItems = selectedCourses.map((item) => (
    <CartItem
      key={`selected-elective-component-${item.sisCourseId}`}
      text={item.courseName}
      disable={mutationStatus === 'success'}
      buttonText={mutationStatus === 'success' ? 'Confirmed' : 'Remove'}
      onClick={() => handleRemoveElective(item.sisCourseId)}
    />
  ));

  if (props.mutationStatus == 'loading') {
    return (
      <>
        <SwipeableDrawer
          onOpen={() => {
            toggleDrawer(true);
          }}
          onClose={() => {
            toggleDrawer(false);
          }}
          anchor="bottom"
          open={mobileOpen}
          swipeAreaWidth={56}
          disableSwipeToOpen={false}
          hysteresis={0.25}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            overflow: 'visible',
          }}
          PaperProps={{
            sx: {
              overflow: 'visible',
              height: 'calc(50% - 56px)',
            },
          }}
        >
          <LoadingComponent label="Confirming Submission" fullPage={false} />
        </SwipeableDrawer>
      </>
    );
  }

  return (
    <>
      <SwipeableDrawer
        onOpen={() => {
          toggleDrawer(true);
        }}
        onClose={() => {
          toggleDrawer(false);
        }}
        anchor="bottom"
        open={mobileOpen}
        swipeAreaWidth={56}
        disableSwipeToOpen={false}
        hysteresis={0.25}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          overflow: 'visible',
        }}
        PaperProps={{
          sx: {
            overflow: 'visible',
            height: 'calc(50% - 56px)',
          },
        }}
      >
        <Box
          bgcolor={theme.palette.background.paper}
          borderRadius="16px 16px 0 0"
          visibility="visible"
          height="56px"
          border="1px solid #eeeeee"
          zIndex={0}
          position="absolute"
          top={-56}
          right={0}
          left={0}
          padding="16px 26px"
          display="flex"
          justifyContent="space-between"
          sx={{
            boxShadow: '0px 0px 16px rgba(0, 0, 0, 0.08)',
          }}
        >
          <Typography>My Electives</Typography>
          {nNeededElectives > 0 && (
            <>
              <Typography display="inline">
                {selectedCourses.length}/{nNeededElectives}
              </Typography>
            </>
          )}
        </Box>
        <Box
          bgcolor={theme.palette.background.paper}
          visibility="visible"
          border="1px solid #eeeeee"
          padding="16px 26px"
          height="100%"
          zIndex={1}
        >
          {selectedElectivesItems}
        </Box>
        <ElectiveCartButton
          {...props}
          isElectiveSelected={selectedCourses.length > 0}
        />
      </SwipeableDrawer>
    </>
  );
}
