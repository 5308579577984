import { Grid } from '@mui/material';
import {
  CardContentSkeleton,
  CardHeaderSkeleton,
  CardSkeleton,
  ListItemSkeleton,
} from '.';

interface Props {
  electivesCount?: number;
}

export function MyElectivesCardSkeleton({ electivesCount = 2 }: Props) {
  return (
    <CardSkeleton size={'small'}>
      <CardHeaderSkeleton status={false} />
      <CardContentSkeleton>
        <Grid container>
          {[...Array(electivesCount)].map((e, i) => (
            <ListItemSkeleton key={i} />
          ))}
        </Grid>
      </CardContentSkeleton>
    </CardSkeleton>
  );
}
