import { createContext } from 'react';
import { useChatbot } from 'src/hooks/chatbot';

export interface ChatbotState {
  chatbot?: ReturnType<typeof useChatbot>;
}

const ChatbotContext = createContext<ChatbotState>({
  chatbot: undefined,
});

export default ChatbotContext;
