import { MutationStatus } from 'react-query';
import CartItem from 'src/components/common/cart/selected-item';

interface Props {
  mutationStatus: MutationStatus;
  isPathwaySelected: boolean;
  selectedPathwayName: string;
  clearSelectedPathway: () => void;
}

const PathwayCartItem = ({
  mutationStatus,
  isPathwaySelected,
  selectedPathwayName,
  clearSelectedPathway,
}: Props) => {
  if (!isPathwaySelected) return null;

  let buttonText = 'Remove';
  let disable = false;

  if (mutationStatus === 'success') {
    buttonText = 'Confirmed';
    disable = true;
  }

  return (
    <CartItem
      text={selectedPathwayName}
      buttonText={buttonText}
      disable={disable}
      onClick={clearSelectedPathway}
    />
  );
};

export default PathwayCartItem;
