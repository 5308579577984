import { AxiosResponse } from 'axios';
import { Enrollment, ProgramDTO, SwitchEnrollmentParams, UserEnrollment } from 'src/types/enrollment';
import { CalculateSalesTaxRequest, PaymentTaxesResponse } from 'src/types/payments';
import { StudentDocument } from 'src/types/document';
import { PostElectivesDto } from '../types/api';
import { instance } from './apiConfig';
import { ElectivePool } from 'src/types/electives';

export function getEnrollments(): Promise<AxiosResponse<UserEnrollment>> {
  return instance({
    method: 'GET',
    url: `/user/withenrollments`,
  });
}

export function getEnrollment(enrollmentId: string): Promise<AxiosResponse<Enrollment>> {
  return instance({
    method: 'GET',
    url: `/user/enrollments/${enrollmentId}`,
  });
}

export function getElectivePools(enrollmentId: string, termId: number): Promise<AxiosResponse<ElectivePool[]>> {
  const url = `/user/enrollments/${enrollmentId}/electivepools?termId=${termId}`;

  return instance({ method: 'GET', url });
}

export function postElectives(enrollmentId: string, data: PostElectivesDto) {
  return instance({
    method: 'POST',
    url: `user/enrollments/${enrollmentId}/electivepools/`,
    data,
  });
}

export function getOutstandingBalance(): Promise<AxiosResponse<ProgramDTO[]>> {
  return instance({
    method: 'GET',
    url: `/user/enrollments/outstanding-balance`,
  });
}

export function switchEnrollment(data: SwitchEnrollmentParams): Promise<AxiosResponse> {
  return instance({
    method: 'PATCH',
    url: `/user/`,
    data: data,
  });
}

export function getEolUrl(enrollmentId: string | undefined): Promise<AxiosResponse<string>> {
  if (!enrollmentId) {
    return Promise.reject(new Error('Invalid enrollmentId'));
  }
  return instance({
    method: 'GET',
    url: `/user/enrollments/${enrollmentId}/eolurl`,
  });
}

export function getLegacyPortalUrl(enrollmentId: string | undefined): Promise<AxiosResponse<string>> {
  if (!enrollmentId) {
    return Promise.reject(new Error('Invalid enrollmentId'));
  }
  return instance({
    method: 'GET',
    url: `/user/enrollments/${enrollmentId}/legacyportalurl`,
  });
}

export function getPaymentTaxes(data: CalculateSalesTaxRequest, enrollmentId: string): Promise<PaymentTaxesResponse> {
  return instance({
    method: 'POST',
    url: `/user/enrollments/${enrollmentId}/paymenttax`,
    data: data,
  });
}

export function getUserEnrollmentDocuments(enrollmentId: string): Promise<AxiosResponse<StudentDocument[]>> {
  return instance({
    method: 'GET',
    url: `/user/enrollments/${enrollmentId}/documents`,
  });
}

export function getLexisNexisURL(): Promise<AxiosResponse<string>> {
  return instance({
    method: 'GET',
    url: `/user/enrollments/lexisnexis`,
  });
}

export function getPersonalizedDocument(): Promise<AxiosResponse<ArrayBuffer>> {
  return instance({
    method: 'GET',
    url: `/user/enrollments/personalizeddocument`,
    responseType: 'arraybuffer',
  });
}

export function checkForPersonalizedDocument(): Promise<boolean> {
  return instance({
    method: 'GET',
    url: `/user/enrollments/personalizeddocumentexists`,
  })
    .then((response: AxiosResponse<boolean>) => {
      return response.data;
    })
    .catch((error) => {
      console.error('Error checking for personalized document:', error);
      return false; // Return false in case of an error
    });
}

