import React from 'react';
import { Subtitle1 } from 'src/styles';
import {
  Alert,
  Box,
  CardContent,
  Stack,
  styled,
  useTheme,
} from '@mui/material';
import { GridColDef, GridValidRowModel } from '@mui/x-data-grid';
import { Card, PFDatagrid, Typography } from '@pennfoster/pfc-design-system';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import { noop } from 'src/helpers/noop';

interface StatusTableProps {
  title?: string;
  rows: GridValidRowModel[];
  header: GridColDef[];
  alertMessage?: string | null;
  noRowsMessage?: string | null;
}

export const DataGridStyled = styled(PFDatagrid)(({ theme }) => ({
  '& .MuiDataGrid-withBorderColor.MuiDataGrid-columnHeaders': {
    borderRadius: 0,
    backgroundColor: theme.palette.background.light,
  },
  '& .MuiDataGrid-columnHeaderTitle': {
    ...theme.typography.labelMedium,
  },
  '& .MuiDataGrid-columnSeparator': {
    display: 'none',
  },
  '& .MuiDataGrid-row': {
    ...theme.typography.body1,
  },
  '& .MuiDataGrid-withBorderColor': {
    borderColor: 'transparent',
  },
  '& .MuiDataGrid-cell': {
    padding: theme.spacing(2),
  },
}));

const additionalDataGridProps = {
  sx: {
    width: '800px',
    '@media (min-width: 801px)': {
      width: '100%',
      maxWidth: 'none',
      border: 'none',
    },
  },
  sortingMode: 'server',
  disableRowSelectionOnClick: true,
  disableColumnFilter: true,
  disableColumnMenu: true,
  disableColumnSelector: true,
  disableDensitySelector: true,
  hideFooter: true,
  hideFooterPagination: true,
  hideFooterSelectedRowCount: true,
  overflowX: 'auto',
  autoHeight: true,
};

export default function StatusTable({
  rows,
  header,
  title,
  alertMessage,
  noRowsMessage,
}: StatusTableProps) {
  const additionalProps = {
    ...additionalDataGridProps,
    slots: {
      noRowsOverlay: () => (
        <Stack height="100%" alignItems="center" justifyContent="center">
          {noRowsMessage ?? 'No rows available'}
        </Stack>
      ),
    },
  };

  const theme = useTheme();
  return (
    <Card pfVariant="default">
      <CardContent style={{ overflowX: 'auto' }}>
        {title && (
          <Box
            sx={{
              pt: 1,
              pb: 2,
            }}
          >
            <Subtitle1>{title}</Subtitle1>
          </Box>
        )}
        <DataGridStyled
          rows={rows}
          header={header}
          handleSortModelChange={noop}
          {...additionalProps}
        />
        {alertMessage && (
          <Box sx={{ alignItems: 'center', display: 'flex' }}>
            <Alert
              severity="info"
              icon={
                <InfoOutlinedIcon sx={{ color: theme.palette.primary.dark }} />
              }
              sx={{
                backgroundColor: theme.palette.secondary.container,
                borderRadius: '8px',
                border: `1px solid ${theme.palette.secondary.light}`,
                marginTop: '24px',
                width: '100%',
              }}
            >
              <Typography variant="body2" sx={{ textAlign: 'left' }}>
                {alertMessage}
              </Typography>
            </Alert>
          </Box>
        )}
      </CardContent>
    </Card>
  );
}
