import { useContext } from 'react';
import { useQuery } from 'react-query';
import { getUserEnrollmentDocuments } from 'src/api/enrollments';
import EnrollmentContext from 'src/context/enrollment';
import QueryKeys from 'src/types/query-keys';

export function useDocumentsQuery() {
  const { enrollment } = useContext(EnrollmentContext);

  const queryFn = () => {
    if (!enrollment?.enrollmentId) {
      const e = 'Unable to query documents. Enrollment ID unavailable.';
      throw new Error(e);
    }

    return getUserEnrollmentDocuments(enrollment.enrollmentId);
  };

  return useQuery(
    [QueryKeys.GET_USER_ENROLLMENT_DOCUMENTS, enrollment?.enrollmentId],
    queryFn,
    { enabled: !!enrollment?.enrollmentId }
  );
}
