import { useTheme } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import NotInterestedIcon from '@mui/icons-material/NotInterested';

export const LessonStatusIcon = ({ status }: { status: string }) => {
  const theme = useTheme();

  if (status === 'complete') {
    return (
      <CheckCircleOutlineIcon
        sx={{
          color: theme.palette.success.dark,
        }}
      />
    );
  }
  if (status === 'failed') {
    return (
      <NotInterestedIcon
        sx={{
          color: theme.palette.error.dark,
        }}
      />
    );
  }
  return (
    <RadioButtonUncheckedIcon
      sx={{
        color: '#606771', // TODO: replace with theme.palette.background.medium (currently does not exist)
      }}
    />
  );
};
