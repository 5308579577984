import { useQuery } from 'react-query';
import {
  getEnrollmentScheduledPayments,
  getNextPaymentData,
  getPaymentPlans,
  getScheduledPayments,
} from 'src/api';
import { PaymentPlans, ScheduledPayment } from 'src/types/payments';
import QueryKeys from 'src/types/query-keys';

export const useFetchPaymentPlans = () => {
  const fetchPaymentPlans = async () => {
    const response = await getPaymentPlans();
    if (response.data && Array.isArray(response.data)) {
      const transformedData = response.data.map((item: PaymentPlans) => ({
        ...item,
        status: item.status.toLowerCase(),
        type: item.type.toLowerCase(),
      }));
      return transformedData;
    }
  };

  return useQuery(QueryKeys.GET_PAYMENT_PLANS, fetchPaymentPlans, {
    staleTime: 0,
  });
};

export const useFetchScheduledPayments = () => {
  const fetchScheduledPayments = async () => {
    const response = await getScheduledPayments();
    if (response.data && Array.isArray(response.data)) {
      const transformedData = response.data.map((item: ScheduledPayment) => ({
        ...item,
        status: item.status.toLowerCase(),
      }));
      return transformedData;
    }
  };

  return useQuery(QueryKeys.GET_SCHEDULED_PAYMENTS, fetchScheduledPayments, {
    staleTime: 0,
  });
};

export const useFetchNextPayment = () => {
  const fetchNextPayments = async () => {
    const resp = await getNextPaymentData();
    return resp;
  };

  return useQuery(QueryKeys.GET_NEXT_PAYMENT_DATA, fetchNextPayments, {
    staleTime: 0,
  });
};
