import React from 'react';
import { useEffect, useState } from 'react';
import StatusTable from '../status-table';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { Transactions, TransactionFields } from '../../types/payments';
import {
  generateAriaLabelElement,
  generateAriaLabelStrong,
} from 'src/helpers/aria-label-helper';
import { currencyFormatWithDecimals } from 'src/helpers/format';

const header: GridColDef[] = [
  {
    field: 'date',
    headerName: 'Date',
    type: 'string',
    width: 10,
    flex: 1,
    editable: false,
    cellClassName: 'not-clickable',
    renderCell: (params: GridRenderCellParams) => {
      const ariaLabelElement = generateAriaLabelElement(params.value);
      return ariaLabelElement;
    },
    sortable: false,
  },
  {
    field: 'plan',
    headerName: 'Plan',
    type: 'string',
    width: 150,
    flex: 2,
    editable: false,
    cellClassName: 'not-clickable',
    renderCell: (params: GridRenderCellParams) => {
      const ariaLabelElement = generateAriaLabelElement(params.value);
      return ariaLabelElement;
    },
    sortable: false,
  },
  {
    field: 'type',
    headerName: 'Type',
    type: 'string',
    width: 100,
    flex: 1,
    editable: false,
    cellClassName: 'not-clickable',
    renderCell: (params: GridRenderCellParams) => {
      const ariaLabelElement = generateAriaLabelElement(params.value);
      return ariaLabelElement;
    },
    sortable: false,
  },
  {
    field: 'charge',
    headerName: 'Charges',
    type: 'number',
    width: 100,
    flex: 1,
    editable: false,
    cellClassName: 'not-clickable',
    renderCell: (params: GridRenderCellParams) => {
      const ariaLabelStrong = generateAriaLabelStrong(params.value);
      return ariaLabelStrong;
    },
    sortable: false,
  },
  {
    field: 'payment',
    headerName: 'Payments',
    type: 'number',
    width: 150,
    flex: 1,
    editable: false,
    cellClassName: 'not-clickable',
    renderCell: (params: GridRenderCellParams) => {
      const ariaLabelStrong = generateAriaLabelStrong(params.value);
      return ariaLabelStrong;
    },
    sortable: false,
  },
];

interface TransactionHistoryProps {
  payments: Transactions[];
}

export default function TransactionHistory({
  payments,
}: TransactionHistoryProps) {
  const [mappedData, setMappedData] = useState<TransactionFields[]>([]);

  useEffect(() => {
    const data =
      payments &&
      payments.map(
        (payment: Transactions): TransactionFields => ({
          id: payment.id,
          date: new Date(payment.date).toLocaleDateString(),
          type: payment.type,
          charge:
            parseFloat(payment.charge) !== 0
              ? currencyFormatWithDecimals(payment.charge, 2)
              : '',
          plan: payment.plan,
          payment:
            parseFloat(payment.payment) !== 0
              ? currencyFormatWithDecimals(payment.payment, 2)
              : '',
        })
      );
    setMappedData(data);
  }, [payments]);

  let counter = 0;
  //TODO: Replace this function with a mechanism to get a unique id for scheduled payments with type = 'Fee'
  function generateUniqueId(): string {
    const timestampPart = Date.now().toString(36);
    const counterPart = (counter++).toString(36);
    return timestampPart + counterPart;
  }
  const rowsWithIds = mappedData.map((row) => ({
    ...row,
    id: generateUniqueId(),
  }));

  return (
    <StatusTable
      title="Transaction History"
      rows={rowsWithIds}
      header={header}
      noRowsMessage="No transaction history available"
    />
  );
}
