import * as DOMPurify from 'dompurify';
import parse, { HTMLReactParserOptions } from 'html-react-parser';

export function purifyAndParse(
  html: string,
  options: HTMLReactParserOptions | undefined = undefined
) {
  const config = {
    ADD_ATTR: ['target'],
  };

  return parse(DOMPurify.sanitize(html, config), options);
}

// Example on how to swap out elements with Components

// const options: HTMLReactParserOptions = {
//   replace: domNode => {
//     if (domNode instanceof Element && domNode.name === 'h2') {
//       const props = attributesToProps(domNode.attribs);
//       return React.createElement(
//         DisplayMedium,
//         {},
//         domToReact(domNode.children, options)
//       );
//     }
//   }
// };
