import { Dispatch, SetStateAction } from 'react';
import { MutationStatus } from 'react-query';
import { Pathway } from 'src/types/pathway';
import { Box, Grid, Typography, useTheme } from '@mui/material';
import { ExpansionPanel } from '@pennfoster/pfc-design-system/molecules/expansion-panel';
import PathwayExpansionRow from './expansion-row';
import SkeletonLoaders from './expansion-row/skeleton-loaders';

export interface PathwaySelectedBoxProps {
  mutationStatus: MutationStatus;
  pathways?: Pathway[];
  selectedPathway: Pathway | null;
  setSelectedPathway: Dispatch<SetStateAction<Pathway | null>>;
}

export default function PathwaySelectionBox({
  mutationStatus,
  pathways,
  selectedPathway,
  setSelectedPathway,
}: PathwaySelectedBoxProps) {
  const { palette } = useTheme();
  let pathwayRows: JSX.Element | JSX.Element[] = <SkeletonLoaders />;

  if (pathways) {
    pathwayRows = pathways.map((pathway) => {
      let buttonText = 'Add';
      let disableSelect = false;

      if (selectedPathway) {
        disableSelect = true;
        if (pathway.sisId === selectedPathway.sisId) {
          buttonText = mutationStatus === 'success' ? 'Confirmed' : 'Added';
        }
      }

      return (
        <PathwayExpansionRow
          key={`pathway-${pathway.sisId}-row`}
          {...{ pathway, buttonText, disableSelect, setSelectedPathway }}
        />
      );
    });
  }

  return (
    <Grid
      item
      xs={12}
      md={7}
      sx={{
        alignContent: 'flex-start',
        mt: 3,
        pb: 3,
        backgroundColor: palette.background.paper,
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 4 }}>
        <Typography fontSize="16px" fontWeight="700">
          Pathways
        </Typography>
      </Box>
      <ExpansionPanel dataColumnWidth={2} mainColumnWidth={4} singleExpand>
        {pathwayRows}
      </ExpansionPanel>
    </Grid>
  );
}
