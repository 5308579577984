import React, { useContext, useEffect, useReducer } from 'react';
import { useMutation, useQuery } from 'react-query';
import NotificationContext, {
  NotificationsState,
} from 'src/context/notifications';
import { NotificationsAction } from 'src/context/notifications/actions';
import initState from 'src/context/notifications/init-state';
import EnrollmentContext from 'src/context/enrollment';
import QueryKeys from 'src/types/query-keys';
import {
  updateMessageAsRead,
  updateMessageAsUnread,
  getMessagesByFilter,
  getMessages,
} from 'src/api';
import { InboxMessage } from 'src/types/message';
import { setSelectedMessage, setUnreadOnly } from './functions';
import messages from 'src/events/messages';

interface Props {
  children: React.ReactNode;
}

function NotificationsProvider({ children }: Props) {
  const { enrollment } = useContext(EnrollmentContext);
  const [state, dispatch] = useReducer(notificationsReducer, initState);

  function notificationsReducer(
    state: NotificationsState,
    action: NotificationsAction
  ): NotificationsState {
    switch (action.type) {
      case 'SET_INBOX_MESSAGES': {
        return { ...state, inboxMessages: action.payload };
      }
      case 'SET_INBOX_MESSAGES_QUERY_STATUS':
        return { ...state, queryStatus: action.payload };
      case 'UPDATE_MESSAGE': {
        return {
          ...state,
          inboxMessages: state.inboxMessages && [
            ...state.inboxMessages.filter(
              (message: InboxMessage) => message.id !== action.payload.id
            ),
            action.payload,
          ],
        };
      }
      case 'SET_UNREAD_ONLY': {
        return {
          ...state,
          unreadOnly: action.payload,
        };
      }
      case 'SET_SELECTED_MESSAGE': {
        const message = action.payload;
        return { ...state, selectedMessage: message };
      }
      case 'SET_NOTIFICATION_COUNT': {
        return { ...state, notificationCount: action.payload };
      }
      default:
        return state;
    }
  }

  const addSignalRMessage = (message: InboxMessage) => {
    const inbox = [message, ...(state.inboxMessages as Array<InboxMessage>)];
    updateInboxMessages(inbox);
    const updateCount = state.notificationCount + 1;
    updateNotificationCount(updateCount);
  };

  useEffect(() => {
    messages.events(addSignalRMessage);
  });

  const inboxMessagesQuery = useQuery(
    [QueryKeys.GET_INBOX_MESSAGES, state.unreadOnly],
    state.unreadOnly ? () => getMessagesByFilter(false) : getMessages,
    {
      onError: (error) => console.error(error),
    }
  );

  const updateReadMutation = useMutation({
    mutationFn: (messageId: string) => updateMessageAsRead(messageId),
    onSuccess: () => {
      inboxMessagesQuery.refetch();
    },
    onError: (error) => console.error(error),
  });

  const updateUnreadMutation = useMutation({
    mutationFn: (messageId: string) => updateMessageAsUnread(messageId),
    onSuccess: () => {
      inboxMessagesQuery.refetch();
    },
    onError: (error) => console.error(error),
  });

  function updateMessageToRead({ id }: InboxMessage) {
    updateReadMutation.mutate(id);
  }

  function updateMessageToUnread({ id }: InboxMessage) {
    updateUnreadMutation.mutate(id);
  }

  function updateInboxMessages(messages: InboxMessage[]) {
    dispatch({
      type: 'SET_INBOX_MESSAGES',
      payload: messages,
    });
  }

  function updateNotificationCount(count: number) {
    dispatch({
      type: 'SET_NOTIFICATION_COUNT',
      payload: count,
    });
  }

  useEffect(() => {
    inboxMessagesQuery.refetch();
  }, [enrollment?.enrollmentId, state.unreadOnly]);

  useEffect(() => {
    const notificationCount =
      state.inboxMessages?.reduce(
        (acc, message) => (message.readStatus ? acc : acc + 1),
        0
      ) || 0;
    updateNotificationCount(notificationCount);
  }, [state.inboxMessages]);

  // Query Status Use Effect.
  useEffect(() => {
    dispatch({
      type: 'SET_INBOX_MESSAGES_QUERY_STATUS',
      payload: inboxMessagesQuery.status,
    });

    switch (inboxMessagesQuery.status) {
      case 'error':
        console.error('Error fetching messages', inboxMessagesQuery.error);
        break;
      case 'success':
        if (inboxMessagesQuery.data?.data) {
          dispatch({
            type: 'SET_INBOX_MESSAGES',
            payload: inboxMessagesQuery.data.data,
          });
        }
    }
  }, [
    inboxMessagesQuery.error,
    inboxMessagesQuery.status,
    inboxMessagesQuery.data,
  ]);

  return (
    <NotificationContext.Provider
      value={[
        {
          ...state,
          updateInboxMessages,
          updateMessageToRead,
          updateMessageToUnread,
          setSelectedMessage,
          setUnreadOnly,
          updateNotificationCount,
        },
        dispatch,
      ]}
    >
      {children}
    </NotificationContext.Provider>
  );
}

export default NotificationsProvider;
