import { Course } from './course';
import { ElectivePlaceHolder } from './elective-place-holder';

export enum PathwayStatusValues {
  None = 'none',
  Elective = 'elective',
  Pathway = 'pathway',
  ElectiveAndPathway = 'electiveAndPathway',
}

export interface DegreeAuditTerm {
  readonly termId: number;
  readonly creditEarned: number;
  readonly creditRequired: number;
  readonly creditPercentage: number;
  readonly courses: Course[];
  readonly electives: Course[];
  readonly electivePlaceHolders: ElectivePlaceHolder[];
  readonly pathwayStatus: PathwayStatusValues;
  readonly cardStatus: string[];
  neededElectives: number;
  enrolledElectives: number;
}
