import React from 'react';
import { MenuItem } from '@mui/material';
import { NotificationsOutlined } from '@mui/icons-material';
import { useTheme } from '@mui/material';
import { Box } from '@mui/system';
import { Badge, IconButton, Typography } from '@pennfoster/pfc-design-system';

interface NotificationIconProps {
  mobile?: boolean;
  notificationCount: number;
  toggleDrawer: () => void;
}

const NotificationIcon: React.FC<NotificationIconProps> = ({
  mobile,
  notificationCount,
  toggleDrawer,
}) => {
  const theme = useTheme();

  return mobile ? (
    <MenuItem onClick={toggleDrawer}>
      <Badge
        aria-label={`Unread Notifications: ${notificationCount}`}
        badgeContent={notificationCount}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        sx={{
          '.MuiBadge-badge': { transform: 'scale(1) translate(10%, 10%)' },
        }}
      >
        <NotificationsOutlined sx={{ fontSize: 24, mr: 2 }} />
      </Badge>
      <Typography variant="body1">Messages</Typography>
    </MenuItem>
  ) : (
    <Box alignItems="center" display="flex">
      <Badge
        badgeContent={notificationCount}
        key="notification-number"
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        sx={{
          '.MuiBadge-badge': { transform: 'scale(1) translate(10%, 10%)' },
        }}
      >
        <IconButton
          aria-label="Notifications"
          onClick={toggleDrawer}
          color="inherit"
          sx={{ mx: 2, color: 'white' }}
          icon={
            <NotificationsOutlined
              sx={{
                backgroundColor: theme.palette.text.light,
                borderRadius: '50%',
                color: theme.palette.neutral[60],
                fontSize: '32px',
                height: '40px',
                padding: '6px',
                width: '40px',
              }}
            />
          }
          pfVariant="standard"
        >
          <NotificationsOutlined
            sx={{
              backgroundColor: theme.palette.text.light,
              borderRadius: '50%',
              color: theme.palette.neutral[60],
              fontSize: '32px',
              height: '40px',
              padding: '6px',
              width: '40px',
            }}
          />
        </IconButton>
      </Badge>
    </Box>
  );
};

export default NotificationIcon;
