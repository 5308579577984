/**
 * Calculates the credit percentage based on the earned credits and required
 * credits.
 *
 * @param creditEarned - The number of credits earned.
 * @param creditRequired - The number of credits required.
 * @returns The calculated credit percentage.
 */
export function calcCreditPercentage(
  creditEarned: number,
  creditRequired: number
) {
  if (!creditEarned || !creditRequired) {
    return 0;
  }
  return (creditEarned / creditRequired) * 100;
}
