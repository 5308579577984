import { ElectivePoolCourse } from 'src/types/electives';

import { Typography } from '@mui/material';

import { StyledExpansionRow } from './styled';

const ElectiveRowDescription = ({
  sisCourseId,
  courseDescription,
}: ElectivePoolCourse) => {
  return (
    <StyledExpansionRow key={`elective-${sisCourseId}-row`}>
      <Typography key={`elective-${sisCourseId}-row-details`}>
        {courseDescription}
      </Typography>
    </StyledExpansionRow>
  );
};

export default ElectiveRowDescription;
