import React from 'react';

import { Box, TableCell, TableRow } from '@mui/material';
import { Alert } from '@pennfoster/pfc-design-system';

export default function MyElectiveCardMessageRow() {
  return (
    <TableRow sx={{ 'td, th': { border: 0 } }}>
      <TableCell colSpan={2}>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Alert pfVariant="standard" severity="info">
            Your electives will appear here when you select your chosen courses
          </Alert>
        </Box>
      </TableCell>
    </TableRow>
  );
}
