import { CardContent, Table, TableBody } from '@mui/material';
import { Card } from '@pennfoster/pfc-design-system';

import MyElectivesCardCoursesRows from './courses-rows';
import MyElectivesCardHeader from './header';
import MyElectiveCardMessageRow from './message-row';

interface Props {
  enrolledElectiveCoursesNames: string[];
  nEnrolledElectives: number;
  nNeededElectives: number;
}

export default function MyElectivesCard({
  enrolledElectiveCoursesNames,
  nEnrolledElectives,
  nNeededElectives,
}: Props) {
  return (
    <Card pfVariant="default" borderColor="secondary.90">
      <CardContent>
        <Table>
          <MyElectivesCardHeader
            nEnrolledElectives={nEnrolledElectives}
            nNeededElectives={nNeededElectives}
          />
          <TableBody>
            {nEnrolledElectives > 0 ? (
              <MyElectivesCardCoursesRows
                enrolledElectiveCoursesNames={enrolledElectiveCoursesNames}
              />
            ) : (
              <MyElectiveCardMessageRow />
            )}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
}
