import React from 'react';
import { useTheme } from '@mui/material';
import { Chip } from '@pennfoster/pfc-design-system';
import { Link, useLocation } from 'react-router-dom';
import { LabelSmall } from 'src/styles';
import { Variant } from '@mui/material/styles/createTypography';

interface LinkProps {
  route: string;
  label: string;
}

interface Crumb {
  link: LinkProps;
  icon?: string;
  variant?: 'inherit' | Variant;
}

export default function Crumb({
  variant,
  icon,
  link: { route, label },
}: Crumb) {
  const { pathname } = useLocation();
  const theme = useTheme();
  if (pathname === route) {
    return (
      <LabelSmall variant={variant} sx={{ display: 'inline' }}>
        {label}
      </LabelSmall>
    );
  }
  return (
    <Link to={route}>
      <Chip
        backgroundColor={theme.palette.background.paper}
        label={<LabelSmall variant={variant}>{label}</LabelSmall>}
        iconName={icon}
        clickable
      />
    </Link>
  );
}
