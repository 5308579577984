import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';

interface MessageDialogueProps {
  type: 'warning' | 'info' | 'error' | 'success' | undefined;
  message: string;
}

function getBackgroundColor(type: MessageDialogueProps['type']) {
  const theme = useTheme();
  switch (type) {
    case 'warning':
      return theme.palette.neutral[90];
    case 'error':
      return 'error.100';
    case 'info':
      return 'secondary.100';
    case 'success':
      return 'success.90';
    default:
      return theme.palette.neutral[90];
  }
}

export default function MessageDialogue({
  type,
  message,
}: MessageDialogueProps) {
  return (
    <Box
      sx={{
        backgroundColor: getBackgroundColor(type),
        borderRadius: '8px',
        padding: '6px 16px',
      }}
    >
      <Typography>{message}</Typography>
    </Box>
  );
}
