import {
  CardSkeleton,
  CardHeaderSkeleton,
  CardContentSkeleton,
  TasklistSkeleton,
  CardActionsSkeleton,
  ButtonSkeleton,
} from '.';
import { Divider, Box } from '@mui/material';

interface Props {
  status?: boolean;
  divider?: boolean;
  actions?: boolean;
}

export function TasksCardSkeleton({
  status = false,
  divider = true,
  actions = false,
}: Props) {
  return (
    <CardSkeleton size={'small'}>
      <CardHeaderSkeleton status={status} />
      {divider ? <Divider /> : null}
      <CardContentSkeleton sx={{ '&:last-child': { pb: 3, pt: 3 } }}>
        <TasklistSkeleton
          taskCount={2}
          variant={'square-reversed'}
          rowGap={2}
        />
      </CardContentSkeleton>
      {actions ? (
        <CardActionsSkeleton>
          <Box width={'100%'} display={'flex'} justifyContent={'end'}>
            <ButtonSkeleton width={'176px'} size={'large'} />
          </Box>
        </CardActionsSkeleton>
      ) : null}
    </CardSkeleton>
  );
}
