import { TokenObject } from '@pennfoster/pfc-design-system/types/token';
import axios, { AxiosResponse } from 'axios';

export function getTheme(
  themeName: string
): Promise<AxiosResponse<TokenObject>> {
  return axios({
    method: 'GET',
    url: `${process.env.REACT_APP_SC_API}/api/theme?themeName=${themeName}`,
  });
}

export function getLogo(logoName: string): Promise<AxiosResponse<string>> {
  return axios({
    method: 'GET',
    url: `${process.env.REACT_APP_SC_API}/api/logo?logoName=${logoName}`,
  });
}
