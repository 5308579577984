import { Box } from '@mui/material';
import { TasklistItemSkeleton } from './tasklist-item-skeleton';

interface Props {
  taskCount: number;
  width?: number | string;
  variant?: 'default' | 'square-reversed';
  rowGap?: number | string;
}

export function TasklistSkeleton({
  taskCount,
  width = '100%',
  variant = 'default',
  rowGap = 1,
}: Props) {
  return (
    <Box display={'flex'} flexWrap={'wrap'} rowGap={rowGap} width={width}>
      {[...Array(taskCount)].map((e, i) => (
        <TasklistItemSkeleton key={i} variant={variant} />
      ))}
    </Box>
  );
}
