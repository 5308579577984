import { Box, Grid } from '@mui/material';
import AppLayout from '../../components/layout';
import { HeadingH6 } from 'src/styles';
import MakeAPaymentForm from 'src/components/forms/make-a-payment-form';
import { useContext, useEffect, useState } from 'react';
import EnrollmentContext from 'src/context/enrollment';
import { useNavigate } from 'react-router-dom';
import { PAYMENTS_OVERVIEW } from 'src/routes/routemap';

export default function MakeAPayment() {
  const [showTitle, setShowTitle] = useState<boolean>(false);
  const { enrollment } = useContext(EnrollmentContext);
  const navigate = useNavigate();

  useEffect(() => {
    const accountStatus = enrollment?.accountStatus;
    if (accountStatus && accountStatus?.paymentsBlocked) {
      navigate(PAYMENTS_OVERVIEW);
    }
  }, []);

  return (
    <AppLayout>
      <Box
        sx={{
          alignContent: 'center',
          alignItems: 'center',
          flexGrow: 1,
          justifyContent: 'center',
          margin: '0 auto',
          px: '24px',
          maxWidth: '580px',
        }}
      >
        <Grid container spacing={3}>
          {showTitle && (
            <Grid item xs={12}>
              <HeadingH6 variant="h1">Make a Payment</HeadingH6>
            </Grid>
          )}
          <Grid item xs={12}>
            <MakeAPaymentForm onNotificationChange={setShowTitle} />
          </Grid>
        </Grid>
      </Box>
    </AppLayout>
  );
}
