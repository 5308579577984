import React, { useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import PaymentPlan from 'src/components/payment-plan';
import ScheduledPayments from 'src/components/scheduled-payments';
import TransactionHistory from 'src/components/transaction-history';
import EnrollmentContext from 'src/context/enrollment';
import QueryKeys from 'src/types/query-keys';

import { Box, Grid } from '@mui/material';
import { GridSortModel } from '@mui/x-data-grid';

import { getTransactionHistory, Query } from '../../api';
import AppLayout from '../../components/layout';
import { PaymentPlans, ScheduledPayment } from '../../types/payments';
import { PaymentsLoading } from './payments-loading';
import NextPaymentCard from 'src/components/cards/next-payment-card';
import {
  useFetchPaymentPlans,
  useFetchScheduledPayments,
} from 'src/hooks/fetch-payments';
import { useFetchDefaultBackupPaymentMethods } from 'src/hooks/fetch-payment-methods';
import PaymentMethodsCard from 'src/components/cards/payment-methods-card';
import { HeadingH6 } from 'src/styles';

interface IPayment {
  id: number;
  charge: string;
  date: string;
  payment: string;
  type: string;
  plan: string;
}

export default function Payments() {
  const { enrollment } = useContext(EnrollmentContext);

  const [valueName, setValues] = React.useState<string[]>([]);
  const [payments, setPayments] = useState<IPayment[]>([]);
  const [activePaymentPlan, setActivePaymentPlan] = useState<PaymentPlans[]>(
    []
  );
  const [scheduledPayments, setScheduledPayments] = useState<
    ScheduledPayment[]
  >([]);
  const [sortOptions, setSortOptions] = useState<GridSortModel>();

  const { data: paymentPlansData, isLoading: isLoadingPayments } =
    useFetchPaymentPlans();
  const { data: scheduledPaymentsData, isLoading: isLoadingScheduled } =
    useFetchScheduledPayments();
  const { data: dataDefaultBackup, isLoading: isLoadingDefaultBackup } =
    useFetchDefaultBackupPaymentMethods();

  const [query, setQuery] = useState<Query>(
    new Query<IPayment>({
      sorts: [
        {
          column: 'CreatedDate',
          direction: 'desc',
        },
      ],
    })
  );

  useEffect(() => {
    if (paymentPlansData) {
      setActivePaymentPlan(paymentPlansData);
    }
    if (scheduledPaymentsData) {
      setScheduledPayments(scheduledPaymentsData);
    }
  }, [paymentPlansData, scheduledPaymentsData]);

  useEffect(() => {
    let sortQuery;
    let filterQuery;
    if (sortOptions && sortOptions.length > 0) {
      sortQuery = new Query<IPayment>({
        sorts: [
          {
            column: sortOptions[0].field,
            direction:
              sortOptions[0].sort === undefined || sortOptions[0].sort === null
                ? 'asc'
                : sortOptions[0].sort,
          },
        ],
      });
    }

    //Set a value by default
    if (sortQuery === undefined) {
      sortQuery = new Query<IPayment>({
        sorts: [
          {
            column: 'CreatedDate',
            direction: 'desc',
          },
        ],
      });
    }

    if (valueName && valueName.length > 0) {
      filterQuery = new Query<IPayment>({
        filters: [
          {
            column: 'Type',
            operator: 'in',
            value: valueName,
          },
        ],
      });
    }

    const queryParams = new Query<IPayment>({
      filters: filterQuery?.filters,
      sorts: sortQuery?.sorts,
    });

    setQuery(queryParams);
  }, [sortOptions, valueName]);

  const ledgerTransactionsQuery = useQuery(
    [QueryKeys.GET_LEDGER_TRANSACTIONS, enrollment?.enrollmentId],
    () => {
      if (enrollment) {
        getTransactionHistory(enrollment.enrollmentId, query).then(
          (resolve) => {
            setPayments(resolve.data);
          }
        );
      }
    }
  );

  useEffect(() => {
    if (enrollment) {
      const fetchData = async () => {
        await getTransactionHistory(enrollment.enrollmentId, query);
        await ledgerTransactionsQuery.refetch();
      };
      fetchData();
    }
  }, [query, enrollment]);

  return (
    <AppLayout>
      {ledgerTransactionsQuery.isLoading ||
      isLoadingDefaultBackup ||
      isLoadingPayments ||
      isLoadingScheduled ? (
        <PaymentsLoading />
      ) : (
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={3} justifyContent="center">
            <Grid item xs={12} md={10}>
              <HeadingH6 variant="h1">Payments Overview</HeadingH6>
            </Grid>
            <Grid
              container
              item
              xs={12}
              md={10}
              spacing={3}
              sx={{ alignContent: 'flex-start' }}
            >
              <Grid item xs={12} md={6} sx={{ height: 'auto' }}>
                <NextPaymentCard
                  paymentScheduled={scheduledPayments}
                  paymentPlans={activePaymentPlan}
                />
              </Grid>
              <Grid item xs={12} md={6} sx={{ height: 'auto' }}>
                <PaymentMethodsCard
                  paymentMethods={dataDefaultBackup?.data ?? []}
                />
              </Grid>

              <Grid item xs={12}>
                <PaymentPlan data={activePaymentPlan} />
              </Grid>
              <Grid item xs={12}>
                <ScheduledPayments
                  dataScheduledPayments={scheduledPayments}
                  dataPaymentPlans={activePaymentPlan}
                />
              </Grid>
              <Grid item xs={12}>
                <TransactionHistory payments={payments} />
              </Grid>
            </Grid>
          </Grid>
        </Box>
      )}
    </AppLayout>
  );
}
