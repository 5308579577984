import React from 'react';
import { Box } from '@mui/material';
import { currencyFormatWithDecimals } from 'src/helpers/format';
import { Typography } from '@pennfoster/pfc-design-system';
import { cms } from 'src/helpers/language';

interface MyCardContentProps {
  description: string;
  nextPaymentAmount: number;
  status: string;
}

export default function MyCardContent({ description, nextPaymentAmount, status }: MyCardContentProps) {
  const content = cms.content.pages.dashboard.cards.my_payments;
  const textWithSpace = `(${content.plus_tax_postfix})`;
  return (
    <>
      {status !== 'paid' ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <Typography variant="labelDisplay">
            {status !== 'paid' && currencyFormatWithDecimals(nextPaymentAmount || 0)}
          </Typography>
          <Typography variant="labelLarge" sx={{ margin: '4px 0px 0px 4px' }}>
            {textWithSpace}
          </Typography>
        </Box>
      ) : (
        <></>
      )}

      <Typography
        variant="body1"
        sx={{
          mt: 2,
        }}
      >
        {description}
      </Typography>
    </>
  );
}
