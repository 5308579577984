import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AppLayout from 'src/components/layout';
import PathwaysCart, { PathwayCartProps } from 'src/components/pathway/cart';
import PathwayHeader from 'src/components/pathway/header';
import PathwaySelectionBox, {
  PathwaySelectedBoxProps,
} from 'src/components/pathway/selection-box';
import PathwaySubmissionModal, {
  PathwaySubmissionModalProps,
} from 'src/components/pathway/submission-modal';
import { EnrollmentState } from 'src/context/enrollment';
import { usePathwayMutation } from 'src/hooks/pathway';
import { ELECTIVES, COURSES } from 'src/routes/routemap';
import { Pathway } from 'src/types/pathway';

import { Box, Grid, useMediaQuery, useTheme } from '@mui/material';
import { PathwayStatusValues } from 'src/types/degree-audit-term';
import DegreeAuditContext from 'src/context/degree-audit';
import UserContext from 'src/context/user';

export interface PathwayViewProps {
  pathways: Pathway[];
  enrollmentsRefetch: EnrollmentState['refetch'];
  pathwayStatus: PathwayStatusValues | undefined;
}

export default function PathwayView({
  pathways,
  enrollmentsRefetch,
  pathwayStatus,
}: PathwayViewProps) {
  const theme = useTheme();
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [selectedPathway, setSelectedPathway] = useState<Pathway | null>(null);
  const [isSubmitModalOpen, setIsSubmitModalOpen] = useState<boolean>(false);

  const mutation = usePathwayMutation();

  const pathwayHasElectivePools = selectedPathway?.hasElectivePools ?? false;

  const { currentTerm } = useContext(DegreeAuditContext);
  const {setSubmittedPathway} = useContext(UserContext);

  const pathwaySelectionBoxProps: PathwaySelectedBoxProps = {
    mutationStatus: mutation.status,
    pathways,
    selectedPathway,
    setSelectedPathway,
  };

  const pathwayCartProps: PathwayCartProps = {
    isSmallScreen,
    isSubmissionModalOpen: isSubmitModalOpen,
    pathwayHasElectivePools: pathwayHasElectivePools,
    isPathwaySubmitted: mutation.isSuccess,
    isPathwaySelected: !!selectedPathway,
    selectedPathwayName: selectedPathway?.name || '',
    mutationStatus: mutation.status,
    clearSelectedPathway: () => setSelectedPathway(null),
    handleSubmitClick: () => {      
      setSubmittedPathway(true);
      setIsSubmitModalOpen(true);
    },
    handleContinueClick: async () => {
      setSubmittedPathway(false);
      navigate(
        currentTerm?.pathwayStatus === PathwayStatusValues.Elective
          ? ELECTIVES
          : COURSES
      );      
    },
  };

  const pathwaySubmissionModalProps: PathwaySubmissionModalProps = {
    pathwayHasElectivePools: pathwayHasElectivePools,
    isOpen: isSubmitModalOpen,
    selectedPathwayName: selectedPathway?.name || '',
    onCancelHandler: () => setIsSubmitModalOpen(false),
    onConfirmHandler: async () => {
      if (!selectedPathway?.sisId) {
        throw new Error('No Pathway SIS ID found');
      }
      mutation.mutate(selectedPathway.sisId);
      setIsSubmitModalOpen(false);
    },
  };

  return (
    <AppLayout>
      <Box
        sx={{
          flexGrow: 1,
          paddingBottom: '16px',
          [theme.breakpoints.down('sm')]: {
            paddingBottom: 'calc(56px + 16px)',
          },
        }}
      >
        <Grid container spacing={3}>
          <PathwayHeader pathwayStatus={pathwayStatus!} />
          <PathwaySelectionBox {...pathwaySelectionBoxProps} />
          <PathwaysCart {...pathwayCartProps} />
          <PathwaySubmissionModal {...pathwaySubmissionModalProps} />
        </Grid>
      </Box>
    </AppLayout>
  );
}
