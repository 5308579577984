import { ProgramDTO } from 'src/types/enrollment';
import {
  CalculateSalesTaxRequest,
  NotificationPaymentErrorCardProps,
  PaymentMethod,
  PaymentTaxesResponse,
  ScheduledPayment,
} from 'src/types/payments';

export type FormState = {
  dialogOpen: boolean;
  individualPaymentsSelected: boolean;
  addPaymentMethodOpen: boolean;
  mostRecentPaymentDueDate: string | undefined;
  isChanged: boolean;
  newCard: PaymentMethod | undefined;
  defaultCard: PaymentMethod | undefined;
  paymentMethods: PaymentMethod[];
  selectedscheduledPayments: ScheduledPayment[];
  amount: number;
  paymentTaxesResponse: PaymentTaxesResponse | undefined;
  selectedProgram: ProgramDTO | undefined;
  salesTaxRequest: CalculateSalesTaxRequest | undefined;
  isLoadingTaxes: boolean;
  isErrorOnTaxes: boolean;
  notificationProps: NotificationPaymentErrorCardProps | null;
  isLoading: boolean;
  isLoadingPrograms: boolean;
};

export type formAction =
  | { type: 'SET_DIALOG_OPEN'; payload: boolean }
  | { type: 'SET_INDIVIDUAL_PAYMENTS_SELECTED'; payload: boolean }
  | { type: 'SET_ADD_PAYMENT_METHOD_OPEN'; payload: boolean }
  | { type: 'SET_MOST_RECENT_PAYMENT_DUE_DATE'; payload: string | undefined }
  | { type: 'SET_IS_CHANGED'; payload: boolean }
  | { type: 'SET_NEW_CARD'; payload: PaymentMethod | undefined }
  | { type: 'SET_DEFAULT_CARD'; payload: PaymentMethod | undefined }
  | { type: 'SET_PAYMENT_METHODS'; payload: PaymentMethod[] }
  | { type: 'SET_SELECTED_SCHEDULED_PAYMENTS'; payload: ScheduledPayment[] }
  | { type: 'SET_AMOUNT'; payload: number }
  | {
      type: 'SET_PAYMENT_TAXES_RESPONSE';
      payload: PaymentTaxesResponse | undefined;
    }
  | { type: 'SET_SELECTED_PROGRAM'; payload: ProgramDTO | undefined }
  | {
      type: 'SET_SALES_TAX_REQUEST';
      payload: CalculateSalesTaxRequest | undefined;
    }
  | { type: 'SET_IS_LOADING_TAXES'; payload: boolean }
  | { type: 'SET_IS_ERROR_ON_TAXES'; payload: boolean }
  | { type: 'SET_IS_ERROR_ON_TAXES'; payload: boolean }
  | {
      type: 'SET_NOTIFICATION_PROPS';
      payload: NotificationPaymentErrorCardProps | null;
    }
  | { type: 'SET_IS_LOADING'; payload: boolean }
  | { type: 'SET_IS_LOADING_PROGRAMS'; payload: boolean };

export const initialState = {
  dialogOpen: false,
  individualPaymentsSelected: true,
  addPaymentMethodOpen: false,
  mostRecentPaymentDueDate: '',
  isChanged: false,
  newCard: undefined,
  defaultCard: undefined,
  paymentMethods: [] as PaymentMethod[],
  selectedscheduledPayments: [] as ScheduledPayment[],
  amount: 0,
  paymentTaxesResponse: undefined,
  selectedProgram: undefined,
  salesTaxRequest: undefined,
  isLoadingTaxes: false,
  isErrorOnTaxes: false,
  notificationProps: null,
  isLoading: false,
  isLoadingPrograms: false,
};

export function reducer(state: FormState, action: formAction) {
  switch (action.type) {
    case 'SET_DIALOG_OPEN':
      return { ...state, dialogOpen: action.payload };
    case 'SET_INDIVIDUAL_PAYMENTS_SELECTED':
      return { ...state, individualPaymentsSelected: action.payload };
    case 'SET_ADD_PAYMENT_METHOD_OPEN':
      return { ...state, addPaymentMethodOpen: action.payload };
    case 'SET_MOST_RECENT_PAYMENT_DUE_DATE':
      return { ...state, mostRecentPaymentDueDate: action.payload };
    case 'SET_IS_CHANGED':
      return { ...state, isChanged: action.payload };
    case 'SET_NEW_CARD':
      return { ...state, newCard: action.payload };
    case 'SET_DEFAULT_CARD':
      return { ...state, defaultCard: action.payload };
    case 'SET_PAYMENT_METHODS':
      return { ...state, paymentMethods: action.payload };
    case 'SET_SELECTED_SCHEDULED_PAYMENTS':
      return { ...state, selectedscheduledPayments: action.payload };
    case 'SET_AMOUNT':
      return { ...state, amount: action.payload };
    case 'SET_PAYMENT_TAXES_RESPONSE':
      return { ...state, paymentTaxesResponse: action.payload };
    case 'SET_SELECTED_PROGRAM':
      return { ...state, selectedProgram: action.payload };
    case 'SET_SALES_TAX_REQUEST':
      return { ...state, salesTaxRequest: action.payload };
    case 'SET_IS_LOADING':
      return { ...state, isLoading: action.payload };
    case 'SET_IS_LOADING_TAXES':
      return { ...state, isLoadingTaxes: action.payload };
    case 'SET_IS_ERROR_ON_TAXES':
      return { ...state, isErrorOnTaxes: action.payload };
    case 'SET_IS_LOADING_PROGRAMS':
      return { ...state, isLoadingPrograms: action.payload };
    case 'SET_NOTIFICATION_PROPS':
      return { ...state, notificationProps: action.payload };
    default:
      return state;
  }
}
