import React from 'react';

import { ExpansionRow } from '@pennfoster/pfc-design-system/molecules/expansion-panel';

import ProgressChip from 'src/components/progress-chip';
import { Lesson } from 'src/types/lesson';
import { isValidDate } from 'src/utils/date-utils';
import { ProgressStatus } from 'src/types/progress-status';
import { Box } from '@mui/material';
import { Typography } from '@pennfoster/pfc-design-system';
import { shortenToEllipsis } from 'src/utils/string-utils';
import { maxCourseNameLength } from '../expansion-panel-with-items';

interface ExpansionRowsProps {
  items: Lesson[];
}

export default function ExpansionRows({ items }: ExpansionRowsProps) {
  const renderExpansionRow = (lesson: Lesson, index: number) => {
    return (
      <ExpansionRow
        key={'lesson-' + index}
        details={[
          lesson.status === ProgressStatus.None ? (
            '-'
          ) : (
            <ProgressChip
              key={'program-chip-' + index}
              status={lesson.status}
              isStatusForLesson={true}
            />
          ),
          lesson.numericGrade ?? '-',
          isValidDate(lesson.gradedDate)
            ? new Date(lesson.gradedDate).toLocaleDateString()
            : '-',
        ]}
      >
        <Box
          sx={{
            maxWidth: '292px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          <Typography variant="body2" sx={{ lineHeight: '24px' }} title={lesson.name}>
            {shortenToEllipsis(lesson.name, maxCourseNameLength)}
          </Typography>
        </Box>
      </ExpansionRow>
    );
  };

  return <>{items.map((lesson, index) => renderExpansionRow(lesson, index))}</>;
}
