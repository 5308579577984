import { useTheme, Box } from '@mui/material';
import { Skeleton } from '@pennfoster/pfc-design-system';

export function ListItemSkeleton() {
  const theme = useTheme();
  return (
    <Box
      height={theme.spacing(6)}
      pb={1}
      pt={1}
      display={'flex'}
      alignItems={'center'}
      width={'100%'}
    >
      <Skeleton variant={'rounded'} width={'100%'} height={theme.spacing(2)} />
    </Box>
  );
}
