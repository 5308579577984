/* eslint-disable @typescript-eslint/no-unused-vars */
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const LabelSmall = styled(Typography)(({ theme }) => ({
  ...theme.typography.labelSmall,
}));

export const LabelMedium = styled(Typography)(({ theme }) => ({
  ...theme.typography.labelMedium,
}));

export const LabelLarge = styled(Typography)(({ theme }) => ({
  ...theme.typography.labelLarge,
}));

export const Overline = styled(Typography)(({ theme }) => ({
  ...theme.typography.overline,
}));

export const HeadingH4 = styled(Typography)(({ theme }) => ({
  ...theme.typography.headingH4,
}));

export const HeadingH5 = styled(Typography)(({ theme }) => ({
  ...theme.typography.headingH5,
}));

export const HeadingH6 = styled(Typography)(({ theme }) => ({
  ...theme.typography.headingH6,
}));

export const Body1 = styled(Typography)(({ theme }) => ({
  ...theme.typography.body1,
}));

export const Body2 = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
}));

export const Caption = styled(Typography)(({ theme }) => ({
  ...theme.typography.caption,
}));

export const DisplayH1 = styled(Typography)(({ theme }) => ({
  ...theme.typography.displayH1,
}));

export const DisplayH2 = styled(Typography)(({ theme }) => ({
  ...theme.typography.displayH2,
}));

export const DisplayH3 = styled(Typography)(({ theme }) => ({
  ...theme.typography.displayH3,
}));

export const Subtitle1 = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle1,
}));

export const desktopMessageH1 = styled(Typography)(({ theme }) => ({
  ...theme.typography.desktopMessageH1,
}));

export const desktopMessageH2 = styled(Typography)(({ theme }) => ({
  ...theme.typography.desktopMessageH2,
}));

export const desktopMessageH3 = styled(Typography)(({ theme }) => ({
  ...theme.typography.desktopMessageH3,
}));

export const desktopMessageH4 = styled(Typography)(({ theme }) => ({
  ...theme.typography.desktopMessageH4,
}));

export const desktopMessageH5 = styled(Typography)(({ theme }) => ({
  ...theme.typography.desktopMessageH5,
}));

export const desktopMessageH6 = styled(Typography)(({ theme }) => ({
  ...theme.typography.desktopMessageH6,
}));

export const Subtitle2 = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
}));

export const UnorderedList = styled('ul')(({ theme }) => ({
  padding: 0,
  marginLeft: theme.spacing(3),
  marginTop: 0,
}));

export const OrderedList = styled('ol')(({ theme }) => ({
  padding: 0,
  marginLeft: theme.spacing(3),
  marginTop: 0,
}));

export const MerriWeatherTitle = styled(Typography)(({ theme }) => ({
  fontFamily: 'Merriweather',
  fontWeight: 300,
  fontSize: '28px',
  lineHeight: '40px',
}));

export const OverdueNotification = styled(Typography)(({ theme }) => ({
  color: 'rgba(121, 24, 111, 1)',
  fontFamily: 'Nunito Sans',
  fontWeight: 400,
  fontSize: '12px',
  letterSpacing: '0.25px',
  lineHeight: '16px',
}));

export const OverdueLinkAction = styled(Typography)(({ theme }) => ({
  color: 'rgba(104, 4, 94, 1)',
  fontFamily: 'Nunito Sans',
  fontWeight: 'bold',
  fontSize: '12px',
  letterSpacing: '0.25px',
  lineHeight: '16px',
  textAlign: 'right',
  textDecoration: 'none',
}));

export const PrimaryCardTitle = styled(Typography)(({ theme }) => ({
  fontFamily: 'Nunito Sans',
  fontWeight: 700,
  fontSize: '18px',
  lineHeight: '24px',
}));

export const PrimaryCardTitleBillForm = styled(Typography)(({ theme }) => ({
  color: 'rgba(32, 40, 52, 1)',
  fontFamily: 'Nunito Sans',
  fontWeight: 700,
  fontSize: '15px',
  lineHeight: '24px',
}));

export const NunitoSansNameOnCard = styled(Typography)(({ theme }) => ({
  color: 'rgba(15, 23, 34, 1)',
  fontFamily: 'Nunito Sans',
  fontWeight: 600,
  fontSize: '12px',
  letterSpacing: '1%',
  lineHeight: '16px',
}));

export const NunitoSansLink = styled(Typography)(({ theme }) => ({
  color: 'rgba(0, 113, 243, 1)',
  fontFamily: 'Nunito Sans',
  fontWeight: 700,
  fontSize: '15px',
  lineHeight: '24px',
}));

export const ConfirmPayment = styled(Typography)(({ theme }) => ({
  color: 'rgba(162, 166, 172, 1)',
  fontFamily: 'Nunito Sans',
  fontWeight: 700,
  fontSize: '15px',
  lineHeight: '24px',
}));

export const NunitoSansErrorMessage = styled(Typography)(({ theme }) => ({
  color: 'rgba(32, 40, 52, 1)',
  fontFamily: 'Nunito Sans',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '20px',
}));

export const NunitoSansTitle = styled(Typography)(({ theme }) => ({
  fontFamily: 'Nunito Sans',
  color: theme.palette.primary.main,
  fontWeight: 700,
  fontSize: '18px',
  lineHeight: '24px',
  marginBottom: theme.spacing(1),
}));

export const NunitoSansContent = styled(Typography)(({ theme }) => ({
  fontFamily: 'Nunito Sans',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '24px',
}));

export const GloockFont = styled(Typography)(({ theme }) => ({
  fontFamily: 'Gloock',
  fontWeight: 400,
  fontSize: '32px',
  lineHeight: '32px',
}));

export const MessageCaption = styled(Typography)(({ theme }) => ({
  fontFamily: 'Nunito Sans',
  fontSize: '12px',
  lineHeight: '16px',
}));

export const NunitoSans24 = styled(Typography)(({ theme }) => ({
  fontFamily: 'Nunito Sans',
  fontSize: '24px',
  lineHeight: '32px',
  fontWeight: 700,
}));

export default {
  LabelSmall,
  LabelMedium,
  LabelLarge,
  Overline,
  HeadingH4,
  HeadingH5,
  HeadingH6,
  Body1,
  Body2,
  Caption,
  MessageCaption,
  DisplayH1,
  DisplayH2,
  DisplayH3,
  Subtitle1,
  Subtitle2,
  MerriWeatherTitle,
  NunitoSansTitle,
  NunitoSansContent,
  NunitoSans24,
  PrimaryCardTitle,
  GloockFont,
};
