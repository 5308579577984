import { MutationStatus, QueryStatus } from 'react-query';
import { noop } from 'src/helpers/noop';
import { InboxMessage } from 'src/types/message';

export default {
  queryStatus: 'idle' as QueryStatus,
  mutationStatus: 'idle' as MutationStatus,
  inboxMessages: [] as InboxMessage[],
  selectedMessage: undefined,
  unreadOnly: false,
  notificationCount: 0,
  updateMessageToRead: noop,
  updateMessageToUnread: noop,
  updateInboxMessages: noop,
  updateNotificationCount: noop,
  setSelectedMessage: noop,
  setUnreadOnly: noop,
};
