import AppLayout from 'src/components/layout';

import { Grid } from '@mui/material';
import {
  MyProgramCardSkeleton,
  PaymentsCardSkeleton,
  PrimaryCardSkeleton,
  UpcomingCoursesCardSkeleton,
  TasksCardSkeleton,
  CardSkeleton,
} from 'src/components/skeleton-components';

export default function DashboardLoadingScreen() {
  return (
    <AppLayout showHelpFooter={false}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CardSkeleton />
        </Grid>
        <Grid container item xs={12} md={7} spacing={3}>
          <Grid item xs={12}>
            <PrimaryCardSkeleton />
          </Grid>
          <Grid item xs={12}>
            <UpcomingCoursesCardSkeleton />
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={12}
          md={5}
          spacing={3}
          sx={{ alignContent: 'flex-start' }}
        >
          <Grid item xs={12}>
            <MyProgramCardSkeleton />
          </Grid>
          <Grid item xs={12}>
            <PaymentsCardSkeleton />
          </Grid>
          <Grid item xs={12}>
            <TasksCardSkeleton />
          </Grid>
        </Grid>
      </Grid>
    </AppLayout>
  );
}
