export enum ProgressStatus {
  Complete = 'complete',
  InProgress = 'inProgress',
  NotStarted = 'notStarted',
  Locked = 'locked',
  Failed = 'failed',
  None = 'none',
}

export type ProgressStatusLabel =
  | 'Complete'
  | 'In-Progress'
  | 'Not Started'
  | 'Locked'
  | 'Failed'
  | 'None';

export const ProgressStatusLabels: Record<ProgressStatus, ProgressStatusLabel> =
  {
    [ProgressStatus.Complete]: 'Complete',
    [ProgressStatus.InProgress]: 'In-Progress',
    [ProgressStatus.NotStarted]: 'Not Started',
    [ProgressStatus.Locked]: 'Locked',
    [ProgressStatus.Failed]: 'Failed',
    [ProgressStatus.None]: 'None',
  };
