import { calcCreditPercentage } from 'src/helpers/credit-percentage';
import { DegreeAuditTerm } from 'src/types/degree-audit-term';
import { Enrollment } from 'src/types/enrollment';

const useCreditPercentage = (enrollment: Enrollment, selectedTerm: DegreeAuditTerm | undefined) => {
  const totalNumberOfTerms = enrollment.program?.totalNumberTerms ?? 1;
  let creditPercentage = 0;

  if (totalNumberOfTerms <= 1) {
    creditPercentage = calcCreditPercentage(enrollment.creditEarned, enrollment.creditRequired);
  } else if (selectedTerm) {
    creditPercentage = calcCreditPercentage(selectedTerm.creditEarned, selectedTerm.creditRequired);
  }
  creditPercentage = Math.min(creditPercentage, 100);

  return Math.round(creditPercentage);

};
export default useCreditPercentage;
