import { Box, useTheme } from '@mui/material';
import { Icon } from '@pennfoster/pfc-design-system';
import { ReactComponent as AmericanExpressIcon } from '../../assets/svg/americanexpress.svg';
import { ReactComponent as VisaIcon } from '../../assets/svg/visa.svg';
import { ReactComponent as DiscoverIcon } from '../../assets/svg/discover.svg';
import { ReactComponent as MastercardIcon } from '../../assets/svg/mastercard.svg';
import { ReactComponent as DefaultCardIcon } from '../../assets/svg/default-card.svg';
import { PaymentMethod } from '../../types/payments';
import { Body1 } from 'src/styles';
import { bankType } from 'src/constants/bankType';

interface IPaymentMethodLoadable extends PaymentMethod {
  loading?: boolean;
}

export default function CreditCardPayment({ brand, type, lastFour, bankName }: IPaymentMethodLoadable) {
  const theme = useTheme();
  let cardImage;
  let brandName;
  switch (brand?.toLowerCase()) {
    case 'amex':
      cardImage = <AmericanExpressIcon />;
      brandName = 'American Express';
      break;
    case 'visa':
      cardImage = <VisaIcon />;
      brandName = 'Visa';
      break;
    case 'mastercard':
      cardImage = <MastercardIcon />;
      brandName = 'Mastercard';
      break;
    case 'discover':
      cardImage = <DiscoverIcon />;
      brandName = 'Discover';
      break;
    default:
      cardImage = <DefaultCardIcon />;
      switch (type) {
        case 'debit':
          brandName = 'Debit';
          break;
        case 'credit':
          brandName = 'Credit';
          break;
        case 'prepaid':
          brandName = 'Prepaid';
          break;
        default:
          brandName = 'Card';
      }
  }

  return (
    <Box
      sx={{
        flexDirection: 'row',
        display: 'flex',
        maxWidth: '375px',
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          borderRadius: '2px',
          marginRight: theme.spacing(4),
          order: 0,
          width: '40px',
        }}
      >
        <>
          {type === bankType ? (
            <Icon.AccountBalance />
          ) : cardImage !== null ? (
            cardImage
          ) : (
            <Box
              sx={{
                backgroundColor: '#5B6269',
                height: '26px',
                width: '40px',
              }}
            ></Box>
          )}
        </>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <>
            <Body1 noWrap>{type === bankType ? bankName : brandName}</Body1>
            <Body1 noWrap>&nbsp;...{lastFour}</Body1>
          </>
        </Box>
      </Box>
    </Box>
  );
}
