import { useContext, useEffect, useState } from 'react';
import { getLegacyPortalUrl } from 'src/api/enrollments';
import EnrollmentContext from 'src/context/enrollment';
import UserContext from 'src/context/user';

import { Box, MenuItem } from '@mui/material';
import { Alert, Typography } from '@pennfoster/pfc-design-system';

import MultiplePrograms from './multiple-programs';
import SingleProgram from './single-program';
import { cms } from 'src/helpers/language';

export function MyProgramsMenuItem() {
  const { user, changeSelectedEnrollment } = useContext(UserContext);
  const { enrollment, userEnrollment: userEnrollment } = useContext(EnrollmentContext);
  const [legacyPortalUrl, setLegacyPortalUrl] = useState('#');
  const content = cms.content.general.menu;

  const onClickProgram = (enrollmentId: string) => {
    changeSelectedEnrollment(enrollmentId);
  };

  const fetchingLegacyPortalUrl = () => {
    getLegacyPortalUrl(enrollment?.enrollmentId)
      .then((res) => {
        setLegacyPortalUrl(res.data);
      })
      .catch((error) => {
        console.error(error);
        setLegacyPortalUrl('#');
      });
  };

  useEffect(() => {
    fetchingLegacyPortalUrl();
  }, []);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <MenuItem sx={{ ':hover': { backgroundColor: 'transparent' } }}>
        <Box sx={{ alignItems: 'center', display: 'flex', mb: 2 }}>
          <Typography sx={{ fontWeight: 'bold' }}>My Programs</Typography>
        </Box>
      </MenuItem>
      <MenuItem sx={{ mt: '-20px' }}>
        {userEnrollment?.enrollments.length === 1 ? (
          <SingleProgram onClick={onClickProgram} />
        ) : (
          <MultiplePrograms
            defaultProgramId={user?.currentEnrollmentId ?? enrollment?.enrollmentId}
            onClick={onClickProgram}
          />
        )}
      </MenuItem>
      <MenuItem sx={{ mt: '10px' }}>
        <Box sx={{ alignItems: 'center', display: 'flex', mb: 2 }}>
          <Alert pfVariant="standard" severity="info" sx={{ whiteSpace: 'pre-wrap' }}>
            {content.program_alert_1}{' '}
            <a
              href={legacyPortalUrl}
              target="_blank"
              rel="noreferrer noopener"
              style={{
                color: 'inherit',
                outline: 'none',
              }}
            >
              {content.program_alert_link_label}
            </a>{' '}
            {content.program_alert_2}
          </Alert>
        </Box>
      </MenuItem>
    </Box>
  );
}
