import { TableCell, TableHead, Typography } from '@mui/material';

interface Props {
  nEnrolledElectives: number;
  nNeededElectives: number;
}

const MyElectivesCardHeader = ({
  nEnrolledElectives,
  nNeededElectives,
}: Props) => {
  const totalRequiredElectives = nEnrolledElectives + nNeededElectives;

  return (
    <TableHead>
      <TableCell>
        <Typography variant="subtitle1">My Electives</Typography>
      </TableCell>
      {totalRequiredElectives > 0 && (
        <TableCell sx={{ textAlign: 'end' }}>
          {nEnrolledElectives}/{totalRequiredElectives}
        </TableCell>
      )}
    </TableHead>
  );
};

export default MyElectivesCardHeader;
