import React, { useContext } from 'react';
import EnrollmentContext from 'src/context/enrollment';
import { dateFormat } from 'src/helpers/format';

import { CardContent, CardHeader, Grid } from '@mui/material';
import { Card } from '@pennfoster/pfc-design-system';

import { MyProgramStatusChip } from '../program-status-chip';
import CardReadOnlyField from '../card-read-only-field';
import { Subtitle1 } from 'src/styles';
import { getProgramName } from 'src/helpers/get-program-name';
import { getAcademicUXStatus } from 'src/helpers/program-helper';
import { cms } from 'src/helpers/language';

export default function MyProgram() {
  const { enrollment } = useContext(EnrollmentContext);
  const academicStatus = enrollment?.academicStatus && getAcademicUXStatus(enrollment?.academicStatus);
  const content = cms.content.pages.dashboard.cards.my_programs;

  return (
    <Card>
      <CardHeader
        sx={{ px: 3, pt: 2.5, pb: 2 }}
        title={<Subtitle1 variant="h2">{content.title}</Subtitle1>}
        action={<MyProgramStatusChip status={academicStatus!} />}
      />
      <CardContent>
        <Grid container>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12}>
                <CardReadOnlyField
                  labelValue={content.program_name_label}
                  fieldValue={enrollment && getProgramName(enrollment)}
                />
              </Grid>
              <Grid item xs={12}>
                <CardReadOnlyField labelValue={content.degree_label} fieldValue={enrollment?.degree.name} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={6}>
                <CardReadOnlyField
                  labelValue={content.enrollment_date_label}
                  fieldValue={dateFormat(enrollment?.enrollmentDate ?? '', {
                    month: 'numeric',
                    day: 'numeric',
                    year: 'numeric',
                  })}
                />
              </Grid>
              <Grid item xs={6}>
                <CardReadOnlyField
                  labelValue={content.expiration_date_label}
                  fieldValue={dateFormat(enrollment?.expirationDate ?? '', {
                    month: 'numeric',
                    day: 'numeric',
                    year: 'numeric',
                  })}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
