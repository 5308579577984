import { AxiosError } from 'axios';
import { createContext } from 'react';
import { QueryStatus } from 'react-query';
import { noop } from 'src/helpers/noop';
import { User } from 'src/types/user';

export type UserState = {
  queryStatus: QueryStatus;
  queryError: AxiosError | null;
  user?: User;
  submittedPathway?: boolean;
  notificationCount: number;
  updateNotificationCount: (notificationCount: number) => void;
  setSubmittedPathway: (submittedPathway: boolean) => void;
  changeSelectedEnrollment: (selectedEnrollmentId: string) => void;
  isSwitchingEnrollments: boolean;
  refetch: () => void;
};

const UserContext = createContext<UserState>({
  queryStatus: 'idle' as QueryStatus,
  queryError: null,
  submittedPathway: false,
  notificationCount: 0,
  updateNotificationCount: noop,
  setSubmittedPathway: noop,
  changeSelectedEnrollment: noop,
  isSwitchingEnrollments: false,
  refetch: noop,
});

export default UserContext;
