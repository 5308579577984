import { MutationStatus } from 'react-query';
import StyledAlert from 'src/components/styled-alert';

import { AlertColor, Box } from '@mui/material';

interface Props {
  mutationStatus: MutationStatus;
  isElectiveSelected: boolean;
}

export default function ElectivesCartMessage({
  isElectiveSelected,
  mutationStatus,
}: Props) {
  let color: AlertColor;
  let message: string;

  switch (mutationStatus) {
    case 'success':
      color = 'success';
      message = `Congratulations! Your elective courses have been submitted
      successfully. To view your updated course list, click ‘Continue’.`;
      break;
    case 'error':
      color = 'error';
      message = `We’re sorry, we couldn’t submit your elective courses due
      to a system error. Please try again or contact support for
      assistance.`;
      break;
    default:
      color = 'info';
      message = isElectiveSelected
        ? `If you need to make changes to confirmed electives, please contact
        our support team for assistance.`
        : `Your electives will appear here when you click the ‘Add’ button on
        your chosen courses.`;
  }

  return (
    <Box display="flex" sx={{ pt: '8px' }}>
      <StyledAlert severity={color} sx={{ width: '100%' }}>
        {message}
      </StyledAlert>
    </Box>
  );
}
