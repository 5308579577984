import React from 'react';

import { InputLabel } from '@mui/material';
import { TextField } from '@pennfoster/pfc-design-system';

interface Props {
  labelValue?: string;
  fieldValue?: string;
}
export default function CardReadOnlyField({
  labelValue = '',
  fieldValue = '',
}: Props) {
  return (
    <>
      <InputLabel
        sx={{
          paddingLeft: '10px',
          marginBottom: 1,
          typography: 'caption',
        }}
        size={'small'}
      >
        {labelValue}
      </InputLabel>
      <TextField
        className="not-clickable"
        inputProps={{
          'aria-label': labelValue,
          className: 'not-clickable',
          readOnly: true,
        }}
        sx={{
          fieldset: { border: 'none' },
          input: {
            paddingLeft: '10px',
            paddingRight: '10px',
            paddingTop: 1,
            paddingBottom: 1,
          },
          width: '100%',
        }}
        readOnly={true}
        value={fieldValue}
      />
    </>
  );
}
