import React from 'react';

import { AccountSettingsMenuList } from './account-settings-menu-list';
import { AccountSettingsDrawer } from './account-settings-mobile-drawer';

interface Props {
  isMobile: boolean;
  preferredName: string;
  firstName: string;
  lastName: string;
}

export default function AccountSettingsMenu(props: Props) {
  return (
    <>
      {!props.isMobile ? (
        <AccountSettingsMenuList {...props} />
      ) : (
        <AccountSettingsDrawer {...props} />
      )}
    </>
  );
}
