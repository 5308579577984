import { instance } from './apiConfig';
import { AxiosResponse } from 'axios';
import { DegreeAudit } from 'src/types/degree-audit';

export function getDegreeAudit(
  enrollmentId: string,
  termId?: number
): Promise<AxiosResponse<DegreeAudit>> {
  let url = `/user/enrollments/${enrollmentId}/degreeaudit`;
  if (termId) {
    url += `?termId=${termId}`;
  }
  return instance({ method: 'GET', url });
}
