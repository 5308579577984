import React from 'react';
import { Box, DialogContent, Typography } from '@mui/material';
import ErrorSalesTax from 'src/assets/svg/error-sales-tax.svg';
import { LabelLarge } from 'src/styles';
import { Button } from '@pennfoster/pfc-design-system';
import { useNavigate } from 'react-router-dom';
import { PAYMENTS_OVERVIEW } from 'src/routes/routemap';

function ErrorTaxesDialog() {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(PAYMENTS_OVERVIEW);
  };

  return (
    <Box
      sx={{
        height: 'auto',
        display: 'flex',
        width: '300px',
        '@media (min-width: 1024px)': {
          width: '456px',
        },
      }}
    >
      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <img
          src={ErrorSalesTax}
          style={{
            height: 'auto',
            width: '100%',
          }}
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            mt: 2,
          }}
        >
          <Typography variant="headingH6" sx={{ mb: 1 }}>
            We’ve encountered an issue.
          </Typography>
          <br />
          <Typography variant="subtitle1" sx={{ mb: 1 }}>
            We had trouble calculating the sales tax for your payment. Nothing
            was charged to your account. Return to the payment page and retry.
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            width: '100%',
          }}
        >
          <Button
            onClick={handleClick}
            pfVariant="filled"
            color="primary"
            type="submit"
            size="small"
          >
            <LabelLarge>OK</LabelLarge>
          </Button>
        </Box>
      </DialogContent>
    </Box>
  );
}

export default ErrorTaxesDialog;
