import { ReactElement, useContext } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import UserContext from 'src/context/user';
import ROUTES from '../routemap';

/**
 * EnrollmentGuard guards access to child components based on the user's
 * userReady status.
 *
 * @component
 * @returns {ReactElement} Returns the destination component if conditions are
 * met.
 */
function EnrollmentGuard(): ReactElement | null {
  const { user } = useContext(UserContext);
  const location = useLocation();

  if (user?.userReady || user === undefined) {
    return <Outlet />;
  }
  return <Navigate to={ROUTES.PROCESSING_ENROLLMENT} state={{ from: location }} replace />;
}

export default EnrollmentGuard;
