import { MutationStatus } from 'react-query';
import PathwayCartCard from 'src/components/pathway/cart/pathway-cart-card';

import { Grid } from '@mui/material';

import SelectedPathwayDrawer from './selected-drawer';

export interface PathwayCartProps {
  mutationStatus: MutationStatus;
  isSmallScreen: boolean;
  pathwayHasElectivePools: boolean;
  isPathwaySelected: boolean;
  isPathwaySubmitted: boolean;
  isSubmissionModalOpen: boolean;
  selectedPathwayName: string;
  clearSelectedPathway: () => void;
  handleSubmitClick: () => void;
  handleContinueClick: () => void;
}

export default function PathwaysCart(props: PathwayCartProps) {
  if (props.isSmallScreen) {
    return (
      <Grid item xs={12} md={5}>
        <SelectedPathwayDrawer {...props} />
      </Grid>
    );
  }

  return (
    <Grid
      container
      item
      xs={12}
      md={5}
      spacing={3}
      sx={{ alignContent: 'flex-start' }}
    >
      <Grid item xs={12}>
        <PathwayCartCard {...props} />
      </Grid>
    </Grid>
  );
}
