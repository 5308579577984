import StyledAlert from 'src/components/styled-alert';
import { PrimaryCardTitle } from 'src/styles';

import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { Button, Dialog, Typography } from '@pennfoster/pfc-design-system';
import { useContext } from 'react';
import DegreeAuditContext from 'src/context/degree-audit';
import { PathwayStatusValues } from 'src/types/degree-audit-term';

export interface PathwaySubmissionModalProps {
  selectedPathwayName: string;
  isOpen: boolean;
  pathwayHasElectivePools: boolean;
  onCancelHandler: () => void;
  onConfirmHandler: () => void;
}

export default function PathwaySubmissionModal({
  selectedPathwayName,
  isOpen,
  pathwayHasElectivePools: pathwayHasElectivePools,
  onCancelHandler,
  onConfirmHandler,
}: PathwaySubmissionModalProps) {
  const {currentTerm} = useContext(DegreeAuditContext);
  
  const message = currentTerm?.pathwayStatus !== PathwayStatusValues.Pathway
    ? `By selecting this pathway, your next step will be
    to select your electives from an elective pool. If you decide to change
    pathways after this step, please contact our support team for assistance.`

    : `If you decide to change pathways after this step,
    please contact our support team for assistance.`;

  return (
    <Dialog
      title={'Confirm Pathway'}
      open={isOpen}
      PaperProps={{ sx: { borderRadius: '0' } }}
    >
      <DialogTitle sx={{ pt: 0, pl: 0, pr: 0, mb: 2 }}>
        <Typography variant="subtitle1">Confirm Pathway</Typography>
      </DialogTitle>
      <DialogContent sx={{ padding: 0, mb: 2 }}>
        <PrimaryCardTitle>{selectedPathwayName}</PrimaryCardTitle>
      </DialogContent>
      <DialogContent sx={{ padding: 0 }}>
        <StyledAlert severity="info">{message}</StyledAlert>
      </DialogContent>
      <DialogActions sx={{ marginTop: 2, padding: 0 }}>
        <Button pfVariant="text" onClick={onCancelHandler}>
          Cancel
        </Button>
        <Button pfVariant="filled" onClick={onConfirmHandler}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
