import { Card } from '@pennfoster/pfc-design-system';
import { CardContent, CardActions, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import { Subtitle1 } from 'src/styles';
export function EmptyState() {
  const theme = useTheme();

  return (
    <Card sx={{ pl: 4, pr: 4, pb: 4, pt: 4 }}>
      <CardContent sx={{ padding: 3 }}>
        <Subtitle1 variant="h2">No Courses Available</Subtitle1>
      </CardContent>
      <CardActions
        sx={{
          justifyContent: 'flex-end',
          mt: 2,
          pt: theme.spacing(1),
          pb: theme.spacing(1),
        }}
      >
        <Link to="/courses">View All Courses and Grades</Link>
      </CardActions>
    </Card>
  );
}
