import { MutationStatus } from 'react-query';
import ElectivesCartCard from 'src/components/electives/cart/card';
import { SISCourseId } from 'src/types/course';
import { SelectedElective } from 'src/types/electives';

import { Grid } from '@mui/material';

import ElectivesCartDrawer from './drawer';

export interface ElectivesCartProps {
  nNeededElectives: number;
  mutationStatus: MutationStatus;
  isSmallScreen: boolean;
  selectedCourses: SelectedElective[];
  handleRemoveElective: (sisCourseId: SISCourseId) => void;
  handleSubmitClick: () => void;
  handleContinueClick: () => void;
}

export default function ElectivesCart({
  isSmallScreen,
  ...props
}: ElectivesCartProps) {
  if (isSmallScreen) {
    return (
      <Grid item xs={12} md={5}>
        <ElectivesCartDrawer {...props} />
      </Grid>
    );
  }

  return (
    <Grid
      container
      item
      xs={12}
      md={5}
      spacing={3}
      sx={{ alignContent: 'flex-start' }}
    >
      <Grid item xs={12}>
        <ElectivesCartCard {...props} />
      </Grid>
    </Grid>
  );
}
