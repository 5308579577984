import { Box, Divider, Grid } from '@mui/material';
import {
  CardSkeleton,
  CardHeaderSkeleton,
  CardContentSkeleton,
  TasklistSkeleton,
  CardActionsSkeleton,
  ButtonSkeleton,
} from '.';
import { Skeleton } from '@pennfoster/pfc-design-system';
export function PrimaryCardSkeleton() {
  return (
    <CardSkeleton>
      <CardHeaderSkeleton status />
      <Divider />
      <CardContentSkeleton>
        <Grid container rowSpacing={3}>
          <Grid item xs={12}>
            <Skeleton variant={'rounded'} width={'100%'} height={32} />
          </Grid>
          <Grid item xs={12}>
            <Box display={'flex'} flexWrap={'wrap'}>
              <TasklistSkeleton taskCount={4} width={'60%'} rowGap={0.5} />
            </Box>
          </Grid>
        </Grid>
      </CardContentSkeleton>
      <CardActionsSkeleton>
        <Box width={'100%'} display={'flex'} justifyContent={'end'}>
          <ButtonSkeleton width={'176px'} size={'large'} />
        </Box>
      </CardActionsSkeleton>
    </CardSkeleton>
  );
}
