import { Link } from 'react-router-dom';
import FeEvents from 'src/events/fe-events';

import { Button } from '@pennfoster/pfc-design-system';

import { ReactComponent as ChoosePathwaySvg } from '../../assets/svg/choose-electives.svg';
import { PathwayStatusValues } from 'src/types/degree-audit-term';
import { useElectivesRoutes } from 'src/hooks/electives';

const pathwaysCardProps = (
  pathwayStatus: PathwayStatusValues
) => {

  const { electivePathwayRoute }= useElectivesRoutes();
  const navigateUrl = electivePathwayRoute;

  return {
    media: <ChoosePathwaySvg />,
    title: `Choose Your ${
      pathwayStatus === PathwayStatusValues.ElectiveAndPathway
        ? 'Electives!'
        : 'Pathway!'
    }`,
    content: 'Tailor your program to your interests.',
    buttons: [
      <Button
        key="Select Pathways"
        sx={{ m: 1 }}
        size="small"
        variant="outlined"
        component={Link}
        to={navigateUrl}
        onClick={() =>
          FeEvents.trackNamedEvent({
            eventName: 'SelectPathwaysClickEvent',
          })
        }
      >
        Select{' '}
        {pathwayStatus === PathwayStatusValues.ElectiveAndPathway
          ? 'Elective'
          : 'Pathway'}
      </Button>,
    ],
  };
};

export { pathwaysCardProps };
