import { CardHeader } from '@mui/material';
import { Grid } from '@mui/material';
import { Skeleton } from '@pennfoster/pfc-design-system';
import { StatusChipSkeleton } from '.';

interface Props {
  status?: boolean;
}

interface SubheaderProps {
  status?: boolean;
}

function Subheader({ status = false }: SubheaderProps) {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={9}>
          <Skeleton variant={'rounded'} width={'100%'} height={32} />
        </Grid>
        {status ? (
          <Grid
            item
            xs={3}
            pl={2}
            pr={0}
            display={'flex'}
            alignItems={'center'}
          >
            <StatusChipSkeleton />
          </Grid>
        ) : null}
      </Grid>
    </>
  );
}

export function CardHeaderSkeleton({ status = false }: Props) {
  return (
    <CardHeader
      sx={{ pl: 0, pr: 0, pt: 2.5, pb: 1.5 }}
      subheader={<Subheader status={status} />}
    />
  );
}
