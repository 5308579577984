import { AppBar, IconButton, Toolbar, Typography } from '@mui/material';
import { Icon } from '@pennfoster/pfc-design-system';
import { useNavigate } from 'react-router-dom';
import routemap from 'src/routes/routemap';

export function AdminHeader() {
  const navigate = useNavigate();
  return (
    <AppBar position={'static'}>
      <Toolbar>
        <Typography variant={'h4'} component="div" sx={{ flexGrow: 1 }}>
          Admin
        </Typography>
        <IconButton
          onClick={() => {
            navigate(routemap.ADMIN);
          }}
        >
          <Icon.RemoveRedEye />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
}
