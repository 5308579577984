import { AxiosResponse } from 'axios';
import {
  PaymentMethod,
  Payment,
  UpdatePaymentMethodDto,
} from 'src/types/payments';

import { instance } from './apiConfig';

export function getPaymentMethodsDefaultBackup(): Promise<
  AxiosResponse<PaymentMethod[]>
> {
  const response = instance({
    method: 'GET',
    url: `/user/paymentmethods/defaultbackup`,
  });
  return response;
}

export function getPaymentMethods(): Promise<AxiosResponse<PaymentMethod[]>> {
  const response = instance({
    method: 'GET',
    url: `/user/paymentmethods`,
  });
  return response;
}

export function makeDefault(id: number): Promise<AxiosResponse<PaymentMethod>> {
  return instance({
    method: 'POST',
    url: `/user/paymentmethods/${id}/makedefault`,
  });
}

export function makeBackup(id: number): Promise<AxiosResponse<PaymentMethod>> {
  return instance({
    method: 'POST',
    url: `/user/paymentmethods/${id}/makebackup`,
  });
}

export function addPaymentMethod(
  data: UpdatePaymentMethodDto
): Promise<AxiosResponse<Payment>> {
  return instance({
    method: 'POST',
    url: `/user/paymentmethods`,
    data: data,
  });
}

export function updatePaymentMethod(
  id: number,
  data: UpdatePaymentMethodDto
): Promise<AxiosResponse<PaymentMethod>> {
  return instance({
    method: 'PATCH',
    url: `/user/paymentmethods/${id}`,
    data: data,
  });
}
