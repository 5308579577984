import React, { MouseEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getInitials } from 'src/helpers';
import { AUTH_LOGOUT, PROFILE } from 'src/routes/routemap';

import ProfileIcon from '@mui/icons-material/PersonOutline';
import LogOutIcon from '@mui/icons-material/PowerSettingsNew';
import { Box, Divider, Menu, MenuItem, Typography, useTheme } from '@mui/material';
import { IconButton } from '@pennfoster/pfc-design-system';

import { AccountInfoMenuItem } from './account-info-menu-item';
import { MyProgramsMenuItem } from './my-programs-menu-item';
import { cms } from 'src/helpers/language';

interface Props {
  preferredName?: string;
  firstName: string;
  lastName: string;
}

export function AccountSettingsMenuList(props: Props) {
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);
  const [menuAnchor, setMenuAnchor] = useState<Element | null>(null);
  const theme = useTheme();
  const content = cms.content.general.menu;

  const toggleMenu = (e: MouseEvent) => {
    if (menuOpen) {
      setMenuAnchor(null);
    } else {
      setMenuAnchor(e.target as Element);
    }
    setMenuOpen(!menuOpen);
  };

  const handleClose = (e: MouseEvent) => {
    toggleMenu(e);
  };

  const navigateLogout = (e: MouseEvent) => {
    toggleMenu(e);
    navigate(AUTH_LOGOUT);
  };

  const navigateMyProfile = (e: MouseEvent) => {
    toggleMenu(e);
    navigate(PROFILE);
  };

  return (
    <>
      <Box onClick={toggleMenu}>
        <IconButton
          aria-label="Account Settings"
          onClick={toggleMenu}
          color="inherit"
          icon={
            <Box
              sx={{
                backgroundColor: theme.palette.text.light,
                borderRadius: '50%',
                color: theme.palette.neutral[60],
                fontSize: '32px',
                height: '40px',
                padding: '6px',
                width: '40px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Typography variant="subtitle1" sx={{ color: theme.palette.neutral[60], marginTop: '3px' }}>
                {getInitials(props.preferredName || props.firstName, props.lastName)}
              </Typography>
            </Box>
          }
          pfVariant="standard"
          sx={{
            // TODO: The token will need to be updated for this one: 'theme.palette.text.light' when these become available in the Design system.
            backgroundColor: 'rgba(244, 244, 245, 1)',
            borderRadius: '50%',
            color: theme.palette.neutral[60],
            fontSize: '32px',
            height: '40px',
            padding: '6px',
            width: '40px',
          }}
        />
      </Box>
      <Menu
        open={menuOpen}
        anchorEl={menuAnchor}
        onClose={handleClose}
        sx={{
          mt: 1,
        }}
        slotProps={{
          paper: {
            sx: {
              borderRadius: 0,
              width: 375,
            },
          },
        }}
        MenuListProps={{
          sx: { paddingY: 2 },
        }}
      >
        <AccountInfoMenuItem {...props} />
        <Box sx={{ mt: '16px' }}>
          <MyProgramsMenuItem />
        </Box>
        <Box padding={2}>
          <Divider aria-hidden="true" />
        </Box>
        <MenuItem onClick={navigateMyProfile}>
          <ProfileIcon sx={{ fontSize: 24, mr: 2 }} />
          <Typography variant="body1">{content.my_profile_label}</Typography>
        </MenuItem>
        <Box padding={2}>
          <Divider aria-hidden="true" />
        </Box>
        <MenuItem onClick={navigateLogout}>
          <LogOutIcon sx={{ fontSize: 24, mr: 2 }} />
          <Typography variant="body1">{content.logout_label}</Typography>
        </MenuItem>
      </Menu>
    </>
  );
}
