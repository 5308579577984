import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';

export default function ChooseProgramSkeleton() {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Skeleton variant="rectangular" sx={{ width: '30%', mb: 1 }} />
        <Skeleton height={50}></Skeleton>
      </Grid>
    </Grid>
  );
}
