import { Box, CardActions, CardContent, Divider, Grid, TextField, Typography } from '@mui/material';
import { Alert, Button, Card } from '@pennfoster/pfc-design-system';
import { useState } from 'react';
import { useMutation } from 'react-query';
import { updateUsername } from 'src/api/user';
import { AxiosError } from 'axios';
import { getErrorMessage } from 'src/helpers/handling-errors';
import ReactJson from 'react-json-view';
import { usernameChangeAtom, initialUsernameChangeState } from 'src/state';
import { useAtom } from 'jotai';
import { usernameChange } from 'src/types/username-change';
import DialogContainer from 'src/components/dialog-container';
import ProcessingState from 'src/components/processing-state';

export function AdminUsernameChange() {
  const [userAccounts, setUserAccounts] = useState<null | { studentNumber: string; username: string }[]>(null);
  const [errors, setErrors] = useState<null | string>(null);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [usernameChange, setUsernameChange] = useAtom(usernameChangeAtom);

  const handleChange = (key: keyof usernameChange, value: string) => {
    setUserAccounts(null);
    setUsernameChange((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const updateUsernameMutation = useMutation({
    mutationFn: (data: usernameChange) => updateUsername(data),
    onSettled: (data, error) => {
      if (error) {
        const errorMessage = getErrorMessage(error as AxiosError);
        setErrors(errorMessage);
      } else {
        setUsernameChange(initialUsernameChangeState);
        setUserAccounts([
          {
            studentNumber: usernameChange.studentNumber,
            username: usernameChange.desiredUsername,
          },
        ]);
      }
      setConfirmDialogOpen(false)
    },
  });

  const handleSubmit = async () => {
    updateUsernameMutation.mutate(usernameChange);
  };

  const resetForm = async () => {
    setErrors(null);
    setUserAccounts(null);
    setUsernameChange(initialUsernameChangeState);
  };

  return (
    <Box p={3}>
      {errors && (
        <Grid item xs={12}>
          <Alert severity="error" pfVariant="standard">
            {errors}
          </Alert>
        </Grid>
      )}
      <Grid container>
        <Card sx={{ pl: 3, pr: 3, pb: 3, maxWidth: 448 }}>
          <Box
            sx={{ pt: 3, pb: 2, pl: 0, pr: 0 }}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <Typography component={'span'} variant={'subtitle1'}>
              {' '}
              Username Change{' '}
            </Typography>
          </Box>
          <Divider />
          <CardContent
            sx={{
              pt: 2,
              pb: 0,
              pl: 0,
              pr: 0,
              display: 'flex',
              flexWrap: 'wrap',
              rowGap: 1,
            }}
          >
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TextField
                  sx={{ pb: 2 }}
                  fullWidth
                  id="requested-email"
                  label="Student Number"
                  variant="outlined"
                  onChange={(e) => handleChange('studentNumber', e.target.value)}
                  value={usernameChange.studentNumber}
                />
                <TextField
                  fullWidth
                  id="student-number-a"
                  label="Desired Sign in Name"
                  variant="outlined"
                  onChange={(e) => handleChange('desiredUsername', e.target.value)}
                  value={usernameChange.desiredUsername}
                />
              </Grid>
            </Grid>
          </CardContent>
          <CardActions sx={{ display: 'flex', mt: 1, flexWrap: 'wrap' }}>
            <Button pfVariant={'filled'} onClick={resetForm}>
              Reset Form{' '}
            </Button>
            <Button pfVariant={'filled'} onClick={() => setConfirmDialogOpen(true)}>
              Submit{' '}
            </Button>
          </CardActions>
          {userAccounts && (
            <Box mt={3} rowGap={2} display={'flex'} flexWrap={'wrap'} width={'100%'} flexDirection={'column'}>
              <Typography variant="subtitle1">Successfully submitted</Typography>
              <Divider sx={{ width: '100%' }} />
              {userAccounts.map((user: any, index: number) => (
                <ReactJson key={index} src={user!} name={`user ${index + 1}`} collapsed={false} />
              ))}
            </Box>
          )}
        </Card>
      </Grid>
      <DialogContainer
        open={confirmDialogOpen}
        handleClose={() => setConfirmDialogOpen(false)}
      >
        <Box>Confirm Changes:</Box>
        <Box>STUDENT NUMBER : <strong>{usernameChange.studentNumber}</strong></Box>
        <Box sx={{ mb: 2 }}>USERNAME : <strong>{usernameChange.desiredUsername}</strong></Box>
        {updateUsernameMutation.isLoading && <ProcessingState>Processing</ProcessingState>}
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button onClick={() => setConfirmDialogOpen(false)} label="Cancel" pfVariant="text" />
          <Button onClick={handleSubmit} label="Confirm" pfVariant="text" />
        </Box>
      </DialogContainer>
    </Box>
  );
}
