import { AcademicStatus } from '../types/enrollment';
import { ProgramStatus } from '../types/program-status';

export const getAcademicUXStatus = (
  academicStatus: AcademicStatus
): ProgramStatus => {
  const code = academicStatus?.code.trim();
  let uxStatus: ProgramStatus;

  switch (code) {
    case 'ENR':
    case 'ACT':
    case 'ACADPROB':
    case 'ARB':
    case 'COM':
      uxStatus = ProgramStatus.Active;
      break;
    case 'ARNM':
    case 'ACADCMP':
    case 'DRNM':
      uxStatus = ProgramStatus.RequirementsNotMet;
      break;
    case 'GRAD':
      uxStatus = ProgramStatus.Graduated;
      break;
    case 'DCSD':
    case 'ACADDSSM':
    case 'ACADHOLD':
    case 'DISMISS':
    case 'EXP':
    case 'MAXOUT':
    case 'WDR':
      uxStatus = ProgramStatus.Inactive;
      break;
    default:
      uxStatus = ProgramStatus.Enrolled;
      break;
  }

  return uxStatus;
};
