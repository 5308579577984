import React, { ReactNode } from 'react';

import { Box, CircularProgress, useTheme } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';

import { LabelMedium } from '../../styles';

interface IProcessingStateProps {
  children: ReactNode;
  circularProgress: boolean;
}

export default function ProcessingState(props: IProcessingStateProps) {
  const theme = useTheme();

  return (
    <Box
      sx={{
        alignContent: 'center',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '100%',
      }}
    >
      {props.circularProgress ? (
        <Box
          sx={{
            display: 'inline-flex',
            height: '36px',
            position: 'relative',
            width: '36px',
          }}
        >
          <CircularProgress
            variant="determinate"
            sx={{
              color:
                theme.palette.grey[theme.palette.mode === 'light' ? 300 : 800],
            }}
            size={36}
            thickness={4}
            value={100}
          />
          <CircularProgress
            sx={{
              color: 'info',
              left: 0,
              position: 'absolute',
            }}
            size={36}
            thickness={4}
          />
        </Box>
      ) : (
        <Box sx={{ width: '100%' }}>
          <LinearProgress />
        </Box>
      )}
      <LabelMedium
        sx={{
          mt: theme.spacing(3),
          textAlign: 'center',
        }}
      >
        {props.children}
      </LabelMedium>
    </Box>
  );
}

ProcessingState.defaultProps = {
  circularProgress: true,
};
