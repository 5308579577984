import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { Icon } from '@pennfoster/pfc-design-system';
import { ReactComponent as DefaultCardIcon } from 'src/assets/svg/default-card.svg';
import { PaymentMethod } from 'src/types/payments';
import { PaymentMethodSelection } from 'src/types/payments';
import PaymentMethodsChip from 'src/components/payment-methods-chip';
import MasterCardSvg from 'src/assets/svg/mastercard.svg';
import AmericanExpressSvg from 'src/assets/svg/americanexpress.svg';
import VisaSvg from 'src/assets/svg/visa.svg';
import DiscoverSvg from 'src/assets/svg/discover.svg';
import { bankType } from 'src/constants/bankType';

interface CreditCardItemProps {
  paymentMethod: PaymentMethod;
}

export default function CreditCardItem({ paymentMethod }: CreditCardItemProps) {
  const theme = useTheme();
  let cardSelection: PaymentMethodSelection;
  if (paymentMethod.isDefault) {
    cardSelection = PaymentMethodSelection.default;
  } else {
    cardSelection = PaymentMethodSelection.backup;
  }

  let cardImage;
  let brandName;
  const customSvgStyle = {
    width: '40px',
    border: 0,
  };
  switch (paymentMethod.brand?.toLowerCase()) {
    case 'amex':
      cardImage = <img alt="American Express" src={AmericanExpressSvg} style={customSvgStyle} />;
      brandName = 'American Express';
      break;
    case 'visa':
      cardImage = <img alt="Visa" src={VisaSvg} style={customSvgStyle} />;
      brandName = 'Visa';
      break;
    case 'mastercard':
      cardImage = <img alt="Mastercard" src={MasterCardSvg} style={customSvgStyle} />;
      brandName = 'Mastercard';
      break;
    case 'discover':
      cardImage = <img alt="Discover" src={DiscoverSvg} style={customSvgStyle} />;
      brandName = 'Discover';
      break;
    default:
      cardImage = <DefaultCardIcon />;
  }

  return (
    <Box
      sx={{
        flexDirection: 'row',
        display: 'flex',
        height: 'auto',
        justifyContent: 'space-between',
        alignItems: 'center',
        pt: 2,
        pb: 2,
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <>
          {paymentMethod?.type === bankType ? (
            <>
              <Icon.AccountBalance />
            </>
          ) : (
            cardImage || (
              <Box
                sx={{
                  backgroundColor: '#5B6269',
                  width: '40px',
                }}
              ></Box>
            )
          )}
        </>
        <Typography
          variant="caption"
          noWrap
          sx={{
            fontFamily: 'Nunito Sans',
            fontSize: '16px',
            fontWeight: 400,
            lineHeight: '24px',
            letterSpacing: '0em',
            marginX: theme.spacing(4),
            textAlign: 'left',
          }}
        >
          {paymentMethod?.type !== bankType ? (
            <>
              {brandName} ...{paymentMethod?.lastFour}
            </>
          ) : (
            <Box sx={{ marginLeft: '16px' }}>
              {paymentMethod?.bankName} ...{paymentMethod?.lastFour}
            </Box>
          )}
        </Typography>
      </Box>
      <PaymentMethodsChip params={cardSelection} />
    </Box>
  );
}
