import React, { useEffect } from 'react';
import svg from 'src/assets/svg/error-models/there-was-an-error.svg';
import { AUTH_LOGOUT } from 'src/routes/routemap';

import ErrorModal from './error-modal';
import { useNavigate } from 'react-router-dom';
import { AuthError } from 'src/types/auth';
import { cms } from 'src/helpers/language';

interface Props {
  err: AuthError;
}

export default function AuthErrorModal({ err }: Props) {
  let errorDesc = cleanDescription(err);
  const navigate = useNavigate();

  const headline = getHeadlineFromDescription(errorDesc);
  errorDesc = removeHeaderFromDesc(errorDesc);

  const body = errorDesc;

  const buttonOptions = {
    text: cms.content.errors.modal.back_button,
    onClick: () => navigate(AUTH_LOGOUT),
  };

  useEffect(() => {
    if (err) {
      console.error(
        `type: ${err.type}
description: ${err.description}`
      );
    }
  });

  return <ErrorModal headline={headline} body={body} svg={svg} secondaryButtonOpts={buttonOptions} />;
}

function cleanDescription(error: AuthError) {
  const fallbackText = cms.content.errors.unknown_login_error.details;

  let errorDescPrefix;
  try {
    errorDescPrefix = error.description.match(/.+:/)![0];
  } catch (e) {
    console.error(e);
  }
  let errorDesc = errorDescPrefix ? error.description.replace(errorDescPrefix, '') : fallbackText;
  errorDesc = removeCorrelationId(errorDesc);
  errorDesc = removeTimestamp(errorDesc);
  return errorDesc;
}

function getHeadlineFromDescription(errorDesc: string) {
  const headlineFallback = cms.content.errors.modal.header;
  const splitDesc = errorDesc.split('|');
  let header = headlineFallback;
  if (splitDesc.length > 1) {
    header = splitDesc[0];
    header.replace(/\s^/, '');
  }
  return header;
}

function removeCorrelationId(errorDesc: string) {
  const regex = /Correlation ID:.+\s/;
  let CorrelationIdString;
  try {
    CorrelationIdString = errorDesc.match(regex)![0];
  } catch (e) {
    console.error(e);
  }
  const newErrorDesc = CorrelationIdString ? errorDesc.replace(CorrelationIdString, '') : errorDesc;
  return newErrorDesc;
}

function removeTimestamp(errorDesc: string) {
  const regex = /Timestamp:.+/;
  let TimestampString;
  try {
    TimestampString = errorDesc.match(regex)![0];
  } catch (e) {
    console.error(e);
  }
  const newErrorDesc = TimestampString ? errorDesc.replace(TimestampString, '') : errorDesc;
  return newErrorDesc;
}

function removeHeaderFromDesc(errorDesc: string) {
  const regex = /.+\|\s?/;
  let header;
  try {
    header = errorDesc.match(regex)![0];
  } catch (e) {
    console.error(e);
  }
  const newErrorDesc = header ? errorDesc.replace(header, '') : errorDesc;
  return newErrorDesc;
}
