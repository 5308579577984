import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import { StatusChipSkeleton } from 'src/components/skeleton-components/status-chip-skeleton';

export default function ChoosePaymentMethodSkeleton() {
  return (
    <Grid container>
      <Grid item xs={9}>
        <Skeleton variant={'rounded'} width={'50%'} height={32} />
      </Grid>
      <Grid item xs={3} pl={2} pr={0} display={'flex'} alignItems={'center'}>
        <StatusChipSkeleton />
      </Grid>
      <Grid item xs={12}>
        <Skeleton height={50} width={'50%'}></Skeleton>
      </Grid>
    </Grid>
  );
}
