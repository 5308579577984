import { Box } from '@mui/material';

const engenTooltipText = (
  <Box sx={{ fontSize: '16px' }}>
    <ul>
      <li>
        La primera vez que inicies sesión en EnGen, se te pedirá que realices la
        Evaluación de Competencia (PA), que evaluará tus habilidades actuales en
        inglés.
      </li>
      <li>
        La plataforma EnGen te ayudará a mejorar tu inglés antes de comenzar tus
        cursos de secundaria con Penn Foster.
      </li>
      <li>
        Una vez que obtengas un nivel intermedio alto en tu Evaluación de
        Competencia (PA), se abrirá tu próximo curso en Penn Foster.
      </li>
      <li>
        Incluso una vez que comiences tus cursos de secundaria, podrás seguir
        accediendo a EnGen durante 12 meses (a partir de la fecha en que
        comiences en EnGen) para practicar tus habilidades en inglés.
      </li>
    </ul>
  </Box>
);

export default engenTooltipText;
