import { Button, Card, Typography } from '@pennfoster/pfc-design-system';
import { CardContent, CardHeader, Divider, Grid, useTheme } from '@mui/material';
import { Body1, HeadingH6, LabelLarge, Subtitle1 } from 'src/styles';
import { useContext } from 'react';
import EnrollmentContext from 'src/context/enrollment';
import { getEolUrl } from 'src/api/enrollments';

export default function EnrollNextSemesterCard({
  setIsEligibleToReEnroll,
}: {
  setIsEligibleToReEnroll: (value: boolean | ((prevVar: boolean) => boolean)) => void;
}) {
  const theme = useTheme();
  const { enrollment } = useContext(EnrollmentContext);

  const handleEnrollNextSemester = () => {
    const enrollmentId = enrollment?.enrollmentId;
    setIsEligibleToReEnroll(true);
    getEolUrl(enrollmentId)
      .then((res) => {
        window.location.href = res.data;
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <Card>
      <CardHeader
        title={<Subtitle1>Required Task</Subtitle1>}
        sx={{
          paddingBottom: 0,
        }}
      />
      <CardContent>
        <Divider aria-hidden="true" />
        <Typography />
        <Grid container>
          <Grid item xs={12}>
            <HeadingH6
              sx={{
                pt: 3,
              }}
              color={theme.palette.secondary.main}
            >
              It&apos;s Time To Enroll In Your Next Semester.
            </HeadingH6>
            <Body1
              sx={{
                pt: 3,
              }}
            >
              Congrats! Your hard work is paying off. You’re nearly complete with this semester. It’s time to start
              thinking about the next step. Enroll in the next semester to continue your learning.
            </Body1>
          </Grid>
        </Grid>
        <Grid item textAlign={'end'}></Grid>
      </CardContent>
      <Grid
        sx={{
          pb: 3,
          pr: 2,
        }}
        item
        xs={12}
        textAlign={'right'}
      >
        <Button pfVariant="filled" onClick={handleEnrollNextSemester}>
          <LabelLarge fontFamily={'NunitoSans24'}>ENROLL IN NEXT SEMESTER</LabelLarge>
        </Button>
      </Grid>
    </Card>
  );
}
