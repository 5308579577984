import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { Button, Dialog, Typography } from '@pennfoster/pfc-design-system';
import StyledAlert from 'src/components/styled-alert';

export interface ElectivesSubmissionModalProps {
  isOpen: boolean;
  selectedCourseNames: string[];
  onCancelHandler: () => void;
  onConfirmHandler: () => void;
}

// TODO: Turn this into a generic confirmation model and consider moving it to
// the Design System repository. Mick Piereder 2023.06.14
export default function ElectivesSubmissionModal({
  isOpen,
  selectedCourseNames,
  onCancelHandler,
  onConfirmHandler,
}: ElectivesSubmissionModalProps) {
  return (
    <Dialog
      title={'Confirm Electives'}
      open={isOpen}
      PaperProps={{ sx: { borderRadius: '0' } }}
    >
      <DialogTitle sx={{ pt: 0, pl: 0, pr: 0, mb: 2 }}>
        <Typography variant="subtitle1">Confirm Electives</Typography>
      </DialogTitle>
      <DialogContent sx={{ padding: 0, mb: 1 }}>
        {selectedCourseNames.map((name, i) => (
          <Typography sx={{ padding: 0, mb: 4 }} key={`${i}-${name}-selected`}>
            {name}
          </Typography>
        ))}
      </DialogContent>
      <DialogContent sx={{ padding: 0 }}>
        <StyledAlert severity="info">
          Please note that you will need to contact our support team if you need
          to make changes after submitting.
        </StyledAlert>
      </DialogContent>
      <DialogActions sx={{ marginTop: 2, padding: 0 }}>
        <Button pfVariant="text" onClick={onCancelHandler}>
          Cancel
        </Button>
        <Button pfVariant="filled" onClick={onConfirmHandler}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
