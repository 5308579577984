import { Body2, Subtitle1 } from 'src/styles';

import { Box } from '@mui/material';
import { MutationStatus } from 'react-query';

interface Props {
  mutationStatus: MutationStatus;
  nNeededElectives: number;
  nSelectedElectives: number;
}

export default function ElectivesCartCardHeader({
  mutationStatus,
  nNeededElectives,
  nSelectedElectives,
}: Props) {
  return (
    <Box display="flex" justifyContent="space-between" sx={{ pb: '16px' }}>
      <Subtitle1 display="inline">My Electives</Subtitle1>
      {nNeededElectives > 0 && mutationStatus !== 'success' && (
        <Body2 display="inline">
          {nSelectedElectives}/{nNeededElectives}
        </Body2>
      )}
    </Box>
  );
}
