import React, { useState } from 'react';
import { Box, Select, SelectProps, styled } from '@mui/material';
import { Icon } from '@pennfoster/pfc-design-system';

export default function TermSelect({ children, ...props }: SelectProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleBoxOpen = () => setIsOpen(true);
  const handleBoxClose = () => setIsOpen(false);

  const StyledSelect = styled(Select)(() => ({
    zIndex: 2,
    svg: { display: 'none' },
    fieldset: { border: 0 },
    '.MuiSelect-select': {
      padding: 0,
      paddingRight: '32px !important',
    },
  }));

  return (
    <Box display={'inline-flex'} alignItems={'center'}>
      <StyledSelect
        {...props}
        onOpen={handleBoxOpen}
        onClose={handleBoxClose}
        open={isOpen}
      >
        {children}
      </StyledSelect>
      <Icon.ExpandMore
        sx={{
          transition: 'transform .125s ease',
          transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
          marginLeft: '-24px',
          zIndex: 1,
        }}
      />
    </Box>
  );
}
