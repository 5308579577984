import React from 'react';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
} from '@mui/material';
import { LabelMedium } from 'src/styles';
import { Button, Dialog } from '@pennfoster/pfc-design-system';
import { Noop, SubmitHandler } from 'react-hook-form';
import { IFormInput } from '.';
import LoadingComponent from 'src/components/loading-component';

interface FormDialogsProps {
  dialogOpen: boolean;
  cancelDialogOpen: boolean;
  isLoading: boolean;
  setDialogOpen: (value: boolean) => void;
  setCancelDialogOpen: (value: boolean) => void;
  handleSubmit: (onSubmit: SubmitHandler<IFormInput>) => object;
  onSubmit: SubmitHandler<IFormInput>;
  handleCancel: Noop;
}

export default function FormDialogs({
  dialogOpen,
  cancelDialogOpen,
  isLoading,
  setDialogOpen,
  setCancelDialogOpen,
  handleSubmit,
  onSubmit,
  handleCancel,
}: FormDialogsProps) {
  return (
    <>
      <Dialog open={dialogOpen}>
        {isLoading ? (
          <DialogContent sx={{ padding: 0 }}>
            <LoadingComponent fullPage={false} label={'Loading'} />
          </DialogContent>
        ) : (
          <>
            <DialogTitle
              sx={{
                paddingTop: 0,
                paddingLeft: 0,
                paddingRight: 0,
              }}
            >
              <Typography variant="body1">Confirm Changes</Typography>
              <Divider sx={{ marginTop: 2, marginBottom: 0 }} />
            </DialogTitle>
            <DialogContent sx={{ padding: 0 }}>
              <LabelMedium>
                Please confirm that you would like to make changes to your
                profile.
              </LabelMedium>
            </DialogContent>
            <DialogActions sx={{ marginTop: 2, padding: 0 }}>
              <Button
                label="Cancel"
                pfVariant="text"
                onClick={() => {
                  setDialogOpen(false);
                }}
              />
              <Button
                label="Confirm"
                onClick={handleSubmit(onSubmit)}
                pfVariant="filled"
                type="submit"
                size="small"
              />
            </DialogActions>
          </>
        )}
      </Dialog>
      <Dialog open={cancelDialogOpen} sx={{ margin: 0 }}>
        <DialogTitle
          sx={{
            paddingTop: 0,
            paddingLeft: 0,
            paddingRight: 0,
          }}
        >
          <Typography variant="body1">Are You Sure?</Typography>
          <Divider sx={{ marginTop: 2, marginBottom: 0 }} />
        </DialogTitle>
        <DialogContent sx={{ padding: 0 }}>
          <LabelMedium>
            If you cancel now, you will lose all of the information you&apos;ve
            entered.
          </LabelMedium>
        </DialogContent>
        <DialogActions sx={{ marginTop: 2, marginBottom: 0, padding: 0 }}>
          <Button
            label="Back"
            size="large"
            pfVariant="text"
            onClick={() => {
              setCancelDialogOpen(false);
            }}
          />
          <Button
            label="I'm Sure"
            pfVariant="filled"
            onClick={handleCancel}
            size="large"
          />
        </DialogActions>
      </Dialog>
    </>
  );
}
