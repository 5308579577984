import { createContext } from 'react';
import { noop } from 'src/helpers/noop';

import { IPaymentMethods } from '../../types/payments';

export interface PaymentMethodsState {
  errorsFromAPI: string;
  setErrorsFromAPI: (errorsFromAPI: string) => void;
  refreshPaymentMethods: boolean;
  setRefreshPaymentMethods: (refreshPaymentMethods: boolean) => void;
  paymentMethodSelected: IPaymentMethods | null;
  setPaymentMethodSelected: (paymentMethodSelected: IPaymentMethods) => void;
}

const PaymentMethodContext = createContext<PaymentMethodsState>({
  errorsFromAPI: '',
  setErrorsFromAPI: noop,
  refreshPaymentMethods: false,
  setRefreshPaymentMethods: noop,
  paymentMethodSelected: null,
  setPaymentMethodSelected: noop,
});

export default PaymentMethodContext;
