import { Grid, Skeleton as MuiSkeleton } from '@mui/material';
import { styled } from '@mui/system';
import { ExpansionItem } from '@pennfoster/pfc-design-system/molecules/expansion-panel';

const Skeleton = styled(MuiSkeleton)(({ theme }) => ({
  height: 56,
  margin: theme.spacing(1),
  '&:last-child': {
    borderRadius: '0 0 16px 16px',
  },
}));

interface Props {
  count?: number;
}

export default function SkeletonLoaders({ count = 4 }: Props) {
  return (
    <>
      {Array.from({ length: count }, (_, i) => (
        <ExpansionItem key={i}>
          <Grid>
            <Grid item>
              <Skeleton variant="rectangular" />
            </Grid>
          </Grid>
        </ExpansionItem>
      ))}
    </>
  );
}
