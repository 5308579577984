import { Box, Grid } from '@mui/material';
import {
  CardSkeleton,
  CardHeaderSkeleton,
  CardContentSkeleton,
  CardActionsSkeleton,
  ButtonSkeleton,
  UpcomingCoursesItemSkeleton,
} from '.';
import { Skeleton } from '@pennfoster/pfc-design-system';

interface Props {
  coursesCount?: number;
  action?: boolean;
  divider?: boolean;
  status?: boolean;
}

export function UpcomingCoursesCardSkeleton({
  coursesCount = 2,
  action = true,
  divider = true,
  status = true,
}: Props) {
  return (
    <CardSkeleton>
      <CardHeaderSkeleton status={status} />
      <CardContentSkeleton>
        <Grid container>
          <Grid item xs={12}>
            <Skeleton variant={'rounded'} width={'100%'} height={56} />
          </Grid>
          <Grid item xs={12}>
            <Box display={'flex'} flexWrap={'wrap'}>
              {[...Array(coursesCount)].map((e, i) => (
                <UpcomingCoursesItemSkeleton key={i} divider={divider} />
              ))}
            </Box>
          </Grid>
        </Grid>
      </CardContentSkeleton>
      {action && (
        <CardActionsSkeleton>
          <Box width={'100%'} display={'flex'} justifyContent={'end'}>
            <ButtonSkeleton width={'176px'} size={'large'} />
          </Box>
        </CardActionsSkeleton>
      )}
    </CardSkeleton>
  );
}
