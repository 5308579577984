import { Body1, HeadingH6 } from 'src/styles';
import { Grid } from '@mui/material';
import { PathwayStatusValues } from 'src/types/degree-audit-term';

interface PathwayHeaderProps {
  pathwayStatus: PathwayStatusValues;
}

export default function PathwayHeader({ pathwayStatus }: PathwayHeaderProps) {
  return (
    <Grid item xs={12} md={7} sx={{ alignContent: 'flex-start', mt: 3 }}>
      <HeadingH6 variant="h1">
        {pathwayStatus.trim() === PathwayStatusValues.ElectiveAndPathway
          ? 'Personalize Your High School Experience'
          : 'Choose your Pathway'}
      </HeadingH6>
      {pathwayStatus !== PathwayStatusValues.ElectiveAndPathway ? (
        <Body1 variant={'caption'} sx={{ top: 2 }}>
          Customize your education by choosing a pathway that aligns with your
          interests. For more information to assist you in making your
          selection, please refer to your Program Outline. You can find this
          document in the Help & Support panel (look for the Question Mark icon
          above) under Documents.
        </Body1>
      ) : (
        <Body1 variant={'caption'}>
          Customize your education by selecting elective courses that align to
          your interests. Electives are organized into three pathways types:
          General, College Prep, and Career. Add <b>General</b> to choose from a
          broad list of courses. Add <b>College Prep</b> to choose from a list
          of courses many colleges require. Or add a <b>Career</b> Pathway to
          take 5 pre-selected electives to prepare for a new career.
        </Body1>
      )}
    </Grid>
  );
}
