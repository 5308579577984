import React from 'react';
import { Box, CardActionArea, useTheme } from '@mui/material';
import { dateFormat } from 'src/helpers/format';
import { Body1, Caption, Subtitle1 } from 'src/styles';
import { InboxMessage } from 'src/types/message';
import { Badge } from '@pennfoster/pfc-design-system';

interface MessageItemProps {
  message: InboxMessage;
  handleMessageClick: (message: InboxMessage) => void;
}

export default function MessageItem({
  message,
  handleMessageClick,
}: MessageItemProps) {
  const theme = useTheme();

  return (
    <CardActionArea
      sx={{
        '& .MuiCardActionArea-focusHighlight': {
          backgroundColor: 'transparent',
        },
        '&:hover .MuiCardActionArea-focusHighlight': {
          opacity: 0.2,
          backgroundColor: theme.palette.secondary.light,
        },
      }}
      onClick={() => handleMessageClick(message)}
    >
      <Box sx={{ p: 2, paddingLeft: 8, mr: 2, mt: theme.spacing(2) }}>
        <Box sx={{ bottom: theme.spacing(1), position: 'relative' }}>
          {!message.readStatus && (
            <Badge
              sx={{
                position: 'absolute',
                top: '50%',
                left: theme.spacing(-3),
                '& .MuiBadge-badge': {
                  width: '12px',
                  height: '12px',
                  borderRadius: '6px',
                },
              }}
              backgroundColor="primary.30"
              color="primary"
              pfVariant="dot"
            />
          )}
          <Subtitle1
            sx={{
              fontWeight: 700,
              color: message.readStatus
                ? theme.palette.text.secondary
                : 'primary.30',
            }}
          >
            {message.subject}
          </Subtitle1>
        </Box>
        <Body1
          sx={{
            fontWeight: message.readStatus ? 400 : 700,
            color: message.readStatus
              ? theme.palette.text.secondary
              : theme.palette.neutral[20],
          }}
        >
          {message.preview}
        </Body1>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Caption
            sx={{ color: theme.palette.text.secondary, mt: theme.spacing(1) }}
          >
            {dateFormat(message.createdDate)}
          </Caption>
        </Box>
      </Box>
    </CardActionArea>
  );
}
