/* eslint-disable react/react-in-jsx-scope */
import AuthErrorModal from 'src/components/error-modals/auth-error';
import { authErrorAtom } from 'src/state/auth';
import { useAtom } from 'jotai';

export default function AuthError() {
  const [authError, setAuthError] = useAtom(authErrorAtom);

  return <AuthErrorModal err={authError} />;
}
