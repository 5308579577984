import { createContext } from 'react';
import { DegreeAudit } from 'src/types/degree-audit';
import { DegreeAuditTerm } from 'src/types/degree-audit-term';

export interface DegreeAuditState {
  degreeAudit?: DegreeAudit;
  currentTerm?: DegreeAuditTerm;
}

const DegreeAuditContext = createContext<DegreeAuditState>({});

export default DegreeAuditContext;
