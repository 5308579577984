import { AxiosResponse } from 'axios';
import { Pathway } from 'src/types/pathway';

import { instance } from './apiConfig';

export function getPathways(
  enrollmentId: string
): Promise<AxiosResponse<Pathway[]>> {
  return instance({
    method: 'GET',
    url: `/user/enrollments/${enrollmentId}/pathways`,
  });
}

export function postPathway(
  enrollmentId: string,
  sisPathwayId: number
): Promise<AxiosResponse<void>> {
  return instance({
    method: 'POST',
    url: `/user/enrollments/${enrollmentId}/pathways`,
    data: { sisPathwayId: sisPathwayId },
  });
}
