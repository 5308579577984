import { AxiosResponse } from 'axios';
import { purifyAndParse } from '../helpers/html-parse';
import { instance } from './apiConfig';
import { InboxMessage, Message } from 'src/types/message';

const ROUTE = '/user/messagecenter/messages';

export function getMessages(): Promise<AxiosResponse<InboxMessage[]>> {
  return instance({
    method: 'GET',
    url: `${ROUTE}`,
  });
}

export function getMessagesByFilter(
  includeRead: boolean
): Promise<AxiosResponse<InboxMessage[]>> {
  return instance({
    method: 'GET',
    url: `${ROUTE}/?isRead=${includeRead}`,
  });
}

export async function getMessage(
  messageId: string
): Promise<AxiosResponse<Message>> {
  const response = await instance({
    method: 'GET',
    url: `${ROUTE}/${messageId}`,
  });

  if (response.status === 200) {
    return {
      ...response,
      data: {
        ...response.data,
        body: purifyAndParse(response.data.content),
      },
    };
  }
  return response;
}

export async function updateMessageAsRead(messageId: string) {
  const body = {
    messageIds: [messageId],
  };

  await instance({
    method: 'POST',
    url: `${ROUTE}/markasread`,
    data: body,
  });
}

export async function updateMessageAsUnread(messageId: string) {
  const body = {
    messageIds: [messageId],
  };
  await instance({
    method: 'POST',
    url: `${ROUTE}/markasunread`,
    data: body,
  });
}

export async function deleteMessage(messageId: string) {
  await instance({
    method: 'DELETE',
    url: `${ROUTE}/${messageId}`,
  });
}
