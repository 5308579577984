import { useContext } from 'react';
import { LoadingStateFailedModal } from 'src/components/error-modals';
import PathwaysView, { PathwayViewProps } from 'src/components/pathway';
import EnrollmentContext from 'src/context/enrollment';
import { usePathwaysQuery } from 'src/hooks/pathway';

import { ElectivesLoading } from '../electives/electives-loading';
import DegreeAuditContext from 'src/context/degree-audit';

export default function Pathways() {
  const enrollmentCtx = useContext(EnrollmentContext);
  const query = usePathwaysQuery();
  const { currentTerm } = useContext(DegreeAuditContext);

  const queriesStatus = [enrollmentCtx.queryStatus, query.status];

  if (queriesStatus.includes('loading')) {
    return <ElectivesLoading />;
  }

  if (queriesStatus.includes('error')) {
    return <LoadingStateFailedModal />;
  }

  if (!query.data?.data) {
    return <LoadingStateFailedModal />;
  }

  const props: PathwayViewProps = {
    pathways: query.data.data,
    enrollmentsRefetch: enrollmentCtx.refetch,
    pathwayStatus: currentTerm?.pathwayStatus,
  };

  return <PathwaysView {...props} />;
}
