import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@pennfoster/pfc-design-system/atoms/button';
import { ExpansionSummary } from '@pennfoster/pfc-design-system/molecules/expansion-panel';
import { ProgressStatus } from 'src/types/progress-status';
import ProgressChip from '../progress-chip';
import { PathwayStatusValues } from 'src/types/degree-audit-term';
import { useElectivesRoutes } from 'src/hooks/electives';
import { shortenToEllipsis } from 'src/utils/string-utils';
import { maxCourseNameLength } from '../program-expansion-panel/expansion-panel-with-items';
import { Typography } from '@pennfoster/pfc-design-system';

interface ElectivePlaceholderProps {
  index: number;
  showLSAPlaceHolders: boolean;
  electivePoolName: string;
  electivePoolId: number;
  termId?: number | null;
  pathwayStatus: string;
}

export default function ElectivePlaceholder(props: ElectivePlaceholderProps) {
  const navigate = useNavigate();

  const { electivePathwayRoute }= useElectivesRoutes();
  const isElectiveOrPathway = props.pathwayStatus !== PathwayStatusValues.None;
  const navigateUrl = electivePathwayRoute;

  const statusItem = props.showLSAPlaceHolders || !isElectiveOrPathway ? (
    <ProgressChip status={ProgressStatus.Locked} isStatusForLesson={false} />
  ) : (
    <Button
      sx={{ padding: 1, margin: 0 }}
      size="small"
      key={'button-' + props.index}
      pfVariant={'outlined'}
      label="Select"
      icon={false}
      onClick={() => navigate(navigateUrl)}
    />
  );

  return (
    <ExpansionSummary
      data-testid={'lesson-1' + props.index}
      details={[statusItem, '-', '-', '-']}
      sx={{ display: 'flex', alignContent: 'center' }}
    >
      <Typography variant="body2" sx={{ lineHeight: '24px' }} title={props.electivePoolName}>
        {shortenToEllipsis(props.electivePoolName, maxCourseNameLength)}
      </Typography>
    </ExpansionSummary>
  );
}
