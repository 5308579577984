import React, { useEffect, useState } from 'react';
import { getTheme } from 'src/api/themes';
import muiThemeJson from 'src/assets/defaultTheme.json';
import LoadingComponent from 'src/components/loading-component';
import AppRoutes from 'src/routes/routes';
import { DomainConfig } from 'src/types/configs';
import CacheBuster from 'react-cache-buster';
import packageJson from '../../package.json';

import { Box, CssBaseline, Theme, ThemeProvider } from '@mui/material';
import { getPaletteFromJson, makeTheme, elevationsLight } from '@pennfoster/pfc-design-system';
import { useAtom } from 'jotai';
import { userAtom } from 'src/state';

function App() {
  const [themeIsLoading, setThemeIsLoading] = useState<boolean>(false);
  const isNotDev = process.env.NODE_ENV !== 'development';
  const storedThemeString = sessionStorage.getItem('selectedTheme');
  const [user] = useAtom(userAtom)
  const themeObject = storedThemeString
    ? JSON.parse(storedThemeString as string)
    : JSON.parse(JSON.stringify(muiThemeJson));
  const defaultThemeOptions = makeTheme(themeObject.palette, elevationsLight);

  const [theme, setTheme] = useState<Theme>(defaultThemeOptions);

  const domainName = window.location.hostname;
  useEffect(() => {
    if (!storedThemeString && user) {
      getTheme(domainName).then((res) => {
        const palette = getPaletteFromJson(res.data);
        const newTheme = makeTheme(palette, elevationsLight);
        sessionStorage.setItem('selectedTheme', JSON.stringify(newTheme));
        setTheme(newTheme);
        setThemeIsLoading(false);
      });
      setThemeIsLoading(true);
    }
  }, [storedThemeString, user]);

  // Dynamic Favicon useEffect:
  useEffect(() => {
    const setFavicon = (iconUrl: string): void => {
      const link = (document.querySelector("link[rel*='icon']") as HTMLLinkElement) || document.createElement('link');
      link.type = 'image/x-icon';
      link.rel = 'shortcut icon';
      link.href = iconUrl;

      document.getElementsByTagName('head')[0].appendChild(link);
    };

    const domainConfigPath = '/json/domain-config.json';
    fetch(domainConfigPath)
      .then((resp) => resp.json())
      .then((data: DomainConfig) => {
        const favicon = data[domainName]?.favicon;
        const title = data[domainName]?.title;

        if (favicon) {
          setFavicon(`${window.location.origin}/favicons/${favicon}`);
        } else {
          const w = `No favicon specified for ${domainName} in ${domainConfigPath}; using default.`;
          console.warn(w);
        }
        if (title) {
          document.title = title;
        } else {
          const w = `No page title specified for ${domainName} in ${domainConfigPath}; using default.`;
          console.warn(w);
        }
      })
      .catch((error) => console.error('Error fetching favicons.json:', error));
  }, [location.hostname]);

  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Box
          className="App"
          bgcolor={(theme) => theme.palette.background.light}
          style={{ minHeight: '100vh' }}
        >
          <CacheBuster
            currentVersion={packageJson.version}
            isEnabled={isNotDev}
            loadingComponent={<LoadingComponent label={'Loading'} />} 
            metaFileDirectory={'.'}
            reloadOnDowngrade={true}
          >
            <AppRoutes />
          </CacheBuster>
        </Box>
      </ThemeProvider>
    </>
  );
}

export default App;
