import React from 'react';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { Subtitle1 } from 'src/styles';
import {
  currencyFormatWithDecimals,
  dateFormatMMddyyyy,
} from 'src/helpers/format';
import { ScheduledPayment } from 'src/types/payments';
import { getProgramNameByParams } from 'src/helpers/get-program-name';
import ChoosePaymentsSkeleton from './skeleton/choose-payments-skeleton';

interface ChoosePaymentsProps {
  scheduledPayments: ScheduledPayment[];
  selectedscheduledPayments: ScheduledPayment[];
  handleCheckboxChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    payment: ScheduledPayment
  ) => void;
}

export default function ChoosePayments({
  scheduledPayments,
  selectedscheduledPayments,
  handleCheckboxChange,
}: ChoosePaymentsProps) {
  return scheduledPayments?.length === 0 ? (
    <ChoosePaymentsSkeleton />
  ) : (
    <>
      <Subtitle1 variant="h2">Choose Payments</Subtitle1>
      <FormGroup>
        {scheduledPayments?.length > 0 &&
          scheduledPayments.map((payment: ScheduledPayment, index) => (
            <React.Fragment key={`${payment.sisPaymentScheduledId}-${index}`}>
              {index <= selectedscheduledPayments.length
                && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e) => handleCheckboxChange(e, payment)}
                        value={payment.sisPaymentScheduledId}
                        defaultChecked={index < selectedscheduledPayments.length}
                        disabled={
                          index === 0 ||
                          1 + index < selectedscheduledPayments.length
                        }
                      />
                    }
                    label={`${currencyFormatWithDecimals(
                      payment.amountDue
                    )} - ${getProgramNameByParams(
                      payment.programDisplayName,
                      payment.programName
                    )} - ${payment.type} - ${dateFormatMMddyyyy(
                      payment.dueDate.toString()
                    )}`}
                  />
                )}
            </React.Fragment>
          ))}
      </FormGroup>
    </>
  );
}
