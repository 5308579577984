import { PolicyType, ResponseState } from 'src/types/auth';
import PKCE from '../auth/challenge-code';
import { AUTH_HOST } from '../auth/auth-host';
import { consentCodeMapping } from './consent-code-mapping';
const config = process.env;

export const redirectToLogin = (responseState?: ResponseState, policyType?: PolicyType) => {
  const {
    REACT_APP_AUTH_TENANT,
    REACT_APP_AUTH_POLICY,
    REACT_APP_AUTH_CLIENT_ID,
    REACT_APP_RESET_POLICY,
    REACT_APP_FORGOT_SIGNIN,
    REACT_APP_HELP_CENTER_POLICY,
    REACT_APP_HELP_CENTER_REDIRECT,
    REACT_APP_HELP_CENTER_CLIENT_ID,
    REACT_APP_ADMIN_POLICY,
  } = config;

  responseState ? responseState : (responseState = { policyType: policyType || 'Login' });

  const incomingUrlParams = new URLSearchParams(location.search);
  const urlParams = new URLSearchParams();
  let env, redirectUrl, responseType, clientId, url;
  if (location.host.includes('dev') || location.host.includes('localhost')) env = 'dev';
  if (location.host.includes('stage')) env = 'test';
  if (location.host.includes('uat')) env = 'uat';
  

  let b2cPolicy = REACT_APP_AUTH_POLICY;
  responseState ? responseState : (responseState = { policyType: policyType || 'Login' });

  /* for password reset */
  if (policyType && policyType == 'ResetPassword') {
    b2cPolicy = REACT_APP_RESET_POLICY;
    urlParams.set('vc', incomingUrlParams.get('vc')!);
    urlParams.set('ul', incomingUrlParams.get('ul')!);
    urlParams.set('ru', incomingUrlParams.get('ru')!);
    responseState.policyType = policyType;
  }

  /* for forgot signin */
  if (policyType && policyType == 'ForgotSignin') {
    b2cPolicy = REACT_APP_FORGOT_SIGNIN;
    responseState.policyType = policyType;
  }

  if (policyType && policyType == 'Admin') {
    b2cPolicy = REACT_APP_ADMIN_POLICY;
  }

  if (policyType && policyType == 'Help') {
    url = new URL(`${REACT_APP_HELP_CENTER_REDIRECT}`);
    const provider = `https://${AUTH_HOST}/tfp/${REACT_APP_HELP_CENTER_CLIENT_ID}/${REACT_APP_HELP_CENTER_POLICY?.toLowerCase()}/v2.0/`;
    urlParams.set('provider', provider);
    responseState.policyType = policyType;
  } else {
    redirectUrl = `${window.location.origin}/auth/callback`;
    responseType = 'code';
    clientId = REACT_APP_AUTH_CLIENT_ID;
    url = new URL(`https://${AUTH_HOST}/${REACT_APP_AUTH_TENANT}.onmicrosoft.com/${b2cPolicy}/oauth2/v2.0/authorize`);
    urlParams.set('client_id', clientId!);
    urlParams.set('response_type', responseType!);
    urlParams.set('redirect_uri', redirectUrl!);
    urlParams.set('scope', 'openid');
    urlParams.set('nonce', '12345');
    urlParams.set('code_challenge', PKCE.challenge);
    urlParams.set('code_challenge_method', 'S256');
    if (!policyType || (policyType && policyType == 'Login'))
      urlParams.set('ru', `${window.location.origin}/auth/reset-password`);
    if (env) urlParams.set('ev', env);
    responseState && urlParams.set('state', JSON.stringify(responseState));

    sessionStorage.setItem('lccv', PKCE.verifier);
  }
  urlParams.set('cg', consentCodeMapping());

  url.search = urlParams.toString();
  window.location.replace(url.toString());
};
