import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@pennfoster/pfc-design-system';
import { MAKE_A_PAYMENT, PAYMENTS_OVERVIEW } from 'src/routes/routemap';
import { useNavigate } from 'react-router-dom';
import { AccountStatus } from 'src/types/enrollment';
import { cms } from 'src/helpers/language';

interface ActionButtonsProps {
  accountStatus: AccountStatus;
}

export default function ActionButtons({ accountStatus }: ActionButtonsProps) {
  const content = cms.content.general.button_labels;
  const navigate = useNavigate();

  const handleClick = async () => {
    navigate(MAKE_A_PAYMENT);
  };

  return (
    <>
      <Button
        role="button"
        sx={{ m: 1 }}
        size="large"
        color="primary"
        pfVariant="text"
        component={Link}
        label={content.view_payments}
        to={PAYMENTS_OVERVIEW}
      />

      <Button
        label={content.make_a_payment}
        sx={{ textTransform: 'initial' }}
        size="large"
        color="primary"
        variant="outlined"
        disabled={accountStatus.paymentsBlocked ? true : false}
        onClick={handleClick}
      />
    </>
  );
}
