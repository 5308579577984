/**
 * Factory function to create a tracked event object from an Event.
 * The function extracts relevant properties from the Event and returns a new object.
 * This function can be easily extended to handle other types of events.
 *
 * @param {Event} e - The Event to be converted into a tracked event object.
 * @returns {Object} The tracked event object with properties extracted from
 * the Event.
 */
export function createClickStreamData(e: Event) {
  if (e instanceof MouseEvent) {
    return createMouseEventData(e);
  }
  return createBaseEventData(e);
}

/**
 * Creates a base event object with common properties.
 *
 * @param {Event} e - The event to be converted into a base event object.
 * @returns {Object} The base event object with common properties extracted from the event.
 */
function createBaseEventData(e: Event) {
  return {
    eventName: e.constructor.name,
    type: e.type,
    timeStamp: e.timeStamp,
    isTrusted: e.isTrusted,
    cancelable: e.cancelable,
    composed: e.composed,
    defaultPrevented: e.defaultPrevented,
    eventPhase: e.eventPhase,
  };
}

/**
 * Creates a MouseEvent object with specific properties.
 *
 * @param {MouseEvent} e - The MouseEvent to be converted into a tracked event object.
 * @returns {Object} The tracked event object with properties extracted from the MouseEvent.
 */
function createMouseEventData(e: MouseEvent) {
  const base = createBaseEventData(e);
  return {
    ...base,
    altKey: e.altKey,
    bubbles: e.bubbles,
    button: e.button,
    buttons: e.buttons,
    clientX: e.clientX,
    clientY: e.clientY,
    detail: e.detail,
    metaKey: e.metaKey,
    movementX: e.movementX,
    movementY: e.movementY,
    offsetX: e.offsetX,
    offsetY: e.offsetY,
    pageX: e.pageX,
    pageY: e.pageY,
    returnValue: e.returnValue,
    screenX: e.screenX,
    screenY: e.screenY,
    shiftKey: e.shiftKey,
    which: e.which,
    x: e.x,
    y: e.y,
  };
}
