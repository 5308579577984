import React from 'react';
import svg from 'src/assets/svg/error-models/trouble-connecting.svg';
import { AUTH_LOGOUT } from 'src/routes/routemap';
import { SUPPORT_PHONE_NUMBER } from 'src/constants/phone';

import ErrorModal from './error-modal';
import { useNavigate } from 'react-router-dom';
import { Typography } from '@pennfoster/pfc-design-system';

export default function ProcessingEnrollmentModal() {
  const navigate = useNavigate();

  const headline = `We are still processing your enrollment.`;

  const body = (
    <>
      <Typography gutterBottom>
        We need a little more time before we can get you into your learning.
        This page will reload when the system is ready.
      </Typography>
      <Typography>
        If you still can&apos;t login after a few hours, please give us a call at {SUPPORT_PHONE_NUMBER}.
      </Typography>
    </>
  );

  const buttonOptions = {
    text: 'Log Out',
    onClick: () => navigate(AUTH_LOGOUT),
  };

  return (
    <ErrorModal
      headline={headline}
      body={body}
      svg={svg}
      secondaryButtonOpts={buttonOptions}
    />
  );
}
