import { AxiosResponse } from 'axios';
import { instance } from './apiConfig';
import {
  CalculateSalesTaxRequest,
  ChargeStudent,
  CreatePaymentDto,
  NextPaymentData,
  Payment,
  PaymentPlans,
  PaymentTaxesResponse,
  ScheduledPaymentData,
} from 'src/types/payments';

const ROUTE = '/user';

export async function getPaymentPlans(): Promise<AxiosResponse<PaymentPlans[]>> {
  const response = await instance({
    method: 'GET',
    url: `${ROUTE}/paymentplans`,
  });

  if (response.status === 200) {
    return {
      ...response,
      data: [...response.data],
    };
  }
  return response;
}

export function getScheduledPayments(): Promise<AxiosResponse<[]>> {
  return instance({
    method: 'GET',
    url: `/user/scheduledpayments`,
  });
}

export function getEnrollmentScheduledPayments(enrollmentId: string): Promise<AxiosResponse<ScheduledPaymentData>> {
  return instance({
    method: 'GET',
    url: `/user/enrollments/${enrollmentId}/scheduledpayments`,
  });
}

export function getPaymentTaxes(data: CalculateSalesTaxRequest, enrollmentId: string): Promise<PaymentTaxesResponse> {
  return instance({
    method: 'POST',
    url: `/user/enrollments/${enrollmentId}/paymenttax`,
    data: data,
  });
}

export function makePayment(enrollmentId: string, data: ChargeStudent): Promise<AxiosResponse> {
  return instance({
    method: 'POST',
    url: `/user/enrollments/${enrollmentId}/pay`,
    data: data,
  });
}

export function postNewPayment(enrollmentId: string, data: CreatePaymentDto): Promise<AxiosResponse<Payment>> {
  return instance({
    method: 'POST',
    url: `/user/enrollments/${enrollmentId}/pay`,
    data: data,
  });
}

export function getNextPaymentData(): Promise<AxiosResponse<NextPaymentData>> {
  return instance({
    method: 'GET',
    url: `/user/nextpayment`,
  });
}
