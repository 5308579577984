export function consentCodeMapping() {
  const domain = location.hostname;
  switch (true) {
    case domain === 'learn.dev.pennfoster.pfgtst.com':
      return 'PF';
    case domain === 'learn.dev.ashworthcollege.pfgtst.com':
      return 'AW';
    case domain === 'learn.dev.jmhs.pfgtst.com':
      return 'AW';
    case domain === 'learn.dev.pcdi.pfgtst.com':
      return 'PCDI';
    case domain === 'learn.dev.ics.pfgtst.com':
      return 'ICS';
    case domain === 'learn.stage.pennfoster.pfgtst.com':
      return 'PF';
    case domain === 'learn.stage.ashworthcollege.pfgtst.com':
      return 'AW';
    case domain === 'learn.stage.jmhs.pfgtst.com':
      return 'AW';
    case domain === 'learn.stage.pcdi.pfgtst.com':
      return 'PCDI';
    case domain === 'learn.stage.ics.pfgtst.com':
      return 'ICS';
    case domain === 'learn.uat.pennfoster.pfgtst.com':
      return 'PF';
    case domain === 'learn.uat.ashworthcollege.pfgtst.com':
      return 'AW';
    case domain === 'learn.uat.jmhs.pfgtst.com':
      return 'AW';
    case domain === 'learn.uat.pcdi.pfgtst.com':
      return 'PCDI';
    case domain === 'learn.uat.ics.pfgtst.com':
      return 'ICS';
    case domain === 'learn.pennfoster.edu':
      return 'PF';
    case domain === 'learn.ashworthcollege.edu':
      return 'AW';
    case domain === 'learn.jmhs.com':
      return 'AW';
    case domain === 'learn.pcdi.ca':
      return 'PCDI';
    case domain === 'learn.icslearn.ca':
      return 'ICS';
    default:
      return 'PF';
  }
}
