import { MutationStatus } from 'react-query';

import { useTheme } from '@mui/material';
import { Card } from '@pennfoster/pfc-design-system';

import PathwayCartButton from './button';
import PathwayCartHeader from './pathway-cart-header';
import PathwayCartItem from './pathway-cart-item';
import PathwayCartMessage from './pathway-cart-message';
import LoadingComponent from 'src/components/loading-component';

interface Props {
  mutationStatus: MutationStatus;
  isSubmissionModalOpen: boolean;
  selectedPathwayName: string;
  pathwayHasElectivePools: boolean;
  clearSelectedPathway: () => void;
  handleSubmitClick: () => void;
  handleContinueClick: () => void;
}

export default function PathwayCartCard(props: Props) {
  const { palette } = useTheme();
  const subprops = { isPathwaySelected: !!props.selectedPathwayName, ...props };

  if (props.mutationStatus == 'loading') {
    return (
      <Card
        pfVariant="default"
        sx={{
          backgroundColor: palette.background.paper,
          borderRadius: 0,
          p: 3,
        }}
      >
        <LoadingComponent label="Confirming Submission" fullPage={false} />
      </Card>
    );
  }
  return (
    <Card
      pfVariant="default"
      sx={{
        backgroundColor: palette.background.paper,
        borderRadius: 0,
        p: 3,
      }}
    >
      <PathwayCartHeader />
      <PathwayCartItem {...subprops} />
      <PathwayCartMessage {...subprops} />
      <PathwayCartButton {...subprops} />
    </Card>
  );
}
