import React from 'react';

import {
  CardContent,
  CardHeader,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { Card, Typography } from '@pennfoster/pfc-design-system';
import { gpaFormatWithDecimals } from 'src/helpers/format';

import { Enrollment } from '../../types/enrollment';
import { Subtitle1 } from 'src/styles';

interface GradesCardProps {
  enrollment: Enrollment;
}

export default function gradesCard({ enrollment }: GradesCardProps) {
  const formattedGpa = gpaFormatWithDecimals(enrollment.gpa);

  return (
    <Card pfVariant="default" borderColor="secondary.90">
      <CardHeader
        sx={{ px: 3, pt: 2.5, pb: 2 }}
        title={<Subtitle1 variant="h2">My Grades</Subtitle1>}
      />
      <CardContent>       
        <Grid container sx={{ fontSize: '14px' }}>
          <Grid item xs={12} sx={{ padding: '16px' }}>
            <Grid container>
              <Grid item xs={6}>
                Current GPA
              </Grid>
              <Grid item xs={6} sx={{ textAlign: 'end', fontWeight: 'bold' }}>
                {' '}
                {formattedGpa}{' '}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sx={{ padding: '16px' }}>
            <Grid container>
              <Grid item xs={6} sx={{ borderBottom: '0' }}>
                Total Credits
              </Grid>
              <Grid item xs={6} sx={{ textAlign: 'end', borderBottom: '0' }}>
                {' '}
                <Typography fontWeight="bold" display="inline">
                  {enrollment.creditEarned}
                </Typography>{' '}
                /{enrollment.creditRequired}{' '}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
