import React from 'react';
import { Skeleton } from '@pennfoster/pfc-design-system';
import { Grid } from '@mui/material';

interface ExpansionSkeletonListProps {
  count: number;
  height?: number;
}

export default function ExpansionSkeleton({
  count,
  height = 56,
}: ExpansionSkeletonListProps) {
  return (
    <>
      {Array.from(Array(count)).map((_, index) => (
        <Grid key={`skeleton-${index}`} item>
          <Skeleton
            variant="rectangular"
            height={height}
            sx={{ margin: '8px 8px 8px 8px' }}
          />
        </Grid>
      ))}
    </>
  );
}
