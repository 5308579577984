const config = process.env;
export const LOGINSESSION = 'loginSession';
const REFRESHSESSION = 'refreshTokenSession';
const LASTACTIVITYSESSION = 'lastActivitySession';
const LASTACTIVITY = 'lastActivity';
const LCSESSION = 'lcSession';
const ACTIVITYLENGTH = parseInt(config.REACT_APP_ACTIVITY_LENGTH ?? '5', 10);

class Session {
  getSession = () => this.getParsedSession(LCSESSION);

  get = (key: string) => {
    const session = this.getSession();
    return session?.[key] ?? null;
  }

  set = (key: string, value: string) => {
    const session = this.getSession() || {};
    const updatedSession = { ...session, [key]: value };
    sessionStorage.setItem(LCSESSION, JSON.stringify(updatedSession));
  }

  setInitialSession = () => {
    const loginSession = localStorage.getItem(LOGINSESSION) ?? crypto.randomUUID();
    localStorage.setItem(LOGINSESSION, loginSession);

    const session = {
      [LOGINSESSION]: loginSession,
      [REFRESHSESSION]: crypto.randomUUID(),
      [LASTACTIVITYSESSION]: crypto.randomUUID(),
    };

    sessionStorage.setItem(LCSESSION, JSON.stringify(session));
    this.checkLastActivity();
  }

  updateRefreshSession = () => this.set(REFRESHSESSION, crypto.randomUUID());

  updateLastActivitySession = () => this.set(LASTACTIVITYSESSION, crypto.randomUUID());

  updateLastActivityDate = () => {
    sessionStorage.setItem(LASTACTIVITY, this.addMinutesToDate(ACTIVITYLENGTH).toISOString());
  }

  addMinutesToDate = (minutes: number) => new Date(Date.now() + minutes * 60000);

  checkLastActivity = () => {
    const lastActivityValue = sessionStorage.getItem(LASTACTIVITY);
    const currentDate = new Date();

    if (!lastActivityValue || currentDate >= new Date(lastActivityValue)) {
      this.updateLastActivitySession();
    }

    this.updateLastActivityDate();
  }

  private getParsedSession = (key: string) => {
    const sessionString = sessionStorage.getItem(key);
    return sessionString ? JSON.parse(sessionString) : null;
  }
}

export default new Session();