const config = process.env;
let AUTH_HOST: string;
if (
  location.host.includes('localhost') ||
  location.host.includes('sc-stage-cdn-d4b5azg6f5ftfpdp') ||
  location.host.includes('sc-dev-cdn-dzamf8dddkc2gah3')
) {
  AUTH_HOST = 'pfstudentcenterdev002.b2clogin.com';
} else if (location.host.includes('ics')) {
  AUTH_HOST = config.REACT_APP_ICS_LOGIN!;
} else {
  AUTH_HOST = `login.${location.host}`;
}

export { AUTH_HOST };
