import NotificationCard from './notification-card';
import { lexisNexisNotificationProps } from './lexis-nexis-notification-card-props';


export default function LexisNexisNotificationCard() {
  const notificationProps = lexisNexisNotificationProps();
  return (
    <NotificationCard
      content={notificationProps.content}
      buttons={notificationProps.buttons}
      media={notificationProps.media}
      title={notificationProps.title}
    />
  );
}