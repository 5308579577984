import React from 'react';
import { useTheme } from '@mui/material';
import { Chip } from '@pennfoster/pfc-design-system';
import { PaymentMethodSelection } from 'src/types/payments';

interface Props {
  params: PaymentMethodSelection;
}

interface ColorMap {
  background: string | undefined;
  border: string;
}

export default function PaymentMethodsChip({ params }: Props) {
  const theme = useTheme();
  let paymentColorMap: ColorMap;
  if (params === PaymentMethodSelection.default) {
    paymentColorMap = {
      background: theme.palette.secondary.container,
      border: theme.palette.secondary.light,
    };
  } else {
    paymentColorMap = {
      background: theme.palette.background.default,
      border: theme.palette.text.disabled,
    };
  }

  return (
    <Chip
      aria-label={`Status: ${params}`}
      backgroundColor={`${paymentColorMap.background}`}
      borderColor={paymentColorMap.border}
      size={'small'}
      variant="outlined"
      label={params}
    ></Chip>
  );
}
