import { refreshToken } from 'src/auth/refresh-token';
import { redirectToLogin } from 'src/helpers/redirect-to-login';
import AuthTokens from 'src/auth/auth-tokens';
import { SESSION_TIMEOUT } from 'src/routes/routemap';

export const refreshTokenHelper = async () => {
  const newToken = await refreshToken();
  if (newToken) {
    AuthTokens.setAccessToken(newToken);
    return newToken;
  }
  return await window.location.replace(SESSION_TIMEOUT);
};

export const redirectToLoginHelper = async () => {
  await redirectToLogin();
  throw new Error('Token refresh failed');
};
