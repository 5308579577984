import { Box, Grid } from '@mui/material';
import {
  MyElectivesCardSkeleton,
  MyProgramCardSkeleton,
  PaymentsCardSkeleton,
  TasksCardSkeleton,
  UpcomingCoursesCardSkeleton,
} from 'src/components/skeleton-components';

export function ProgramsLoading() {
  return (
    <Grid container spacing={3}>
      <Grid container item xs={12} md={7} rowGap={2}>
        <Grid item display={'flex'} xs={12} columnGap={2}>
          <Box width={'90%'} display={'flex'} flexWrap={'wrap'} rowGap={1}>
            <Box
              height={16}
              width={'100%'}
              bgcolor={'#f4f4f5' /* replace with token */}
            />
            <Box
              height={24}
              width={'100%'}
              bgcolor={'#f4f4f5' /* replace with token */}
            />
          </Box>
          <Box width={'10%'}>
            <Box
              height={56}
              width={56}
              borderRadius={'50%'}
              bgcolor={'#f4f4f5' /* replace with token */}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <UpcomingCoursesCardSkeleton
            status={false}
            coursesCount={10}
            divider
            action={false}
          />
        </Grid>
      </Grid>
      <Grid container item xs={12} md={5} rowGap={3} height={'min-content'}>
        <Grid item xs={12}>
          <Box height={48} width={'100%'} />
        </Grid>
        <Grid item xs={12}>
          <PaymentsCardSkeleton header={false} />
        </Grid>
        <Grid item xs={12}>
          <MyProgramCardSkeleton />
        </Grid>
        <Grid item xs={12}>
          <TasksCardSkeleton status={false} divider={false} />
        </Grid>
        <Grid item xs={12}>
          <MyElectivesCardSkeleton />
        </Grid>
      </Grid>
    </Grid>
  );
}
