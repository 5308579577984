// apiConfig.ts
import axios from 'axios';
import { refreshTokenHelper } from 'src/utils/auth-utils';
import { TokenHelper } from 'src/helpers/token-helper';
import AuthTokens from 'src/auth/auth-tokens';

export const config = process.env;

export const applicationVersion = `${config.REACT_APP_MAJOR_VERSION ?? 0}.${config.REACT_APP_MINOR_VERSION ?? 0}.${
  config.REACT_APP_BUG_VERSION ?? 0
}`;

export const instance = axios.create({
  baseURL: `${config.REACT_APP_SC_API}/api`,
  headers: {
    'content-type': 'application/json',
  },
  withCredentials: true,
});

instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response?.status === 401) {
      if (error.response.data.Reason === 'TOKEN_EXPIRED' && !originalRequest._retry) {
        originalRequest._retry = true;

        const newToken = await refreshTokenHelper();

        if (newToken) {
          originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
          return instance(originalRequest);
        }
      } else if (error.response.data.Reason === 'USER_NOT_AUTHORIZED' && window.location.pathname !== '/unauthorized') {
        window.location.href = '/unauthorized';
      }
    }

    return Promise.reject(error);
  }
);

// Request interceptor
instance.interceptors.request.use(
  async function (config) {
    // To-Do Read the token from userContext instead of sessionStorage
    const storedToken = AuthTokens.getAccessToken();
    const tokenHelper = new TokenHelper(storedToken);

    if (tokenHelper.isTokenExpired()) {
      const newToken = await refreshTokenHelper();

      if (newToken) {
        // Token Stored by refreshTokenHelper
        config.headers['Authorization'] = `Bearer ${newToken}`;
      } else {
        throw new Error('Token refresh failed');
      }
    } else if (storedToken) {
      config.headers['Authorization'] = `Bearer ${storedToken}`;
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default instance;
