import { Dialog, DialogTitle, DialogContent } from '@mui/material';
import { Subtitle1 } from 'src/styles';
import AddCardForm from '../forms/add-card-form';
import { PaymentMethod } from 'src/types/payments';

interface AddCardFormDialogProps {
  open: boolean;
  handleClose: () => void;
  emitNewCard?: (paymentMethod: PaymentMethod) => void;
}

export default function AddCardFormDialog({
  open,
  handleClose,
  emitNewCard,
}: AddCardFormDialogProps) {
  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle
        sx={{
          paddingTop: 0,
          paddingLeft: 0,
          paddingRight: 0,
        }}
      >
        <Subtitle1>Add a new card</Subtitle1>
      </DialogTitle>
      <DialogContent sx={{ padding: 0 }}>
        <AddCardForm handleClose={handleClose} emitNewCard={emitNewCard} />
      </DialogContent>
    </Dialog>
  );
}
