import { ElectivePoolCourse } from 'src/types/electives';

import { ExpansionItem } from '@pennfoster/pfc-design-system/molecules/expansion-panel';

import ElectiveRowDescription from './row-description';
import ElectiveRowSummary from './row-summery';
import { MutationStatus } from 'react-query';

export interface ElectiveExpansionRowProps {
  course: ElectivePoolCourse;
  isAddable: boolean;
  isSelected: boolean;
  isEnrolled: boolean;
  mutationStatus: MutationStatus;
  selectElective: () => void;
}

export default function ElectiveExpansionRow({ course, ...props }: ElectiveExpansionRowProps) {
  return (
    <ExpansionItem>
      <ElectiveRowSummary {...props} {...course} text={course.name} />
      <ElectiveRowDescription {...course} />
    </ExpansionItem>
  );
}
