import { Box } from '@mui/material';
import { Skeleton } from '@pennfoster/pfc-design-system';

interface Props {
  variant?: 'default' | 'square' | 'square-reversed';
}

export function TasklistItemSkeleton({ variant = 'default' }: Props) {
  return (
    <Box
      display={'flex'}
      width={'100%'}
      columnGap={1}
      alignItems={'center'}
      flexDirection={variant === 'square-reversed' ? 'row-reverse' : 'unset'}
    >
      <Skeleton
        variant={
          variant === 'square-reversed' || variant === 'square'
            ? 'rounded'
            : 'circular'
        }
        width={24}
        height={24}
      />
      <Skeleton variant={'rounded'} width={'calc(100% - 32px)'} height={16} />
    </Box>
  );
}
