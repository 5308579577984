/**
 * Get the initials from the first and last name.
 *
 * @param {string} firstName - The first name.
 * @param {string} lastName - The last name.
 * @returns {string} The initials, in uppercase.
 *
 * @example
 * getInitials('John', 'Doe') // returns "JD"
 */
export function getInitials(firstName = '', lastName = ''): string {
  const first = firstName.charAt(0);
  const second = lastName.charAt(0);
  return (first + second).toUpperCase();
}
