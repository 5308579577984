/* eslint-disable react/jsx-no-undef */
import { Box } from '@mui/system';
import React from 'react';
import AppLayout from '../../components/layout';
import { LabelLarge } from '../../styles';
import SuccessPayment from '../../assets/svg/success-payment.svg';
import { Link, useParams } from 'react-router-dom';

export default function ConfirmationPayment() {
  const { value } = useParams();
  const date = new Date();
  const formattedDate = date.toLocaleDateString('en-us', {
    weekday: 'long',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });

  return (
    <AppLayout>
      <Box
        sx={{
          display: 'flex',
          marginTop: -5,
          marginRight: 0,
          marginBottom: 0,
          marginLeft: 0,
          width: '100%',
          position: 'static',
          height: '100vh',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            left: 0,
            right: 0,
          }}
        >
          <img
            src={SuccessPayment}
            style={{
              height: '100%',
              width: '100%',
              objectFit: 'contain',
            }}
          />
        </Box>

        <LabelLarge
          sx={{
            fontFamily: 'Roboto',
            fontSize: '22px',
            fontWeight: 400,
            lineHeight: '24px',
            position: 'absolute',
            textAlign: 'center',
            top: '72%',
            left: '50%',
            right: 0,
            color: 'white',
            maxWidth: '600px',
            transform: 'translate(-50%, -28px)',
          }}
        >
          Thank you for your payment
        </LabelLarge>
        <LabelLarge
          sx={{
            fontFamily: 'Roboto',
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '20px',
            position: 'absolute',
            letterSpacing: '0.25px',
            textAlign: 'center',
            top: '79%',
            left: '50%',
            right: 0,
            color: 'rgba(255, 255, 255, 0.65)',
            alignItems: 'center',
            maxWidth: '600px',
            transform: 'translate(-50%, -24px)',
          }}
        >
          ${value} paid on {formattedDate}
        </LabelLarge>

        <Link to="/payments-overview">
          <LabelLarge
            sx={{
              fontFamily: 'Roboto',
              fontSize: '12px',
              fontWeight: 400,
              lineHeight: '20px',
              position: 'absolute',
              letterSpacing: '0.25px',
              textAlign: 'center',
              top: '85%',
              left: '50%',
              right: 0,
              color: 'rgba(255, 255, 255, 0.65)',
              alignItems: 'center',
              maxWidth: '600px',
              transform: 'translate(-50%, -15px)',
            }}
          >
            Return to payments
          </LabelLarge>
        </Link>
      </Box>
    </AppLayout>
  );
}
