import { AxiosResponse } from 'axios';
import { instance } from './apiConfig';
import { State } from 'src/types/states';

export function getStates(): Promise<AxiosResponse<State[]>> {
  return instance({
    method: 'GET',
    url: `/regions`,
  });
}
