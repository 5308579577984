import { StatusCodes } from 'http-status-codes';

/**
 * Checks if a given HTTP status code falls within the 2xx range, indicating success.
 *
 * @param statusCode - The HTTP status code to check.
 * @returns {boolean} - True if the status code is between 200 and 299, inclusive. False otherwise.
 */
export function is2xx(statusCode: number): boolean {
  return (
    statusCode >= StatusCodes.OK && statusCode < StatusCodes.MULTIPLE_CHOICES
  );
}
