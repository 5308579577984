import { AxiosError } from 'axios';

interface ErrorResponse {
  errors: string[];
  message?: string;
}

const getErrorMessages = (errors: string[]): string[] => {
  return errors.reduce((messages: string[], error: string) => {
    if (!error) {
      return messages;
    }

    if (typeof error === 'string') {
      return [...messages, error];
    }

    if (typeof error !== 'object') {
      return messages;
    }

    const { message, error: errorMsg } = error;

    if (typeof message === 'string') {
      return [...messages, message];
    }

    if (typeof errorMsg === 'string') {
      return [...messages, errorMsg];
    }
    return messages;
  }, []);
};

export const getErrorMessage = (error: AxiosError): string => {
  const errData = error.response?.data as ErrorResponse;
  const errors = errData?.errors;

  if (typeof errData === 'object' && 'Errors' in errData) {
    const errors = errData.Errors ?? [];
    if (Array.isArray(errors) && errors.length > 0) {
      const errorMessage = errors[0].Message;
      if (errorMessage.length > 0) return errorMessage;
    }
  }

  if (!errors && errData.message) {
    return errData.message;
  } else if (typeof errData === 'string' && errData !== '') {
    return errData;
  }

  if (errors && Object.keys(errors).length > 0) {
    const firstErrorKey = Object.keys(errors)[0] as keyof typeof errors;
    const firstErrorMessages = errors[firstErrorKey];

    if (Array.isArray(firstErrorMessages) && firstErrorMessages.length > 0) {
      const formattedErrorMessage = firstErrorMessages[0];
      const splitErrorMessage = formattedErrorMessage.replace(
        /([a-z])([A-Z])/g,
        '$1 $2'
      ); // Split words conjoined by capital letters
      return splitErrorMessage;
    }
  }

  const sources = [errors].filter(Boolean);

  const firstSourceWithMessage = sources.find((source) => {
    const formattedSource = Array.isArray(source) ? source : [source];
    const messages = getErrorMessages(formattedSource);

    return messages.length > 0;
  });

  if (firstSourceWithMessage) {
    const formattedSource = Array.isArray(firstSourceWithMessage)
      ? firstSourceWithMessage
      : [firstSourceWithMessage];

    const messages = getErrorMessages(formattedSource);

    // If multiple errors, format them nicely
    if (messages.length > 1) {
      const formattedMessages = messages
        .map((m) => `&#9;&#149; ${m}`)
        .join('\n');

      return `Multiple errors occurred: \n${formattedMessages}`;
    }

    // Otherwise, return the single message
    return messages[0];
  }

  // If no message was found in the response data, return the error message
  return error.message;
};

export default {
  getErrorMessage,
};
