import { Link } from 'react-router-dom';
import FeEvents from 'src/events/fe-events';
import { SUPPORT_PHONE_NUMBER } from 'src/constants/phone';
import { cms } from 'src/helpers/language';


import { Button } from '@pennfoster/pfc-design-system';

import { ReactComponent as ChooseElectivesSvg } from '../../assets/svg/choose-electives.svg';
import { useElectivesRoutes } from 'src/hooks/electives';

const electivesNotificationProps = (showLSAPlaceHolders: boolean) => {
  const { electivePathwayRoute }= useElectivesRoutes();
  const navigateUrl = electivePathwayRoute;
  
  return {
    media: <ChooseElectivesSvg />,
    title: `${cms.content.pages.courses_and_grades.cards.select_electives.choose_your_electives_title}`,
        content: showLSAPlaceHolders ? (
      <>
        You’ll need to call our support team at{' '}
        <a href={`tel:${SUPPORT_PHONE_NUMBER}`}>{SUPPORT_PHONE_NUMBER}</a> to select your electives.
      </>
    ) : (
      `${cms.content.pages.courses_and_grades.cards.select_electives.select_electives_description}`
    ),
    buttons: [
      !showLSAPlaceHolders ? 
      <Button
        key="Select Electives"
        sx={{ m: 1 }}
        size="small"
        variant="outlined"
        component={Link}
        to={navigateUrl}
        onClick={() =>
          FeEvents.trackNamedEvent({
            eventName: 'SelectElectivesClickEvent',
          })
        }
      >
        {cms.content.general.button_labels.select_electives}
      </Button> : <></>,
    ],
  };
};

export { electivesNotificationProps };
