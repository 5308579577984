import React from 'react';

import { TableCell, TableRow } from '@mui/material';

interface Props {
  enrolledElectiveCoursesNames: string[];
}

export default function MyElectivesCardCoursesRows({
  enrolledElectiveCoursesNames,
}: Props) {
  return (
    <>
      {enrolledElectiveCoursesNames.map((courseName, i) => {
        return (
          <TableRow
            key={i}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <TableCell>{courseName}</TableCell>
          </TableRow>
        );
      })}
    </>
  );
}
