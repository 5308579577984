import React from 'react';
import { useTheme } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { Chip } from '@pennfoster/pfc-design-system';
import {
  PaymentPlanStatus,
  PaymentPlanStatusLabels,
} from 'src/types/payment-plan-status';

interface Props {
  params: GridRenderCellParams;
}

export default function StatusChip({ params }: Props) {
  const theme = useTheme();
  const statusColorMap = {
    [PaymentPlanStatus.Past_due]: {
      background: theme.palette.warning.light,
      border: theme.palette.warning.main,
    },
    [PaymentPlanStatus.Missed]: {
      background: theme.palette.warning.light,
      border: theme.palette.warning.main,
    },
    [PaymentPlanStatus.Current]: {
      background: theme.palette.secondary.container,
      border: theme.palette.secondary.light,
    },
    [PaymentPlanStatus.Scheduled]: {
      background: theme.palette.secondary.container,
      border: theme.palette.secondary.light,
    },
    [PaymentPlanStatus.Paid]: {
      background: theme.palette.success.container,
      border: theme.palette.success.light,
    },
    [PaymentPlanStatus.Suspended]: {
      background: theme.palette.error.container,
      border: theme.palette.error.light,
    },
    [PaymentPlanStatus.Deactivated]: {
      background: theme.palette.background.light,
      border: theme.palette.neutral[80],
    },
  };
  //TODO: Temp fix, we need to find real soultion
  const color = statusColorMap[params.value as keyof typeof statusColorMap] ?? {
    background: theme.palette.background.light,
    border: theme.palette.neutral[80],
  };

  return (
    <Chip
      aria-label={`Status: ${[
        PaymentPlanStatusLabels[params.value as PaymentPlanStatus],
      ]}`}
      borderColor={color.border}
      backgroundColor={color.background}
      pfVariant="outlined"
      size="small"
      label={[PaymentPlanStatusLabels[params.value as PaymentPlanStatus]]}
    />
  );
}
