export const CC_NUMBER_REGEX =
  /^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|3[47][0-9]{13}|6(?:011|5[0-9]{2})[0-9]{12})$/;

export const CC_NUMBER_GROUPED_REGEX = /^(\d{4})(\d{4})(\d{4})(\d{4})$/;

export const CC_EXP_DATE_REGEX = /^(0[1-9]|1[0-2])\/?([0-9]{2})$/;

export const CC_CVV_REGEX = /^[0-9]{3,4}$/;

export const ZIP_CODE_REGEX = /^[0-9]{5}(?:-[0-9]{4})?$/;
