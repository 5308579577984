import { MutationStatus } from 'react-query';
import CartItem from 'src/components/common/cart/selected-item';
import { SISCourseId } from 'src/types/course';
import { SelectedElective } from 'src/types/electives';

interface Props {
  mutationStatus: MutationStatus;
  selectedCourses: SelectedElective[];
  handleRemoveElective: (sisCourseId: SISCourseId) => void;
}

const ElectiveCartItems = ({
  mutationStatus,
  selectedCourses,
  handleRemoveElective,
}: Props) => {
  return (
    <>
      {selectedCourses.map((item) => {
        let buttonText = 'Remove';
        let disable = false;

        if (mutationStatus === 'success') {
          buttonText = 'Confirmed';
          disable = true;
        }

        return (
          <CartItem
            key={`selected-elective-course-${item.sisCourseId}`}
            text={item.courseName}
            buttonText={buttonText}
            disable={disable}
            onClick={() => handleRemoveElective(item.sisCourseId)}
          />
        );
      })}
    </>
  );
};

export default ElectiveCartItems;
