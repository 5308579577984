import { instance } from './apiConfig';
import { Query } from './index';

export function getTransactionHistory<T = any>(
  enrollmentId: string,
  query = new Query<T>()
) {
  const qs = query.toQueryString();
  return instance<T[]>({
    method: 'GET',
    url: `/user/ledger/transactions?${qs}`,
  });
}
