import React, { useContext, useState } from 'react';
import { Alert, Icon } from '@pennfoster/pfc-design-system';
import { NunitoSansErrorMessage } from 'src/styles';
import { Box, CardActions, CardContent, DialogContent, DialogTitle, useTheme } from '@mui/material';
import { Card, Button, Dialog } from '@pennfoster/pfc-design-system';
import EditCardForm from '../forms/edit-card-form';
import { PaymentMethod } from '../../types/payments';
import PaymentMethodContext from 'src/context/payment-method';
import { PaymentMethodSelection } from 'src/types/payments';
import PaymentMethodsChip from 'src/components/payment-methods-chip';
import MasterCardSvg from 'src/assets/svg/mastercard.svg';
import AmericanExpressSvg from 'src/assets/svg/americanexpress.svg';
import VisaSvg from 'src/assets/svg/visa.svg';
import DiscoverSvg from 'src/assets/svg/discover.svg';
import { ReactComponent as DefaultCardIcon } from 'src/assets/svg/default-card.svg';
import { getPaymentMethods, makeBackup, makeDefault } from 'src/api';
import { useQuery } from 'react-query';
import QueryKeys from 'src/types/query-keys';
import { Body1, Subtitle1 } from 'src/styles';
import { bankType } from 'src/constants/bankType';

interface CardContainerProps {
  paymentMethod?: PaymentMethod;
  loading?: boolean;
}

export default function CardContainer(props: CardContainerProps) {
  const theme = useTheme();
  const { loading, paymentMethod }: CardContainerProps = props;
  const { setErrorsFromAPI, setPaymentMethodSelected } = useContext(PaymentMethodContext);
  const [open, setOpen] = useState(false);
  const achAlert = `
  ACH payment methods cannot be added as a new payment method.
  If you unassign this payment method you will not be able to reassign it.
  `;

  const paymentMethodsQuery = useQuery(QueryKeys.GET_PAYMENT_METHODS, getPaymentMethods);

  const handleClickOpen = () => {
    setErrorsFromAPI('');
    setOpen(true);
  };

  const handleSetAsDefault = (creditcardId: number | undefined) => {
    if (creditcardId) {
      makeDefault(creditcardId).then(() => {
        paymentMethodsQuery.refetch();
      });
    }
  };

  const handleSetAsBackup = (creditcardId: number | undefined) => {
    if (creditcardId) {
      makeBackup(creditcardId).then(() => {
        paymentMethodsQuery.refetch();
      });
    }
  };

  const handleClose = () => {
    setErrorsFromAPI('');
    setPaymentMethodSelected({
      nameOnCard: '',
      cardNumber: '',
      expiration: '',
      zipCode: '',
      isDefault: false,
      isBackup: false,
    });
    setOpen(false);
  };

  let cardImage;
  let brandName;
  const customSvgStyle = {
    width: '40px',
    height: '27px',
    border: 0,
  };
  switch (paymentMethod?.brand?.toLowerCase()) {
    case 'amex':
      cardImage = <img alt="American Express" src={AmericanExpressSvg} style={customSvgStyle} />;
      brandName = 'American Express';
      break;
    case 'visa':
      cardImage = <img alt="Visa" src={VisaSvg} style={customSvgStyle} />;
      brandName = 'Visa';
      break;
    case 'mastercard':
      cardImage = <img alt="Mastercard" src={MasterCardSvg} style={customSvgStyle} />;
      brandName = 'Mastercard';
      break;
    case 'discover':
      cardImage = <img alt="Discover" src={DiscoverSvg} style={customSvgStyle} />;
      brandName = 'Discover';
      break;
    default:
      cardImage = <DefaultCardIcon />;
  }

  return (
    <>
      <Card sx={{ p: theme.spacing(1) }} pfVariant="default" borderColor={theme.palette.neutral[100]}>
        <CardContent sx={{ paddingBottom: '0' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
            <Box
              sx={{
                flexDirection: 'row',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <>
                {paymentMethod?.type === bankType ? (
                  <Icon.AccountBalance />
                ) : (
                  cardImage || (
                    <Box
                      sx={{
                        backgroundColor: '#5B6269',
                        padding: '2px 0 0 0',
                        height: '27px',
                        width: '40px',
                      }}
                    ></Box>
                  )
                )}
              </>
              <Subtitle1
                noWrap
                sx={{
                  margin: '0 16px',
                }}
              >
                {paymentMethod?.type !== bankType ? (
                  <>
                    {brandName} {paymentMethod?.lastFour}
                  </>
                ) : (
                  paymentMethod?.bankName
                )}
              </Subtitle1>
            </Box>
            <Box>
              {paymentMethod?.isDefault && <PaymentMethodsChip params={PaymentMethodSelection.default} />}
              {paymentMethod?.isBackup && <PaymentMethodsChip params={PaymentMethodSelection.backup} />}
            </Box>
          </Box>
          <Box></Box>
          <Box sx={{ flexDirection: 'column' }}>
            <Box>
              <Body1
                noWrap
                sx={{
                  textAlign: 'left',
                }}
              >
                {paymentMethod?.type !== bankType ? (
                  <>Name: {paymentMethod?.nameOnCard}</>
                ) : (
                  <>Account Number: {'************' + paymentMethod?.lastFour}</>
                )}
              </Body1>
            </Box>
            <Box>
              <Body1
                noWrap
                sx={{
                  textAlign: 'left',
                  color: paymentMethod?.expired ? 'red' : '',
                }}
              >
                {paymentMethod?.type !== bankType ? (
                  <>
                    {' '}
                    Exp: {paymentMethod?.expiration} {paymentMethod?.expired ? '(Expired)' : ''}
                  </>
                ) : (
                  <>Routing Number: {paymentMethod?.routingNumber}</>
                )}
              </Body1>
            </Box>
          </Box>
        </CardContent>
        {paymentMethod?.type === bankType && (
          <Alert
            sx={{
              marginTop: '20px',
              marginBottom: '20px',
            }}
            pfVariant="standard"
            severity="info"
          >
            <NunitoSansErrorMessage>{achAlert}</NunitoSansErrorMessage>
          </Alert>
        )}
        {paymentMethod?.type !== bankType && (
          <CardActions sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            {!loading ? (
              <>
                {!paymentMethod?.expired && (
                  <>
                    {!paymentMethod?.isBackup && !paymentMethod?.isDefault && (
                      <Button onClick={() => handleSetAsBackup(paymentMethod?.id)} pfVariant="text">
                        Make Backup
                      </Button>
                    )}

                    {!paymentMethod?.isDefault && (
                      <Button onClick={() => handleSetAsDefault(paymentMethod?.id)} pfVariant="text">
                        Make Default
                      </Button>
                    )}
                  </>
                )}

                {paymentMethod?.type !== bankType && (
                  <Button onClick={handleClickOpen} pfVariant="text">
                    Edit
                  </Button>
                )}
              </>
            ) : (
              <Box sx={{ height: '75px' }} />
            )}
          </CardActions>
        )}
      </Card>
      <Box
        sx={{
          alignContent: 'center',
          alignItems: 'center',
          margin: '0 auto',
          width: '100%',
        }}
      >
        <Dialog
          onClose={handleClose}
          open={open}
          sx={{
            alignContent: 'center',
            alignItems: 'center',
            margin: '0 auto',
          }}
        >
          <DialogTitle
            sx={{
              paddingTop: 0,
              paddingLeft: 0,
              paddingRight: 0,
            }}
          >
            <Subtitle1>
              Edit {brandName} ...{paymentMethod?.lastFour}
            </Subtitle1>
          </DialogTitle>
          <DialogContent sx={{ padding: 0 }}>
            <EditCardForm paymentMethod={paymentMethod} handleClose={handleClose} />
          </DialogContent>
        </Dialog>
      </Box>
    </>
  );
}
