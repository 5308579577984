import React, { useEffect, useState } from 'react';
import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
} from '@mui/material';
import { LabelLarge, NunitoSans24, Subtitle1 } from 'src/styles';
import { Button, Dialog } from '@pennfoster/pfc-design-system';
import { SubmitHandler } from 'react-hook-form';
import { IFormInput } from '.';
import {
  PaymentMethod,
  PaymentBrand,
  PaymentBrandLabels,
} from 'src/types/payments';
import { currencyFormatWithDecimals } from 'src/helpers/format';
import LoadingComponent from 'src/components/loading-component';
import ErrorTaxesDialog from './error-taxes-dialog';

interface ConfirmationDialogProps {
  dialogOpen: boolean;
  amount: number;
  taxAmount: number;
  planName?: string | '';
  paymentMethod?: PaymentMethod;
  setDialogOpen: (value: boolean) => void;
  handleSubmit: (onSubmit: SubmitHandler<IFormInput>) => object;
  onSubmit: SubmitHandler<IFormInput>;
  isLoading: boolean;
  isLoadingTaxes: boolean;
  isErrorOnTaxes: boolean;
}

export default function ConfirmationDialog({
  dialogOpen,
  amount,
  taxAmount,
  planName,
  paymentMethod,
  setDialogOpen,
  handleSubmit,
  onSubmit,
  isLoading,
  isLoadingTaxes,
  isErrorOnTaxes,
}: ConfirmationDialogProps) {
  const brand = paymentMethod?.brand || '';
  const brandLabel = PaymentBrandLabels[brand as PaymentBrand];
  const [totalAmount, setTotalAmount] = useState<number>(0);

  useEffect(() => {
    setTotalAmount(amount + taxAmount);
  }, [amount, taxAmount]);

  return (
    <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
      {isLoadingTaxes ? (
        <Box
          sx={{
            height: '376px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '300px',
            '@media (min-width: 920px)': {
              width: '456px',
            },
          }}
        >
          <LoadingComponent fullPage={false} label="Calculating Sales Tax" />
        </Box>
      ) : isErrorOnTaxes ? (
        <ErrorTaxesDialog />
      ) : !isLoading ? (
        <Box sx={{ maxWidth: '456px' }}>
          <DialogTitle
            sx={{
              paddingTop: 0,
              paddingLeft: 0,
              paddingRight: 0,
            }}
          >
            <Subtitle1>Confirm Payment</Subtitle1>
            <Divider sx={{ marginTop: 2, marginBottom: 0 }} />
            <NunitoSans24 sx={{ pt: 4, fontSize: '18px' }}>
              {`Payment: ${currencyFormatWithDecimals(amount)}`}
            </NunitoSans24>
            <Typography variant="subtitle1" sx={{ fontSize: '16px' }}>
              {`Sales Tax: ${currencyFormatWithDecimals(taxAmount)}`}
            </Typography>
            <Divider sx={{ marginTop: 2, marginBottom: 0 }} />
            <NunitoSans24 sx={{ pt: 2 }}>
              {`Total: ${currencyFormatWithDecimals(totalAmount)}`}
            </NunitoSans24>
          </DialogTitle>
          <DialogContent sx={{ padding: 0 }}>
            You are about to pay this amount for {planName} using {brandLabel}
            ...{paymentMethod?.lastFour}.
          </DialogContent>
          <DialogActions sx={{ marginTop: 2, padding: 0 }}>
            <Button
              pfVariant="text"
              onClick={() => {
                setDialogOpen(false);
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleSubmit(onSubmit)}
              pfVariant="filled"
              color="primary"
              type="submit"
              size="small"
            >
              <LabelLarge>Confirm</LabelLarge>
            </Button>
          </DialogActions>
        </Box>
      ) : (
        <LoadingComponent fullPage={false} label="Confirm Payment" />
      )}
    </Dialog>
  );
}
