import React, { useState } from 'react';
import { IPaymentMethods } from 'src/types/payments';
import PaymentMethodContext, {
  PaymentMethodsState,
} from 'src/context/payment-method';

interface ProviderProps {
  children: React.ReactNode;
}

function PaymentMethodProvider({ children }: ProviderProps) {
  const [errorsFromAPI, setErrorsFromAPI] = useState<string>('');
  const [refreshPaymentMethods, setRefreshPaymentMethods] =
    useState<boolean>(false);
  const [paymentMethodSelected, setPaymentMethodSelected] =
    useState<IPaymentMethods | null>(null);

  const ctx: PaymentMethodsState = {
    errorsFromAPI,
    setErrorsFromAPI,
    refreshPaymentMethods,
    setRefreshPaymentMethods,
    paymentMethodSelected,
    setPaymentMethodSelected,
  };

  return (
    <PaymentMethodContext.Provider value={ctx}>
      {children}
    </PaymentMethodContext.Provider>
  );
}

export default PaymentMethodProvider;
