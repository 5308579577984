import { AxiosResponse } from 'axios';
import { ResourceLink } from 'src/types/resource-link';

import { instance } from './apiConfig';

export function getResourceLinks(
  campusId: string
): Promise<AxiosResponse<ResourceLink[]>> {
  return instance({
    method: 'GET',
    url: `/campus/${campusId}/resourcelinks`,
  });
}
