import { useContext } from 'react';
import { useQuery } from 'react-query';
import { getDegreeAudit } from 'src/api';
import EnrollmentContext from 'src/context/enrollment';
import QueryKeys from 'src/types/query-keys';

export function useDegreeAuditQuery() {
  const { enrollment } = useContext(EnrollmentContext);

  const queryFn = () => {
    if (!enrollment?.enrollmentId) {
      const e = 'Unable to query degree audit. Enrollment ID unavailable.';
      throw new Error(e);
    }

    return getDegreeAudit(enrollment.enrollmentId);
  };

  return useQuery(
    [QueryKeys.GET_DEGREE_AUDIT, enrollment?.enrollmentId],
    queryFn,
    { enabled: !!enrollment?.enrollmentId }
  );
}
