import { Box, Divider } from '@mui/material';
import { Skeleton } from '@pennfoster/pfc-design-system';
import { StatusChipSkeleton } from './status-chip-skeleton';

interface Props {
  divider?: boolean;
}

export function UpcomingCoursesItemSkeleton({ divider = true }: Props) {
  return (
    <Box display={'flex'} width={'100%'} flexWrap={'wrap'}>
      <Box width={'70%'} height={56} pl={2} pt={2} pb={2} pr={0}>
        <Skeleton variant={'rounded'} width={'80%'} height={16} />
      </Box>
      <Box
        width={'30%'}
        height={56}
        pl={2}
        pt={2}
        pb={2}
        pr={0}
        display={'flex'}
        justifyContent={'end'}
      >
        <StatusChipSkeleton />
      </Box>
      <Box width={'100%'}>{divider ? <Divider /> : null}</Box>
    </Box>
  );
}
