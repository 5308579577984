import { Box } from '@mui/material';

interface AdminTabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export function AdminTabPanel(props: AdminTabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`admin-tabpanel-${index}`}
      aria-labelledby={`admin-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </Box>
  );
}
