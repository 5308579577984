import React from 'react';
import svg from 'src/assets/svg/error-models/session-timeout.svg';
import { AUTH_LOGOUT } from 'src/routes/routemap';

import ErrorModal from './error-modal';
import { useNavigate } from 'react-router-dom';

export default function SessionTimedOutModal() {
  const navigate = useNavigate();

  const headline = `Are You Still With Us?`;

  const body = `Looks like your session has timed out. Don't worry, just log back in to pick up where you left off`;

  const buttonOptions = {
    text: 'Back to login',
    onClick: () => navigate(AUTH_LOGOUT),
  };

  return (
    <ErrorModal
      headline={headline}
      body={body}
      svg={svg}
      secondaryButtonOpts={buttonOptions}
    />
  );
}
