import { Box, Typography } from '@mui/material';
import { currencyFormatWithDecimals } from 'src/helpers/format';
import { NunitoSans24, Subtitle1 } from 'src/styles';
import TotalPaymentSkeleton from './skeleton/total-payment-skeleton';

interface TotalProps {
  amount: number;
  isLoading: boolean;
}

function totalAmountBody(amount: number) {
  const textWithSpace = '(plus tax)';
  return (
    <>
      <Subtitle1 variant="h2">Total Payment Amount</Subtitle1>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <NunitoSans24>{currencyFormatWithDecimals(amount)}</NunitoSans24>
        <Typography variant="labelLarge" sx={{ margin: '4px 0px 0px 4px' }}>
          {textWithSpace}
        </Typography>
      </Box>
    </>
  );
}

export default function TotalPaymentAmount(props: TotalProps) {
  return props.isLoading ? (
    <TotalPaymentSkeleton />
  ) : (
    totalAmountBody(props.amount)
  );
}
