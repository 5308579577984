/**
 * delivers and mutates copy from the language json.
 *
 * @param copy - content copy.
 * @param variables - object including key,value pairs of variables for content.
 * @returns The content with variable replaced.
 */
import { Language, LanguageContent } from 'src/types/language';
import languageObj from '../language/en-us.json';

interface Variable {
  key: string;
  value: string;
}

class CMS {
  constructor(lang: Language) {
    this._content = lang.content;
    this.language_code = lang.language_code;
    this.language = lang.language;
  }
  private _content: LanguageContent;
  public language_code: string;
  public language: string;
  public replaceVariables: (a: string, b: Variable[]) => string = replaceVariables;
  public get content() {
    return this._content;
  }
}
function replaceVariables(copy: string, variables: Variable[]) {
  const copyArray = copy.split(/[{}]{2}/g);
  variables.forEach((v) => {
    const variable = copyArray.find((str) => str == v.key) || '';
    copyArray[copyArray.indexOf(variable)] = v.value;
  });
  const newCopy = copyArray.join('');
  return newCopy;
}

export const cms = new CMS(languageObj);
