import { MutationStatus } from 'react-query';
import StyledAlert from 'src/components/styled-alert';

import { AlertColor, Box } from '@mui/material';
import { useContext } from 'react';
import DegreeAuditContext from 'src/context/degree-audit';
import { PathwayStatusValues } from 'src/types/degree-audit-term';

interface Props {
  mutationStatus: MutationStatus;
  isPathwaySelected: boolean;
  pathwayHasElectivePools: boolean;
}

export default function PathwayCartMessage({
  isPathwaySelected,
  mutationStatus,
  pathwayHasElectivePools,
}: Props) {
  let color: AlertColor;
  let message: string;

  const { currentTerm } = useContext(DegreeAuditContext);

  switch (mutationStatus) {
    case 'success':
      color = 'success';
      message =
        currentTerm?.pathwayStatus === PathwayStatusValues.Elective
          ? `Congratulations! Your pathway has been submitted
              successfully. To select your electives, click ‘Continue’.`
          : `Congratulations! Your pathway has been submitted
              successfully. To view your updated course list, click ‘Continue’.`;
      break;
    case 'error':
      color = 'error';
      message = `We’re sorry, we couldn’t submit your pathway due to a system
      error. Please try again or contact support for assistance.`;
      break;
    default:
      color = 'info';
      message = !isPathwaySelected
        ? `Your pathway will appear here when you click the ‘Add’ button on your
         chosen area.`
        : `Please note that you will need to contact our support team if you
        need to make changes after submitting.`;
  }

  return (
    <Box display="flex" sx={{ pt: '8px' }}>
      <StyledAlert severity={color} sx={{ width: '100%' }}>
        {message}
      </StyledAlert>
    </Box>
  );
}
