export const getAriaLabel = (value: string) => {
  return value === '' ? 'Empty Cell' : undefined;
};

export const generateAriaLabelElement = (value: string) => {
  const ariaLabel = getAriaLabel(value);
  return <div aria-label={ariaLabel}>{value}</div>;
};

export const generateAriaLabelStrong = (value: string) => {
  const ariaLabel = getAriaLabel(value);
  return <strong aria-label={ariaLabel}>{value}</strong>;
};
