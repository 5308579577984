import { Box, Grid } from '@mui/material';
import BreadCrumbs from 'src/components/breadcrumbs';

export interface AdminLayoutProps {
  children?: React.ReactNode;
}

export function AdminLayout({ children }: AdminLayoutProps) {
  return (
    <Box p={3}>
      <Grid container>
        <Grid item xs={12}>
          <BreadCrumbs />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            flexDirection: 'column',
            alignItems: 'center',
            display: 'flex',
          }}
        >
          {children}
        </Grid>
      </Grid>
    </Box>
  );
}
