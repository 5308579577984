import React, { Dispatch, SetStateAction } from 'react';
import FeEvents from 'src/events/fe-events';
import { Typography, useTheme } from '@mui/material';
import {
  ExpansionItem,
  ExpansionRow,
  ExpansionSummary,
} from '@pennfoster/pfc-design-system/molecules/expansion-panel';

import { Pathway } from 'src/types/pathway';
import { Button } from '@pennfoster/pfc-design-system';

interface Props {
  pathway: Pathway;
  buttonText: string;
  disableSelect: boolean;
  setSelectedPathway: Dispatch<SetStateAction<Pathway | null>>;
}

export default function PathwayExpansionRow({
  pathway,
  buttonText,
  disableSelect,
  setSelectedPathway,
}: Props) {
  const theme = useTheme();

  const handleClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    pathway: Pathway
  ) => {
    event.stopPropagation();
    event.preventDefault();

    if (disableSelect) return;

    setSelectedPathway(pathway);
    FeEvents.trackNamedEvent({
      eventName: 'AddPathwayClickEvent',
      dataSource: { pathway },
    });
  };

  const { name, sisId, description } = pathway;

  return (
    <ExpansionItem
      key={`pathway-${sisId}-item`}
      sx={{
        '&.MuiAccordion-root': {
          borderBottom: `1px solid ${theme.palette.neutral[80]}`,
          borderRadius: 0,
          mr: 4,
        },
        '.MuiGrid-root .MuiGrid-container': {
          alignItems: 'center',
          justifyContent: 'space-between',
        },
      }}
    >
      <ExpansionSummary
        key={`pathway-${sisId}-summary`}
        sx={{
          '.MuiGrid-root .MuiGrid-item': {
            justifyContent: 'flex-end',
          },
          '.MuiGrid-container': {
            alignItems: 'center',
          },
        }}
        details={[
          <Button
            disabled={disableSelect ? true : false}
            pfVariant="text"
            key={sisId}
            label={buttonText}
            onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
              handleClick(e, pathway);
            }}
          />,
        ]}
      >
        {name}
      </ExpansionSummary>
      <ExpansionRow
        key={`pathway-${sisId}-row`}
        sx={{
          width: '100%',
          padding: '17px 48px 24px 48px',
          '&.MuiGrid-root .MuiGrid-item': {
            flexFlow: 'column',
            maxWidth: '100%',
            flexBasis: '100%',
          },
        }}
      >
        <Typography key={`pathway-${sisId}-row-details`}>
          {description}
        </Typography>
      </ExpansionRow>
    </ExpansionItem>
  );
}
