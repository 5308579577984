import { MutationStatus } from 'react-query';
import CartButton from 'src/components/common/cart/button';

interface Props {
  mutationStatus: MutationStatus;
  isPathwaySelected: boolean;
  handleSubmitClick: () => void;
  handleContinueClick: () => void;
}

const PathwayCartButton = ({
  isPathwaySelected,
  mutationStatus,
  handleSubmitClick,
  handleContinueClick,
}: Props) => {
  let text = 'Submit';
  let disabled = !isPathwaySelected || mutationStatus !== 'idle';
  let onClick = handleSubmitClick;

  // show the 'continue' button if the pathway has been submitted.
  if (mutationStatus === 'success') {
    text = 'Continue';
    disabled = false;
    onClick = handleContinueClick;
  }

  return <CartButton text={text} disabled={disabled} onClick={onClick} />;
};

export default PathwayCartButton;
