import { Skeleton } from '@pennfoster/pfc-design-system';
import {
  CardSkeleton,
  CardHeaderSkeleton,
  CardContentSkeleton,
  CardActionsSkeleton,
  ButtonSkeleton,
} from '.';
import { Divider, Grid, Box } from '@mui/material';

interface Props {
  header?: boolean;
}

export function PaymentsCardSkeleton({ header = true }: Props) {
  return (
    <CardSkeleton size={'small'}>
      {header && (
        <>
          <CardHeaderSkeleton status />
          <Divider />
        </>
      )}
      <CardContentSkeleton sx={{ '&:last-child': { pb: 3, pt: 3 } }}>
        <Grid container rowGap={3}>
          <Grid item xs={12}>
            <Skeleton variant={'rounded'} height={32} width={'33.33%'} />
          </Grid>
          <Grid item container rowGap={1.5}>
            <Grid item xs={12} p={0}>
              <Skeleton variant={'rounded'} height={16} />
            </Grid>
            <Grid item xs={6} p={0}>
              <Skeleton variant={'rounded'} height={16} />
            </Grid>
          </Grid>
        </Grid>
      </CardContentSkeleton>
      <CardActionsSkeleton>
        <Box width={'100%'} display={'flex'} justifyContent={'end'}>
          <ButtonSkeleton width={'176px'} size={'large'} />
        </Box>
      </CardActionsSkeleton>
    </CardSkeleton>
  );
}
