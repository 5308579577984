import { MutationStatus } from 'react-query';
import { SISCourseId } from 'src/types/course';
import { SelectedElective } from 'src/types/electives';

import { useTheme } from '@mui/material';
import { Card } from '@pennfoster/pfc-design-system';

import ElectiveCartButton from '../button';
import ElectivesCartCardHeader from '../header';
import ElectiveCartItems from './cart-items';
import ElectivesCartMessage from './message';
import LoadingComponent from 'src/components/loading-component';

interface Props {
  nNeededElectives: number;
  mutationStatus: MutationStatus;
  selectedCourses: SelectedElective[];
  handleRemoveElective: (sisCourseId: SISCourseId) => void;
  handleSubmitClick: () => void;
  handleContinueClick: () => void;
}

export default function ElectivesCartCard(props: Props) {
  const { palette } = useTheme();

  const subprops = {
    nSelectedElectives: props.selectedCourses.length,
    isElectiveSelected: props.selectedCourses.length > 0,
    ...props,
  };

  if (props.mutationStatus == 'loading') {
    return (
      <Card
        pfVariant="default"
        sx={{
          backgroundColor: palette.background.paper,
          borderRadius: 0,
          p: 3,
        }}
      >
        <LoadingComponent label="Confirming Submission" fullPage={false} />
      </Card>
    );
  }

  return (
    <Card
      pfVariant="default"
      sx={{
        backgroundColor: palette.background.paper,
        borderRadius: 0,
        p: 3,
      }}
    >
      <ElectivesCartCardHeader {...subprops} />
      <ElectiveCartItems {...subprops} />
      <ElectivesCartMessage {...subprops} />
      <ElectiveCartButton {...subprops} />
    </Card>
  );
}
