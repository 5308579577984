import React from 'react';
import { PaymentPlans } from 'src/types/payments';
import StatusTable from '../status-table';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import StatusChip from '../status-chip';
import {
  PaymentPlanType,
  PaymentPlanTypeLabels,
} from 'src/types/payment-plan-type';
import {
  generateAriaLabelElement,
  generateAriaLabelStrong,
  getAriaLabel,
} from 'src/helpers/aria-label-helper';
import { currencyFormatWithDecimals } from 'src/helpers/format';

const paymentPlansHeader: GridColDef[] = [
  {
    field: 'programDisplayName',
    headerName: 'Program',
    type: 'string',
    flex: 3,
    sortable: false,
    cellClassName: 'not-clickable',
    renderCell: (params: GridRenderCellParams) => {
      const ariaLabelElement = generateAriaLabelElement(params.value);
      return ariaLabelElement;
    },
  },
  {
    field: 'type',
    headerName: 'Payment Plan',
    type: 'string',
    flex: 1,
    headerAlign: 'left',
    align: 'left',
    sortable: false,
    cellClassName: 'not-clickable',
    renderCell: (params: GridRenderCellParams) => {
      const ariaLabel = getAriaLabel(params.value);
      return (
        <span aria-label={ariaLabel}>
          {[PaymentPlanTypeLabels[params.row.type as PaymentPlanType]]}
        </span>
      );
    },
  },
  {
    field: 'status',
    headerName: 'Status',
    type: 'string',
    flex: 1,
    headerAlign: 'left',
    align: 'left',
    cellClassName: 'not-clickable',
    renderCell: (params: GridRenderCellParams) => {
      const ariaLabel = getAriaLabel(params.value);
      return params.formattedValue !== 'default' ? (
        <StatusChip aria-label={ariaLabel} params={params} />
      ) : (
        <div aria-label="Empty Cell"></div>
      );
    },
    sortable: false,
  },
  {
    field: 'remainingBalance',
    headerName: 'Remaining',
    type: 'number',
    flex: 1,
    headerAlign: 'right',
    align: 'right',
    cellClassName: 'not-clickable',
    valueFormatter: ({ value }) => `${currencyFormatWithDecimals(value, 2)}`,
    renderCell: (params: GridRenderCellParams) => {
      const ariaLabelStrong = generateAriaLabelStrong(params.formattedValue);
      return ariaLabelStrong;
    },
    sortable: false,
  },
];

interface PaymentPlansProps {
  data: PaymentPlans[];
}

export default function PaymentPlan({ data }: PaymentPlansProps) {
  const rowsWithIds = data.map((row) => ({ ...row, id: row.paymentPlanSisId }));
  return (
    <StatusTable
      title="Payment Plans"
      rows={rowsWithIds}
      header={paymentPlansHeader}
      noRowsMessage="No payment plans available"
    />
  );
}
