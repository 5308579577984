import jwtDecode, { JwtPayload } from 'jwt-decode';

export interface StudentToken extends JwtPayload {
  extension_Admin?: boolean;
  name: string;
  given_name: string;
  family_name: string;
  tfp: string;
  nonce: number;
  scp: string;
  azp: string;
  ver: string;
}

export class TokenHelper {
  private _decoded?: StudentToken;

  constructor(token?: string | null) {
    if (token) {
      this._decoded = TokenHelper.decodedToken(token);
    }
  }

  private static decodedToken(token?: string): StudentToken | undefined {
    if (token) {
      return jwtDecode<StudentToken>(token);
    }
  }

  hasToken(): boolean {
    return !!this._decoded;
  }

  getFullName(): string | undefined {
    return this._decoded?.name;
  }

  getFirstName(): string | undefined {
    return this._decoded?.given_name;
  }

  getLastName(): string | undefined {
    return this._decoded?.family_name;
  }

  isTokenExpired(): boolean {
    if (this._decoded && this._decoded.nbf) {
      const now = Math.floor(Date.now());
      if (
        now >= this._decoded.nbf! * 1000 &&
        now <= this._decoded.exp! * 1000
      ) {
        return false;
      }
    }
    return true;
  }

  isAdmin(): boolean {
    if (this._decoded && this._decoded.extension_Admin) {
      return true;
    }
    return false;
  }
}
