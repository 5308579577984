export const currencyFormat = (num: number, fixed = 0) => {
  const negativeValue = num < 0;
  return (
    `${negativeValue ? '-' : ''}$` +
    Math.abs(num)
      .toFixed(fixed)
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  );
};

export const currencyFormatWithDecimals = (
  value: number | string,
  fixed = 2,
  currency = 'USD',
  locale = 'en-US'
) => {
  const n = typeof value === 'string' ? parseFloat(value) : value;

  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: fixed,
    maximumFractionDigits: fixed,
  }).format(isNaN(n) ? 0 : n);
};

export const gpaFormatWithDecimals = (gpa: number) => {
  if (Number.isInteger(gpa)) {
    return gpa.toFixed(2);
  }
  return gpa.toString();
};

export const roundNumber = (num: number) =>
  Math.round((num + Number.EPSILON) * 100) / 100;

export const dateFormat = (
  date: string,
  dateOptions?: Intl.DateTimeFormatOptions
) => {
  if (!date || !date.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d+)?$/)) {
    return 'N/A'; // Return a default value if date is undefined or not in the 'YYYY-MM-DDTHH:MM:SS' format
  }
  const newDate = new Date(date);
  if (!dateOptions) {
    return newDate.toLocaleDateString('en-us', {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    });
  }
  return newDate.toLocaleDateString('en-us', dateOptions);
};

//Convert a date in format ISO 8601 to MM/dd/yyyy format
export const dateFormatMMddyyyy = (dateSource: string) => {
  const datePart = dateSource.split('T')[0]; // Extract the date part of the string
  const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
  if (!dateRegex.test(datePart)) {
    console.error('Invalid date format. Expected format is YYYY-MM-DD');
    return 'Invalid date';
  }

  const [year, month, day] = datePart.split('-').map(Number);
  const date = new Date(year, month - 1, day);
  const formattedMonth = (date.getMonth() + 1).toString().padStart(2, '0');
  const formattedDay = date.getDate().toString().padStart(2, '0');
  const formattedYear = date.getFullYear();

  return `${formattedMonth}/${formattedDay}/${formattedYear}`;
};

const fallbackCopyTextToClipboard = (text: string) => {
  const textArea = document.createElement('textarea');
  textArea.value = text;

  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    document.execCommand('copy');
  } catch (err) {
    console.error(err);
  }

  document.body.removeChild(textArea);
};

export const copyToClipboard = (ref: React.RefObject<HTMLElement>) => {
  if (!ref.current) {
    return;
  }
  const text = ref.current.innerText;

  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
  } else {
    try {
      navigator.clipboard.writeText(text);
    } catch (err) {
      console.error(err);
    }
  }
};

export const mobileNumberFormatter = (number: string) => {
  const cleaned = number.replace(/\D/g, '');
  const mask = /^(\d{3})(\d{3})(\d{4})$/;
  const formattedNumber = cleaned.replace(mask, '($1) $2-$3');
  return formattedNumber;
};

export default {
  currencyFormat,
  dateFormat,
  roundNumber,
  mobileNumberFormatter,
};
