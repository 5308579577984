import React from 'react';
import { Grid } from '@mui/material';
import { Skeleton } from '@pennfoster/pfc-design-system';

export default function FormSkeleton() {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <Skeleton height={50}></Skeleton>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Skeleton height={50}></Skeleton>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Skeleton height={50}></Skeleton>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Skeleton height={50}></Skeleton>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Skeleton height={50}></Skeleton>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Skeleton height={50}></Skeleton>
      </Grid>
    </Grid>
  );
}
