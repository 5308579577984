import { Box, Grid } from '@mui/material';
import {
  CardSkeleton,
  CardHeaderSkeleton,
  CardContentSkeleton,
  UpcomingCoursesItemSkeleton,
} from '.';
import { Skeleton } from '@pennfoster/pfc-design-system';

export function PaymentPlansCardSkeleton() {
  return (
    <CardSkeleton>
      <CardHeaderSkeleton />
      <CardContentSkeleton>
        <Grid container>
          <Grid item xs={12}>
            <Skeleton variant={'rounded'} width={'100%'} height={56} />
          </Grid>
          <Grid item xs={12}>
            <Box display={'flex'} flexWrap={'wrap'}>
              {[...Array(1)].map((e, i) => (
                <UpcomingCoursesItemSkeleton key={i} divider={false} />
              ))}
            </Box>
          </Grid>
        </Grid>
      </CardContentSkeleton>
    </CardSkeleton>
  );
}
