import { createContext, Dispatch } from 'react';
import { noop } from 'src/helpers/noop';

import { NotificationsAction } from './actions';
import initState from './init-state';
import { InboxMessage } from 'src/types/message';
import { MutationStatus, QueryStatus } from 'react-query';

export type NotificationsState = {
  queryStatus: QueryStatus;
  mutationStatus: MutationStatus;
  inboxMessages?: InboxMessage[];
  selectedMessage?: InboxMessage;
  unreadOnly: boolean;
  notificationCount: number;
  updateNotificationCount: (count: number) => void;
  updateMessageToRead: (message: InboxMessage) => void;
  updateMessageToUnread: (message: InboxMessage) => void;
  updateInboxMessages: (messages: InboxMessage[]) => void;
  setUnreadOnly: (
    unreadOnly: boolean,
    dispatch: React.Dispatch<NotificationsAction>
  ) => void;
  setSelectedMessage: (
    message: InboxMessage | undefined,
    dispatch: React.Dispatch<NotificationsAction>
  ) => void;
};

const NotificationContext = createContext<
  [NotificationsState, Dispatch<NotificationsAction>]
>(
  [{ ...initState }, noop] // default values
);

export default NotificationContext;
