import { CardContent, SxProps } from '@mui/material';
import { ReactNode } from 'react';

interface Props {
  children?: ReactNode;
  sx?: SxProps;
}

export function CardContentSkeleton({ children, sx }: Props) {
  return (
    <CardContent sx={{ pl: 0, pr: 0, pt: 4, pb: 4, ...sx }}>
      {children}
    </CardContent>
  );
}
