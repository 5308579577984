import { ReactElement, useContext } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import DegreeAuditContext from 'src/context/degree-audit';
import EnrollmentContext from 'src/context/enrollment';

import { COURSES, PATHWAY } from '../routemap';
import { PathwayStatusValues } from 'src/types/degree-audit-term';

/**
 * ElectivesEnrollmentGuard guards access to child components based on the
 * user's selected enrollment status and current academic term.
 *
 * The Context API is used to access enrollment and degree audit information.
 * The guard checks if the user can enroll in electives for the current term
 * and if a pathway has previously been selected prior to directing to the
 * appropriate route.
 *
 * If selectedPathway is null. The current enrollment does not have associated
 * pathways. If selectedPathway is false, the enrollment is ineligible for
 * pathways.
 *
 * @component
 * @param {Object} props - The component's props.
 * @returns {ReactElement|null} Returns the child components if conditions are
 * met, or null if redirection is required.
 */
function ElectivesEnrollmentGuard(): ReactElement {
  const { enrollment } = useContext(EnrollmentContext);
  const { currentTerm } = useContext(DegreeAuditContext);
  const location = useLocation();

  if (!enrollment || !currentTerm) {
    return <Navigate to={COURSES} state={{ from: location }} replace />;
  }
  
  if (
    currentTerm.pathwayStatus === PathwayStatusValues.ElectiveAndPathway &&
    !enrollment.selectedPathway
  ) {
    return <Navigate to={PATHWAY} state={{ from: location }} replace />;
  }

  if ((currentTerm.pathwayStatus === PathwayStatusValues.None && 
      enrollment.selectedPathway !== true) ||
    currentTerm?.pathwayStatus === PathwayStatusValues.Pathway
  ) {
    return <Navigate to={COURSES} state={{ from: location }} replace />;
  }

  return <Outlet/>
}

export default ElectivesEnrollmentGuard;
