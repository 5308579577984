import { useContext } from 'react';
import { useMutation, useQuery } from 'react-query';
import { getPathways, postPathway } from 'src/api';
import EnrollmentContext from 'src/context/enrollment';
import QueryKeys from 'src/types/query-keys';
import { is2xx } from 'src/utils/http-utils';
import { useDegreeAuditQuery } from './degree-audit';

export function usePathwaysQuery() {
  const { enrollment } = useContext(EnrollmentContext);

  const queryFn = () => {
    if (!enrollment?.enrollmentId) {
      throw new Error('Unable to query pathways. Enrollment ID unavailable.');
    }
    return getPathways(enrollment.enrollmentId);
  };

  return useQuery([QueryKeys.GET_PATHWAYS, enrollment?.enrollmentId], queryFn, {
    enabled: !!enrollment?.enrollmentId,
  });
}

export function usePathwayMutation() {
  const { refetch: degreeAuditRefetch } = useDegreeAuditQuery();
  const { enrollment, refetch: enrollmentRefetch } =
    useContext(EnrollmentContext);

  const mutationFn = async (sisPathwayId: number) => {
    if (!sisPathwayId) {
      throw new Error('Unable to mutate pathway. Pathway SIS ID not passed.');
    }

    if (!enrollment?.enrollmentId) {
      throw new Error('Unable to mutate pathway. Enrollment ID unavailable.');
    }

    if (enrollment.selectedPathway) {
      throw new Error(
        'Unable to mutate pathway. Enrollment already has a pathway.'
      );
    }

    const resp = await postPathway(enrollment.enrollmentId, sisPathwayId);

    if (!is2xx(resp.status)) {
      throw new Error('Pathway POST did not return 2xx. Response:\n' + resp);
    }

    await Promise.all([enrollmentRefetch(), degreeAuditRefetch()]);
    return resp;
  };

  const mutation = useMutation({ mutationFn });
  return mutation;
}
