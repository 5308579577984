import React from 'react';

import { Header as PFHeader } from '@pennfoster/pfc-design-system';
import { SxProps, Theme, alpha, useTheme } from '@mui/material';

export interface HeaderProps {
  sx?: SxProps<Theme>;
  pfVariant?: 'standard-variant' | 'compact-variant';
  children?: React.ReactNode;
}

export default function Header({ children }: HeaderProps) {
  const theme = useTheme();
  return (
    <PFHeader
      component="header"
      position="relative"
      configuration="small-centered"
      sx={{
        backgroundColor: theme.palette.background.default,
        border: '0px 0px 1px 0px',
        boxShadow: '0px 2px 6px 1px rgba(213, 216, 229, 0.3)',
        color: theme.palette.secondary.main,
        height: theme.spacing(8),
        position: 'sticky',
        top: 0,
        zIndex: 99,
      }}
    >
      {children}
    </PFHeader>
  );
}
