import React, { ReactElement, useContext } from 'react';
import { Navigate, useLocation, Outlet } from 'react-router-dom';
import EnrollmentContext from 'src/context/enrollment';
import { DASHBOARD } from '../routemap';

/**
 * PaymentsGuard guards access to child components based on the user's
 * partner account code.
 *
 * @component
 * @returns {ReactElement} Returns the destination component if conditions are
 * met.
 */
function PaymentsGuard(): ReactElement {
  const { enrollment } = useContext(EnrollmentContext);
  const location = useLocation();

  if (enrollment?.partnerAccountCode) {
    return <Navigate to={DASHBOARD} state={{ from: location }} replace />;
  }

  return <Outlet />;
}

export default PaymentsGuard;
