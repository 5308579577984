import React, { useContext, useEffect, useState } from 'react';
import ChatbotContext, { ChatbotState } from 'src/context/chatbot';
import DegreeAuditContext from 'src/context/degree-audit';
import EnrollmentContext from 'src/context/enrollment';
import UserContext from 'src/context/user';
import { useChatbot } from 'src/hooks/chatbot';

interface Props {
  children: React.ReactNode;
}

export default function ChatbotProvider({ children }: Props) {
  const [isStarted, setIsStarted] = useState<boolean>(false);
  const { user } = useContext(UserContext);
  const { enrollment } = useContext(EnrollmentContext);
  const { currentTerm } = useContext(DegreeAuditContext);

  const chatbot = useChatbot();
  
  useEffect(() => {
    if (isStarted) {
      chatbot.stop();
      setIsStarted(false);
    }

    if (user && enrollment && currentTerm) {
      chatbot.start(user, enrollment, currentTerm);
      setIsStarted(true);
    }
  }, [enrollment, user, currentTerm,]);

  const ctx: ChatbotState = {
    chatbot,
  };

  return (
    <ChatbotContext.Provider value={ctx}>{children}</ChatbotContext.Provider>
  );
}
