import { Skeleton } from '@pennfoster/pfc-design-system';
import { CardSkeleton, CardHeaderSkeleton, CardContentSkeleton } from '.';
import { Divider, Grid } from '@mui/material';

export function MyProgramCardSkeleton() {
  return (
    <CardSkeleton size={'small'}>
      <CardHeaderSkeleton status />
      <Divider />
      <CardContentSkeleton sx={{ '&:last-child': { pb: 0, pt: 3 } }}>
        <Grid container>
          <Grid item p={2} xs={12}>
            <Skeleton variant={'rounded'} height={16} />
          </Grid>
          <Grid item container>
            <Grid item xs={6} pl={2} pr={2} pt={1} pb={1}>
              <Skeleton variant={'rounded'} height={16} />
            </Grid>
            <Grid item xs={6} pl={2} pr={2} pt={1} pb={1}>
              <Skeleton variant={'rounded'} height={16} />
            </Grid>
          </Grid>
        </Grid>
      </CardContentSkeleton>
    </CardSkeleton>
  );
}
