import { Task } from 'src/types/task';

import { IconButton, ListItem, ListItemText } from '@mui/material';
import { Icon, Typography } from '@pennfoster/pfc-design-system';

interface Props {
  task: Task;
}

export function MyTasksCardListItem({ task }: Props) {
  return (
    <ListItem sx={{ pl: 0, pr: 0 }}>
      <ListItemText>
        <Typography>{task.label}</Typography>
      </ListItemText>
      <IconButton onClick={task.action}>
        <Icon.Launch />
      </IconButton>
    </ListItem>
  );
}
