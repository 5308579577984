import { ReactElement, useContext } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import DegreeAuditContext from 'src/context/degree-audit';

import { COURSES } from '../routemap';
import { PathwayStatusValues } from 'src/types/degree-audit-term';
import EnrollmentContext from 'src/context/enrollment';
import UserContext from 'src/context/user';

/**
 * PathwayEnrollmentGuard guards access to child components based on the
 * user's ability to enroll in electives for the current academic term.
 *
 * It uses Context to access degree audit information and checks if the user is
 * allowed to enroll in electives for the current term. If not, it redirects
 * the user to the dashboard route.
 *
 * @component
 * @param {Object} props - The component's props.
 * @returns {ReactElement|null} Returns the child components if the user can
 * enroll in electives, or null if redirection is required.
 */
function PathwaysEnrollmentGuard(): ReactElement | null {
  const { submittedPathway } = useContext(UserContext);
  const { currentTerm } = useContext(DegreeAuditContext);
  const { enrollment } = useContext(EnrollmentContext);
  const location = useLocation();

  if (!enrollment || !currentTerm) {
    return <Navigate to={COURSES} state={{ from: location }} replace />;
  }  

  if (currentTerm?.pathwayStatus === PathwayStatusValues.None &&
    (!(enrollment?.selectedPathway === true && submittedPathway === true))) 
  {      
    return <Navigate to={COURSES} state={{ from: location }} replace />;
  }

  if (currentTerm?.pathwayStatus === PathwayStatusValues.Elective && submittedPathway !== true) 
  {      
    return <Navigate to={COURSES} state={{ from: location }} replace />;
  } 
  return <Outlet/>;
}

export default PathwaysEnrollmentGuard;
