import React, { useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import PaymentMethodContext from 'src/context/payment-method';
import UserContext from 'src/context/user';
import FeEvents from 'src/events/fe-events';
import { Body2, HeadingH6, Subtitle1 } from 'src/styles';
import QueryKeys from 'src/types/query-keys';

import { AddCard } from '@mui/icons-material';
import { Box, Grid, useTheme } from '@mui/material';
import { Alert, Button, Typography } from '@pennfoster/pfc-design-system';
import { getPaymentMethods } from '../../api';
import CardContainer from '../../components/card-container';
import AppLayout from '../../components/layout';
import { PaymentMethod } from '../../types/payments';
import AddCardFormDialog from 'src/components/add-card-form-dialog';
import CardContainerSkeleton from 'src/components/card-container/skeleton';

export default function PaymentMethods() {
  const {
    refreshPaymentMethods,
    setRefreshPaymentMethods,
    setErrorsFromAPI,
    setPaymentMethodSelected,
  } = useContext(PaymentMethodContext);

  const { user } = useContext(UserContext);

  const query = useQuery(QueryKeys.GET_PAYMENT_METHODS, getPaymentMethods, {
    onSuccess: ({ data }) => setData(data),
  });

  useEffect(() => {
    query.refetch();
    setRefreshPaymentMethods(false);
  }, [refreshPaymentMethods]);

  const [data, setData] = useState<PaymentMethod[]>();
  const [open, setOpen] = useState(false);
  const theme = useTheme();

  const handleClickOpen = () => {
    setErrorsFromAPI('');
    setOpen(true);
  };

  const assignedCards = (data?: PaymentMethod[]) => {
    const hasData =
      data &&
      data.some(
        (paymentMethod) => paymentMethod.isBackup || paymentMethod.isDefault
      );
    if (!hasData) {
      return (
        <Box sx={{ display: 'flex', justifyContent: 'center', pb: 2 }}>
          <Alert
            pfVariant="standard"
            severity="warning"
            alertIconColor={theme.palette.warning.dark}
          >
            <Typography>
              <Body2>{NO_DEFAULT_OR_BACKUP_WARNING}</Body2>
            </Typography>
          </Alert>
        </Box>
      );
    } else {
      return data!
        .filter(
          (paymentMethod) => paymentMethod.isBackup || paymentMethod.isDefault
        )
        .map((paymentMethod) => (
          <Grid key={paymentMethod.id} item sx={{ pb: 1 }}>
            <CardContainer paymentMethod={paymentMethod} />
          </Grid>
        ));
    }
  };

  const unassignedCards = (data?: PaymentMethod[]) => {
    return (
      data &&
      data
        .filter(
          (paymentMethod) => !paymentMethod.isBackup && !paymentMethod.isDefault
        )
        .map((paymentMethod) => (
          <Grid key={paymentMethod.id} item sx={{ pb: 1 }}>
            <CardContainer paymentMethod={paymentMethod} />
          </Grid>
        ))
    );
  };

  const handleClose = () => {
    setErrorsFromAPI('');
    setPaymentMethodSelected({
      nameOnCard: '',
      cardNumber: '',
      expiration: '',
      zipCode: '',
      isDefault: false,
      isBackup: false,
    });
    setOpen(false);
    query.refetch();
  };
  const NO_DEFAULT_OR_BACKUP_WARNING = `
  No default or backup cards assigned at this time. Assign a 
  default and backup to prevent missed payment fees for 
  automatic payments.
`;
  return (
    <AppLayout>
      <>
        <Box sx={{ flexGrow: 1, maxWidth: '506px', margin: '0 auto' }}>
          <Grid
            container
            xs={12}
            justifyContent="center"
            direction="column"
            sx={{
              sm: 12,
            }}
          >
            <HeadingH6 variant="h1" noWrap>
              Manage payment methods
            </HeadingH6>

            <Subtitle1 sx={{ paddingY: 2 }} variant="h2" noWrap>
              Assigned payment methods
            </Subtitle1>
            {query.isFetching ? (
              <CardContainerSkeleton showOptions={false} />
            ) : (
              assignedCards(data)
            )}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                paddingTop: 4,
                paddingBottom: 2,
              }}
            >
              <Subtitle1 variant="h2" noWrap>
                Unassigned payment methods
              </Subtitle1>
              <Button
                label="Add a new card"
                pfVariant="text"
                icon={<AddCard />}
                onClick={() => {
                  if(user){
                  FeEvents.trackNamedEvent({
                    eventName: 'AddPaymentMethodClick',
                  })
                };
                  handleClickOpen();
                }}
              />
            </Box>
            {query.isFetching ? (
              <CardContainerSkeleton showOptions />
            ) : (
              unassignedCards(data)
            )}
          </Grid>
        </Box>
        <Box
          sx={{
            alignContent: 'center',
            alignItems: 'center',
            margin: '0 auto',
          }}
        >
          <AddCardFormDialog handleClose={handleClose} open={open} />
        </Box>
      </>
    </AppLayout>
  );
}
