import { ProcessingEnrollmentModal } from 'src/components/error-modals';
import AppLayout from 'src/components/layout';

const ProcessingEnrollmentPage = () => {
  return (
    <AppLayout hidden={true}>
      <ProcessingEnrollmentModal />
    </AppLayout>
  )
};

export default ProcessingEnrollmentPage;
