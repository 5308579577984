export enum PaymentPlanType {
  Standard = 'standard',
  Auto = 'auto',
}

export type PaymentPlanTypeLabel = 'Standard' | 'Auto-Pay';

export const PaymentPlanTypeLabels: Record<
  PaymentPlanType,
  PaymentPlanTypeLabel
> = {
  [PaymentPlanType.Standard]: 'Standard',
  [PaymentPlanType.Auto]: 'Auto-Pay',
};
