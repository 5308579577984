import React from 'react';
import { Box } from '@mui/material';
import { Skeleton } from '@pennfoster/pfc-design-system';

function MessageSkeleton() {
  return (
    <Box sx={{ mb: 1 }}>
      <Skeleton variant="text" sx={{ width: '70%', mb: 1 }} />
      <Skeleton variant="text" sx={{ width: '95%', mb: 1 }} />
      <Skeleton variant="text" sx={{ width: '95%', mb: 1 }} />
    </Box>
  );
}

export default function InboxSkeleton() {
  return (
    <Box sx={{ paddingTop: 1, paddingLeft: 8 }}>
      <MessageSkeleton />
      <MessageSkeleton />
      <MessageSkeleton />
      <MessageSkeleton />
      <MessageSkeleton />
      <MessageSkeleton />
    </Box>
  );
}
