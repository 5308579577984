import { useQuery } from 'react-query';
import { getEnrollmentCourses } from '../api/courses';
import QueryKeys from '../types/query-keys';
import EnrollmentContext from 'src/context/enrollment';
import { useContext } from 'react';

export const useFetchCourses = (enrollmentId: string | undefined) => {
  const { enrollment } = useContext(EnrollmentContext);

  const fetchCourses = () => {
    if (enrollmentId) {
      return getEnrollmentCourses(enrollmentId, 3);
    }
    return Promise.reject('No enrollment ID available');
  };

  return useQuery(
    [QueryKeys.GET_UPCOMING_COURSES, enrollment?.enrollmentId],
    fetchCourses,
    {
      enabled: !!enrollmentId,
    }
  );
};
