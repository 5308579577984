import React, { ReactNode } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

interface Props {
  label?: ReactNode;
  fullPage?: boolean;
}

export default function LoadingComponent({ label, fullPage = true }: Props) {
  /* We have to use SX overriding properties here instead of theme properties because the theme is not yet loaded */
  return (
    <Grid
      container
      height={fullPage ? '100vh' : 'unset'}
      justifyContent={'center'}
      alignItems={'center'}
    >
      <Grid
        item
        xs={12}
        display={'flex'}
        flexWrap={'wrap'}
        textAlign={'center'}
        justifyContent={'center'}
        gap={1}
      >
        <Box width={'100%'} textAlign={'center'}>
          <CircularProgress
            size={49}
            sx={{
              //Pennfoster: Primary/Main
              color: '#0071F3',
            }}
          />
        </Box>
        {label ? (
          <Typography
            component={'div'}
            sx={{
              //styleName: Desktop/Label/Medium;
              fontFamily: 'Nunito Sans',
              fontSize: '14px',
              fontWeight: 600,
              lineHeight: '24px',
              letterSpacing: '0.01em',
              textAlign: 'left',
            }}
          >
            {label}
          </Typography>
        ) : null}
      </Grid>
    </Grid>
  );
}
