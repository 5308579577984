import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box } from '@mui/system';
import { ROOT } from 'src/routes/routemap';
import { useQuery } from 'react-query';
import QueryKeys from 'src/types/query-keys';
import { getLogo } from 'src/api/themes';

export interface LogoProps {
  width?: string;
}

export default function Logo({ width }: LogoProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const domainName = window.location.hostname;

  const handleClick = () => {
    if (location.pathname !== ROOT) {
      navigate(ROOT);
    }
  };
  const {
    data: svgContent,
    isLoading,
    isError,
    refetch,
  } = useQuery<string, Error>(
    [QueryKeys.GET_LOGO],
    async () => {
      const response = await getLogo(domainName);
      return response.data;
    },
    {
      refetchOnMount: true,
      enabled: false,
    }
  );
  const getSvgDataUri = (logoContent: string) => {
    return `data:image/svg+xml;charset=utf-8,${encodeURIComponent(
      logoContent
    )}`;
  };

  useEffect(() => {
    refetch();
  }, []);

  const cursor = location.pathname !== ROOT ? 'pointer' : 'default';

  return (
    <>
      {!isLoading && !isError && svgContent ? (
        <Box
          aria-label="Logo"
          sx={{ display: 'flex', cursor, width: '100%' }}
          onClick={handleClick}
        >
          <img src={getSvgDataUri(svgContent)} alt="logo" style={{ width }} />
        </Box>
      ) : null}
    </>
  );
}
