import React, { ReactNode } from 'react';
import { Box, CardActions, CardContent } from '@mui/material';
import { Card } from '@pennfoster/pfc-design-system';
import { NunitoSansContent, NunitoSansTitle } from '../../styles';

interface Props {
  media?: ReactNode;
  title?: string;
  content: string | JSX.Element;
  buttons: ReactNode[];
}

export default function NotificationCard({
  media,
  title,
  content,
  buttons,
}: Props) {
  return (
    <Card
      pfVariant="default"
      sx={{ display: 'flex', borderColor: 'secondary.90' }}
    >
      <CardContent sx={{ display: 'flex', p: 3, pb: 0 }}>
        {media && <Box sx={{ mr: 2 }}>{media}</Box>}
        <Box>
          {title && <NunitoSansTitle>{title}</NunitoSansTitle>}
          <NunitoSansContent sx={{ pb: 3 }}>{content}</NunitoSansContent>
        </Box>
      </CardContent>
      <CardActions sx={{ display: 'flex', flexDirection: 'row', p: 2 }}>
        {buttons.map((button) => button)}
      </CardActions>
    </Card>
  );
}
